import { Injectable, Inject, inject } from "@angular/core";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SessionService } from "./session.service";

import { AuthResult } from "../models/auth-result";
import { AuthUser } from "../models/auth-user";
import { AuthUserIdentity } from "../models/auth-user-identity";
import { CookieService } from 'ngx-cookie-service';
import { SystemPermission } from "../models/system-permissions";
import { LogNinja } from "./log-ninja.service";

// declare the Index.chsml authentication check
declare var _MyInitialAuthentication: boolean;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public static readonly AUTH_PROFILE_KEY = "token";

  private _baseUrl = '/api/auth';  // URL to web api
  private _authInfo: AuthResult | null = null;
  private _authInfoInitial: AuthResult | null = null;
  private _isBrowser: boolean;
  private _debug = false;

  private _router = inject(Router);
  private _http = inject(HttpClient);
  private _session = inject(SessionService);
  private _cookie = inject(CookieService);
  private _logger = inject(LogNinja);

  constructor(
    @Inject(PLATFORM_ID)
    private platform_id: any
  ) {
    // angular 4 way for _isBrowser()
    this._isBrowser = isPlatformBrowser(this.platform_id);

    // regularly check if the user logged in as someone else on another tab, or logged out on another tab
    // which will prompt an automatic logout here
    if (this._isBrowser) {
      // Note: the code below can create race conditions during identity switching..
      setInterval(() => {
        let data = localStorage.getItem(AuthService.AUTH_PROFILE_KEY);
        if (data == null) {
          if (this._authInfoInitial != null || this._session.isUserLoggedIn$.getValue()) {
            if (this._debug) this._logger.log('noticed user change => logout (local storage null)');
            this.logout();
          }
        } else {
          // got data in sessionStorage....
          let authInfo = JSON.parse(data) as AuthResult;

          if (this._authInfoInitial != null && authInfo.userIdentityCurrent && authInfo.userIdentityCurrent.hash != this._authInfoInitial.userIdentityCurrent!.hash) {
            // noticed user change => logout
            if (this._debug) this._logger.log('noticed user change => logout (local storage mismatch)');
            this.logout(false /* do not clear local storage */);
          }
        }

      }, 1000);
    }
  }

  private fetchLocalToken() {
    if (this._isBrowser && !this._authInfo) {
      let data = localStorage.getItem(AuthService.AUTH_PROFILE_KEY);
      this._authInfo = JSON.parse(data!) as AuthResult;
    }
  }
  public getLocalToken(): string {
    this.fetchLocalToken();
    return this._authInfo == null ? '' : this._authInfo.token!;
  }
  public getLocalUserId(): number {
    this.fetchLocalToken();
    return this._authInfo == null ? 0 : this._authInfo.userId!;
  }
  /*public getLocalUserId(): number {
    this.fetchLocalToken();
    return this._authInfo == null ? 0 : this.getLocalUserIdentityCurrent();
  }*/
  public getLocalUserName(): string {
    this.fetchLocalToken();
    return this._authInfo == null ? '' : this._authInfo.userName!;
  }
  public getLocalUserFullName(): string {
    this.fetchLocalToken();
    return this._authInfo == null ? '' : this._authInfo.fullName!;
  }
  public getLocalUserLogin(): string {
    this.fetchLocalToken();
    return this._authInfo == null ? '' : this._authInfo.userLogin!;
  }
  public getLocalUserRoles(): number[] {
    this.fetchLocalToken();
    return this._authInfo == null ? [] : (this._authInfo.userRoles == null ? [] : this._authInfo.userRoles);
  }
  public getLocalUserIdentityCurrent(): AuthUserIdentity | null{
    this.fetchLocalToken();
    return this._authInfo == null ? null : this._authInfo.userIdentityCurrent!;
  }
  public getLocalUserIdentities(): AuthUserIdentity[] {
    this.fetchLocalToken();
    return this._authInfo == null ? [] : (this._authInfo.userIdentities == null ? [] : this._authInfo.userIdentities);
  }
  public checkRole(role: SystemPermission) {
    return this.checkLogin() && this.getLocalUserRoles().includes(role);
  }

  // ##START Roles; do not modify generated content below this line.
  public hasRoleAdminAdd() { return this.checkRole(SystemPermission.AdminAdd); }
  public hasRoleAdminDelete() { return this.checkRole(SystemPermission.AdminDelete); }
  public hasRoleAdminPortalAccess() { return this.checkRole(SystemPermission.AdminPortalAccess); }
  public hasRoleAdminUpdate() { return this.checkRole(SystemPermission.AdminUpdate); }
  public hasRoleAdminUserAdd() { return this.checkRole(SystemPermission.AdminUserAdd); }
  public hasRoleAdminUserDelete() { return this.checkRole(SystemPermission.AdminUserDelete); }
  public hasRoleAdminUserUpdate() { return this.checkRole(SystemPermission.AdminUserUpdate); }
  public hasRoleAdminUserView() { return this.checkRole(SystemPermission.AdminUserView); }
  public hasRoleAdminView() { return this.checkRole(SystemPermission.AdminView); }
  public hasRolePortalAccess() { return this.checkRole(SystemPermission.PortalAccess); }
  public hasRoleUserAdd() { return this.checkRole(SystemPermission.UserAdd); }
  public hasRoleUserDelete() { return this.checkRole(SystemPermission.UserDelete); }
  public hasRoleUserUpdate() { return this.checkRole(SystemPermission.UserUpdate); }
  public hasRoleUserView() { return this.checkRole(SystemPermission.UserView); }
  public hasRoleAgentAdd() { return this.checkRole(SystemPermission.AgentAdd); }
  public hasRoleAgentDelete() { return this.checkRole(SystemPermission.AgentDelete); }
  public hasRoleAgentUpdate() { return this.checkRole(SystemPermission.AgentUpdate); }
  public hasRoleAgentView() { return this.checkRole(SystemPermission.AgentView); }
  // ##END Roles; do not modify generated content above this line.
  // ##START Roles-Azure; do not modify generated content below this line.
  // ##END Roles-Azure; do not modify generated content above this line.

  getLocalTime() {
    let d = new Date();
    let localDateString = d.toLocaleDateString('en-US');
    let localTimeString = d.toLocaleTimeString('en-US', { hour12: false }); // force 24 hour timezone, to avoid language specific AM/PM

    // Edge adds left-to-right characters in-between its punctuation in the date/time strings.
    // We need to filter these out otherwise it causes a time parse error on the login server side.
    // left-to-right mark: https://en.wikipedia.org/wiki/Left-to-right_mark
    // Edge/IE date & time string issues: https://www.csgpro.com/blog/2016/08/a-bad-date-with-internet-explorer-11-trouble-with-new-unicode-characters-in-javascript-date-strings
    let ltrChar = String.fromCharCode(8206);
    localDateString = localDateString.split('').filter(x => x !== ltrChar).reduce((previousVal, currentVal) => previousVal + currentVal);
    localTimeString = localTimeString.split('').filter(x => x !== ltrChar).reduce((previousVal, currentVal) => previousVal + currentVal);

    let localTime = localDateString + ' ' + localTimeString; // without timezone.
    if (this._debug) this._logger.log('localTime = ', localTime);
    return localTime;
  }
  /** return a value indicating whether we are logged in (JWT present or not) */
  isLoggedIn() {
    this.fetchLocalToken();
    return this._authInfo != null;
  }

  login(login: string, password: string): Observable<AuthResult> {
    // delete the cookie if we have one, as it may cause issues
    if (this._cookie.check("access_token")) {
      if (this._debug) this._logger.log('login .. clean up cookies');
      this._cookie.delete("access_token"); // delete to prevent problems
    }

    let user = new AuthUser();
    user.login = login;
    user.password = password;
    user.localTime = this.getLocalTime();
    this._authInfo = null;
    this._authInfoInitial = null;

    return this._http.post<AuthResult>(this._baseUrl + '/Login', user)
      .pipe(
        map(response => {
          this._authInfo = response;
          localStorage.setItem(AuthService.AUTH_PROFILE_KEY, JSON.stringify(this._authInfo)); // todo: we could store the date and check an expiration with it.
          this._session.setUserIsLoggedIn(true);
          return this._authInfo;
        }),
        catchError(response => {
          //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
          //this._logger.log(response);
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }

  loginToAccount(identity: AuthUserIdentity): Observable<AuthResult> {
    this._authInfoInitial = null;
    return this._http.post<AuthResult>(this._baseUrl + '/LoginToAccount', identity)
      .pipe(
        map(response => {
          this._authInfo = response;
          this._authInfoInitial = this._authInfo; // used to tell if the user changed tab and logged with another user.
          localStorage.setItem(AuthService.AUTH_PROFILE_KEY, JSON.stringify(this._authInfo)); // todo: we could store the date and check an expiration with it.
          this._session.setUserIsLoggedIn(true);
          return this._authInfo;
        }),
        catchError(response => {
          //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
          //this._logger.log(response);
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }

  buyerRegister(authUser: AuthUser): Observable<boolean> {
    return this._http.post<boolean>(this._baseUrl + '/BuyerRegister', authUser)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(response => {
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }


  /**
   *  return the user full name only
   */
  isValidPasswordResetHash(hash: string): Observable<AuthResult> {
    let user = new AuthUser();
    user.hash = hash;
    return this._http.post<AuthResult>(this._baseUrl + '/IsValidPasswordResetHash', user)
      .pipe(
        map(response => {
          return response; // does nothing really
        }),
        catchError(response => {
          //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
          //this._logger.log(response);
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }

  changePassword(passwordOld: string, passwordNew: string): Observable<Boolean> {
    let user = new AuthUser();
    user.passwordOld = passwordOld;
    user.password = passwordNew;
    return this._http.post<Boolean>(this._baseUrl + '/ChangePassword', user)
      .pipe(
        map(response => {
          return true;
        }),
        catchError(response => {
          //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
          //this._logger.log(response);
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }

  updatePassword(hash: string, password: string, resetMode: string): Observable<Boolean> {
    let user = new AuthUser();
    user.hash = hash;
    user.password = password;
    user.resetMode = resetMode;
    return this._http.post<Boolean>(this._baseUrl + '/UpdatePassword', user)
      .pipe(
        map(response => {
          return true;
        }),
        catchError(response => {
          //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
          //this._logger.log(response);
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }

  resetPassword(email: string): Observable<Boolean> {
    let user = new AuthUser();
    user.login = email; // use login for email address
    return this._http.post<Boolean>(this._baseUrl + '/resetpassword', user)
      .pipe(
        map(response => {
          return true;
        }),
        catchError(response => {
          //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
          //this._logger.log(response);
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }

  completeRegistration(hash: string): Observable<Boolean> {
    let user = new AuthUser();
    user.hash = hash;
    return this._http.post<Boolean>(this._baseUrl + '/CompleteRegistration', user)
      .pipe(
        map(response => {
          return true;
        }),
        catchError(response => {
          //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
          //this._logger.log(response);
          this.overrideStatusText(response);
          return observableThrowError(response || 'Server error');
        })
      );
  }

  // TODO: uncomment and use for account invites. See ERM project.
  //sendInvite(email: string): Observable<Boolean> {
  //	let user = new AuthUser();
  //	user.login = email; // use login for email address
  //	return this._http.post<Boolean>(this._baseUrl + '/SendInvite', user)
  //		.pipe(
  //			map(response => {
  //				return true;
  //			}),
  //			catchError(response => {
  //				//this._logger.log('got error: ' + response.status + ', ' + response.statusText);
  //				//this._logger.log(response);
  //				this.overrideStatusText(response);
  //				return observableThrowError(response || 'Server error');
  //			})
  //		);
  //}
  //isValidInviteHash(hash: string): Observable<Boolean> {
  //	let user = new AuthUser();
  //	user.hash = hash;
  //	return this._http.post<Boolean>(this._baseUrl + '/IsValidInviteHash', user)
  //		.pipe(
  //			map(response => {
  //				return true; // does nothing really
  //			}),
  //			catchError(response => {
  //				//this._logger.log('got error: ' + response.status + ', ' + response.statusText);
  //				//this._logger.log(response);
  //				this.overrideStatusText(response);
  //				return observableThrowError(response || 'Server error');
  //			})
  //		);
  //}
  //validateAccount(hash: string, password: string, firstName: string, lastName: string): Observable<Boolean> {
  //	let user = new AuthUser();
  //	user.hash = hash;
  //	user.password = password;
  //	user.firstName = firstName;
  //	user.lastName = lastName;
  //	return this._http.post<Boolean>(this._baseUrl + '/ValidateAccount', user)
  //		.pipe(
  //			map(response => {
  //				return true;
  //			}),
  //			catchError(response => {
  //				//this._logger.log('got error: ' + response.status + ', ' + response.statusText);
  //				//this._logger.log(response);
  //				this.overrideStatusText(response);
  //				return observableThrowError(response || 'Server error');
  //			})
  //		);
  //}

  private setLogout(clearLocalStorage = true, doRedirect = true, redirectUrl = '/home') {
    _MyInitialAuthentication = false;
    if (clearLocalStorage) {
      localStorage.removeItem(AuthService.AUTH_PROFILE_KEY);
    }
    this._authInfo = null;
    this._authInfoInitial = null;
    this._session.setUserIsLoggedIn(false);
    if (doRedirect) {
      this._router.navigate([redirectUrl]);
    }
  }

  claims() {
    this._http.get(this._baseUrl + '/claims', { headers: this.initAuthHeaders() }).subscribe({
      next: (data) => {
        this._logger.log('claims (ok??).. returned...', data);
      },
      error: (err) => {
        this._logger.log('claims (error??).. returned...', err);
      }
    });
  }

  logout(clearLocalStorage = true, doRedirect = true, redirectUrl = '/home',) {
    if (this._debug) this._logger.log('logout... invoking API... (clear storage = ' + clearLocalStorage + ', redirect url = ' + redirectUrl + ')');
    this._authInfoInitial = null;
    // call it once before - no redirect.
    this.setLogout(clearLocalStorage, false, undefined);
    this._http.get(this._baseUrl + '/logout', { headers: this.initAuthHeaders() }).subscribe({
      next: (data) => {
        if (this._debug) this._logger.log('logout (ok??).. returned...', data);
        // call it once again with redirect
        this.setLogout(clearLocalStorage, doRedirect, redirectUrl);
      },
      error: () => {
        if (this._debug) this._logger.log('logout (error??).. returned...');
        // call it once again with redirect
        this.setLogout(clearLocalStorage, doRedirect, redirectUrl);
      }
    });
  }

  // extract the human readable status text from the server, and override the response statusText
  // because too often the statusText gets replaced by the generic http status code
  // which doesn't reflect the server error detail
  overrideStatusText(response: any) {
    try {
      let error = response.error; // error result is the normalized json object we expect from Asp.net
      if (error.statusText != null && error.statusText.trim() != '') {
        //this._logger.log('auth.handlerError() Overriding status text: ' + error.statusText);
        response.statusText = error.statusText; // override the error
      }
    } catch (e) {
      //this._logger.log('got ex: ', e);
    }
  }

  handleError(response: any) {
    //this._logger.log('handled error: ',response);
    if (response.status == 401) {
      this._logger.log('Caught 401 => Unauthorized => Authentication issue: Token expired => logging out');
      this.logout();
    }
    else if (response.status == 403) {
      this._logger.log('Caught 403 => Access forbidden => Access permission issue. Do not log out.');
      //this.logout(); // uncomment if you wish to logout on forbidden
    }
    //this._logger.log('got error: ' + response.status + ', ' + response.statusText);
    // this._logger.log(response);
    this.overrideStatusText(response);
    return observableThrowError(response || 'Server error');
  }

  ///**
  // * hits the server just to check our logged in status
  // */
  //refreshStatus() {
  //	if (!this._isBrowser) { // otherwise, would fail on pre-rendering.
  //		return;
  //	}
  //	this._http.get(this._baseUrl, { headers: this.initAuthHeaders() }).subscribe(
  //		(data) => {
  //			// ok. We're still in
  //			this._logger.log("we're logged in");
  //			this._global.setUserIsLoggedIn(true);
  //		},
  //		(err) => {
  //			this._logger.log("oops. we're NOT logged in");
  //			_MyInitialAuthentication = false;
  //			this._global.setUserIsLoggedIn(false);
  //			//// We're out => toggle status only if failed
  //			//if (this.checkLogin() || this._global.isUserLoggedIn) {
  //			//	this.logout();
  //			//}
  //		}
  //	);
  //}

  /**
   * checks the local session, but does not hit the server.
   */
  checkLogin(): boolean {
    if (this._isBrowser) { // need this because of webpack pre-compilation
      let token = localStorage.getItem(AuthService.AUTH_PROFILE_KEY);
      return token != null;
    }

    return false;
  }



  /**
   * get authentication headers
   */
  initAuthHeaders(): HttpHeaders {
    // not needed anymore, thanks to the cookie JWT change.
    //		let token = this.getLocalToken();
    //		if (token == null) throw "No token";
    var headers = new HttpHeaders();
    //		headers.append("Authorization", "Bearer " + token);
    return headers;
  }

}
