import { Component, OnInit, OnDestroy, inject, signal } from '@angular/core';
import { AuthService } from '@services/auth.service';

import { UIComponentBase } from '@buildable/foundation';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LogNinja } from '@services/log-ninja.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'reset',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './reset.component.html',
})
export class ResetComponent extends UIComponentBase implements OnInit, OnDestroy {
  public resetPasswordEmail = signal('');
  public resetPasswordErrorMessage = signal('');
  public resetPasswordSuccessMessage = signal('');
  public resetPasswordLoading = signal(false);
  public loading = signal(true);
  public isMobile = signal(false);
  private _debug = false;

  public auth = inject(AuthService);
  private _device = inject(DeviceDetectorService);
  private _logger = inject(LogNinja);

  constructor() {
    super();

    this.isMobile.set(this._device.isMobile());
    this._logger.log('isMobile = ' + this.isMobile());
  }

  ngOnInit() {
    this.loading.set(true);

    // Logout without redirecting anywhere
    // to ensure that nobody resets a password while logged in
    this.auth.logout(true, false, '');
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  resetPasswordInit() {
    this.resetPasswordLoading.set(false);
    this.resetPasswordErrorMessage.set('');
    this.resetPasswordSuccessMessage.set('');
    this.resetPasswordEmail.set('');
  }

  resetPassword() {
    this.resetPasswordLoading.set(true);
    this.resetPasswordSuccessMessage.set('');
    this.resetPasswordErrorMessage.set('');

    if (this.resetPasswordEmail() == '') {
      this.resetPasswordErrorMessage.set('Please supply an email');
      return;
    }

    this.auth.resetPassword(this.resetPasswordEmail()).subscribe({
      next: () => {
        this.resetPasswordLoading.set(false);
        this.resetPasswordSuccessMessage.set(
          'An email was sent to this address with a password reset link.'
        );
      },
      error: (error: any) => {
        this.resetPasswordLoading.set(false);
        this.resetPasswordErrorMessage.set(error.statusText);
        return null;
      },
      complete: () => {
        if (this._debug) this._logger.log('reset password finished');
      },
    });
  }
}
