import { Component, OnInit, ViewEncapsulation } from '@angular/core';

// declare the Index.chsml authentication check
declare var _MyProductVersion: boolean;

@Component({
    selector: 'nav-footer',
	standalone: true,
	templateUrl: './nav-footer.component.html',	
	styleUrls: ['./nav-footer.component.scss'],
	encapsulation: ViewEncapsulation.None, // makes this CSS global
})
export class NavFooterComponent {

	public version = _MyProductVersion;

	constructor() { }
}
