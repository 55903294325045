import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';

import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Observable, Subscription } from "rxjs";
import { LogNinja } from "./log-ninja.service";

export type PageTitleContent = {
  title: String
}

/**
 * Intended to centralize the setting of page titles in the app.
 * Important for data that should be displayed in the document title that isn't available on mount (ex: name of a book)
 */
@Injectable({
  providedIn: 'root' // need this to be singleton so it remembers the current page state
})
export class PageTitleService {

  constructor(
    private _router: Router,
    private _titleService: Title,
    private _logger: LogNinja
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        // wiping the page title watcher for dynamic content
        if (this._pageTitleSubscription) {
          this._pageTitleSubscription.unsubscribe();
          this._pageTitleSubscription = null;
        }

        if (this._currentRouteUrl !== this._router.url) {
          this._currentRoute = event.snapshot;
          this._currentRouteUrl = this._router.url;
          var pageTitle = this._currentRoute.data["title"]?.trim();

          if (typeof pageTitle !== 'string' || pageTitle.length == 0) {
            pageTitle = 'Undefined Page';
          }


          // this._logger.log('changed page to ', pageTitle, this._router.url);

          this._titleService.setTitle(`${pageTitle} | ${environment.appTitle}`);
        } else {
          // this._logger.log('skip page title update')
        }
      }
    });
  }


  // Timezone offset
  private _currentRouteUrl: string | null = null;
  private _currentRoute: ActivatedRouteSnapshot | null = null;
  private _currentPageTitle: string = '';
  get pageTitle(): string {
    return this._currentPageTitle;
  }

  set pageTitle(value: string) {
    if (value != null) {
      this._currentPageTitle = value;
    }
  }

  private _pageTitleSubscription: Subscription | null = null;

  /**
   * NOTE: Does not work, leaving here for a future lad
   * Not sure this is the best solution to watch for updates to an object.
   * The whole goal here is to update the current title in realtime (e.g Loading Book -> Edit Book: The Notebook)
   */
  set pageTitleWatch(pageTitleObservable: Observable<PageTitleContent>) {
    this._pageTitleSubscription = pageTitleObservable.subscribe((newContent) => {
      this._logger.log('page title changed in the component', newContent?.title);
    });
  }
}
