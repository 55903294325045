
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import {ListResultItem} from "@buildable/foundation";

export enum PaymentMethods {
  // Enumeration values
  Cash = 1,
  Financing = 2

}

function enumToList(enumType: any): ListResultItem[] {
  return Object.keys(enumType)
    .filter(key => isNaN(Number(key)))
    .map(key => (new ListResultItem({
      value: enumType[key] as number,
      label: key
    })));
}

export const paymentMethodsList = enumToList(PaymentMethods);

export const getPaymentMethodName = (id: PaymentMethods | null | undefined): string | null | undefined => {
  const paymentMethod = paymentMethodsList.find(item => item.value === id);
  return paymentMethod ? paymentMethod.label : '';
};




