
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


// add imports
import { ListResultItem, IListResultItem } from '../models/list-result-item'; 
import { SystemUserModel, ISystemUserModel } from '../models/system-user-model'; 
import { UpdateOptions, IUpdateOptions } from '../models/update-options'; 

@Injectable()
export class SystemUsersService {

	constructor(public _http: HttpClient, public _auth: AuthService) { } 
	
	public getResultItems(i_oSearchOptions: SearchOptions): Observable<ListResultItem[]> {
		
		return this._http.post<ListResultItem[]>(`api/System/SystemUsers/getResultItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IListResultItem[]).map(x => new ListResultItem(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItems(i_oSearchOptions: SearchOptions): Observable<SystemUserModel[]> {
		
		return this._http.post<SystemUserModel[]>(`api/System/SystemUsers/getItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as ISystemUserModel[]).map(x => new SystemUserModel(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemNew(): Observable<SystemUserModel> {
		
		return this._http.get<SystemUserModel>(`api/System/SystemUsers/getItemNew`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemUserModel(<ISystemUserModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemById(id: number): Observable<SystemUserModel> {
		
		return this._http.get<SystemUserModel>(`api/System/SystemUsers/getItemById/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemUserModel(<ISystemUserModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getById(id: number): Observable<SystemUserModel> {
		
		return this._http.get<SystemUserModel>(`api/System/SystemUsers/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemUserModel(<ISystemUserModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public putColumn(i_oUpdateOptions: UpdateOptions): Observable<UpdateOptions> {
		
		return this._http.put<UpdateOptions>(`api/System/SystemUsers/putColumn`, i_oUpdateOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new UpdateOptions(<IUpdateOptions>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public changePassword(id: number, i_oValue: SystemUserModel): Observable<SystemUserModel> {
		
		return this._http.put<SystemUserModel>(`api/System/SystemUsers/changePassword/${id}`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemUserModel(<ISystemUserModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public put(id: number, i_oValue: SystemUserModel): Observable<SystemUserModel> {
		
		return this._http.put<SystemUserModel>(`api/System/SystemUsers/${id}`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemUserModel(<ISystemUserModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public post(i_oValue: SystemUserModel): Observable<SystemUserModel> {
		
		return this._http.post<SystemUserModel>(`api/System/SystemUsers`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemUserModel(<ISystemUserModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public delete(id: number): Observable<any> {
		
		return this._http.delete<any>(`api/System/SystemUsers/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public deleteBulk(i_iIdArray: number[]): Observable<any> {
		
		return this._http.post<any>(`api/System/SystemUsers/deleteBulk`, i_iIdArray, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public resetPassword(i_iIdArray: number[]): Observable<any> {
		
		return this._http.post<any>(`api/System/SystemUsers/resetPassword`, i_iIdArray, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
}

