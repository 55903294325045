import { Component } from '@angular/core';
import { IconsModule } from '@app/icons/icons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'design-system',
    standalone: true,
    imports: [
        FontAwesomeModule, IconsModule,
    ],
    templateUrl: './design-system.component.html'
})
export class DesignSystemComponent {

}