
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


// add imports
import { OfferStatus } from './offer-status'; 



export interface ISearchFields {
	
	// Class properties
	includeInactive?: boolean | null;
	refLanguageID?: number | null;
	dateStart?: Date | null;
	dateEnd?: Date | null;
	address?: string | null;
	offerId?: number | null;
	offerStatuses?: OfferStatus[] | null;

}



export class SearchFields implements ISearchFields  {
	constructor(init?: Partial<ISearchFields>) { // allows us to assign this object C# style
		Object.assign(this, init);
		// Date initializers
		if (init?.dateStart) this.dateStart = new Date(init.dateStart);
		if (init?.dateEnd) this.dateEnd = new Date(init.dateEnd);

	}

	
	
	// Class properties
	includeInactive?: boolean | null = null;
	refLanguageID?: number | null = null;
	dateStart?: Date | null = null;
	dateEnd?: Date | null = null;
	address?: string | null = null;
	offerId?: number | null = null;
	offerStatuses?: OfferStatus[] | null = [];

	
}



