<div class="full-height row pt-4">
  <div class="col-md-6 d-flex flex-column justify-content-center">
    <h1 class="h1-responsive fw-bold">Let’s get started!</h1>
    <p class="mt-3 h5-responsive text-primary  fw-bold">Here’s how it works :</p>
  </div>

  <div class="col-md-6 d-flex flex-column justify-content-md-center">
    <ol class="step-list">
      <li class="h5-responsive">Submit notice of interest</li>
      <li class="h5-responsive">We call to discuss</li>
      <li class="h5-responsive">Decide go /no go</li>
      <li class="h5-responsive">We write your offer</li>
      <li class="h5-responsive">You sign & We negotiate</li>
    </ol>
  </div>
</div>
