<div class="full-height row">
  <div class="d-flex flex-column justify-content-center">
    <div>
      <h1 class="col-md-9 h1-responsive fw-bold">When do you want to close on the new home?</h1>

      <div class="mt-3 col-md-6">
        <input #dateInput
          type="text"
          class="wizard-input"
          [ngModel]="closingDate()"
          (ngModelChange)="closingDate.set($event)"
          id="number_days"
          placeholder="Enter Closing Date"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          (dateTimeChange)="updateBuyerOffer($event)"
        />
        <owl-date-time [pickerType]="'calendar'" #dt1 ></owl-date-time>
      </div>
      <p class="col-md-9 mt-3 subheader">
        We recommend choosing a day 30-45 days from today.
      </p>

    </div>
  </div>

</div>
