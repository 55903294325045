
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder





export interface IAuthUser {
	
	// Class properties
	login?: string | null;
	password?: string | null;
	localTime?: string | null;
	source?: string | null;
	passwordOld?: string | null;
	hash?: string | null;
	resetMode?: string | null;
	userName?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	pin?: number | null;
	deviceToken?: string | null;

}



export class AuthUser implements IAuthUser  {
	constructor(init?: Partial<IAuthUser>) { // allows us to assign this object C# style
		Object.assign(this, init);
		
	}

	// Constants
	public static readonly CST_MODE_CREATE: string = 'create';
	public static readonly CST_MODE_RESET: string = 'reset';

	
	// Class properties
	login?: string | null = null;
	password?: string | null = null;
	localTime?: string | null = null;
	source?: string | null = null;
	passwordOld?: string | null = null;
	hash?: string | null = null;
	resetMode?: string | null = null;
	userName?: string | null = null;
	firstName?: string | null = null;
	lastName?: string | null = null;
	pin?: number | null = null;
	deviceToken?: string | null = null;

	// getters
	public static getModeCreateId() { return AuthUser.CST_MODE_CREATE; } 
	public static getModeResetId() { return AuthUser.CST_MODE_RESET; } 


}



