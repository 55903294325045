import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';

import { UIListViewColumn, UIListItem, UIListItemStyle, UIListViewModel, UIListChange, UIAlertComponent, UIHeaderComponent, UIListViewComponent, UILoadingBarComponent } from '@buildable/foundation';
import { UIModalComponent, UIComponentBase, UIAlertItem, UISortableColumnEvent } from '@buildable/foundation';
import { UIEditViewModel, UIEditViewValidateResult, UIEditViewComponent, UIEditViewItemComponent, UIEditViewItem } from '@buildable/foundation';

import { AuthService } from '@services/auth.service';
import { CacheDataService } from '@services/cache-data.service';

import { SearchOptions } from '@buildable/foundation';

/* specific to this listview */
import { SystemAgentModel} from "@models/system-agent-model";
import { SystemUsersService } from '@services/system-users.service';
import { CommonModule } from '@angular/common';
import {SystemAgentsService} from "@services/system-agents.service";

@Component({
	selector: 'system-agent-list',
	standalone: true,
	imports: [
		CommonModule,
		UIAlertComponent, UIHeaderComponent, UIListViewComponent, UILoadingBarComponent, UIEditViewComponent, UIModalComponent,
	],
	templateUrl: './system-agent-list.component.html',
	providers: [SystemAgentsService , SystemUsersService], // does not need to be SINGLETON, or global (saves on space)
})
export class SystemAgentListComponent extends UIComponentBase implements OnInit, OnDestroy {

	public listAlert: UIAlertItem = new UIAlertItem();
	public listViewModel: UIListViewModel;

	public editAlert: UIAlertItem = new UIAlertItem();
	public editViewModel: UIEditViewModel;
	public editDataId: number | null = null;
	public editLoading: boolean = false;
	public editSaving: boolean = false;

	private _searchOptions: SearchOptions = new SearchOptions();

	// find a reference to the editview component
	@ViewChild(UIEditViewComponent)
	uiEditViewComponent!: UIEditViewComponent;
	// find a reference to the first modal included in this component. Saves having to assign an id to the modal
	@ViewChild(UIModalComponent)
	uiModal!: UIModalComponent;

	constructor(
		public auth: AuthService,
		private _cacheData: CacheDataService,
		private _data: SystemAgentsService,
		private _systemUserService: SystemUsersService,
	) {
		super();

		//--------------------------
		// setup the compact listview
		this.listViewModel = new UIListViewModel({
			showPager: true,
			showButtons: true,
			showTitle: false,
			showSearch: true,
			showFilter: false,
			addText: 'Add an agent member',
			addEnabled: this.auth.hasRoleAdminAdd(),
			deleteText: 'Delete',
			deleteEnabled: this.auth.hasRoleAdminDelete(),
			viewEnabled: this.auth.hasRoleAdminView(),
			reloadText: '',
			reloadEnabled: true,
			inlineEditEnabled: this.auth.hasRoleAdminUpdate(),
			items: [],
			columns: [
        new UIListViewColumn({ label: 'Email', field: 'systemUserEmailAddress', type: 'text', link: true, style: <UIListItemStyle>{ bold: true } }),
				new UIListViewColumn({ label: 'Active', field: 'isActive', type: 'boolean' }),
        new UIListViewColumn({ label: 'First Name', field: 'systemUserFirstName', type: 'text' }),
				new UIListViewColumn({ label: 'Last Name', field: 'systemUserLastName', type: 'text' }),
				new UIListViewColumn({ label: 'Access', field: 'systemRolesCSV', type: 'text' }),
				new UIListViewColumn({ label: 'Added', field: 'dateAdded', type: 'date' }),
				new UIListViewColumn({ label: 'Modified', field: 'dateModified', type: 'date' }),
			]
		});
		// subscribe to events when the model will be ready
		this.addSubscriptions(this.listViewModel.initialize$.subscribe(() => {
			this.addSubscriptions(
				this.listViewModel.reload$.subscribe((searchOptions?) => this.reloadList(searchOptions)),
				this.listViewModel.add$.subscribe(() => this.addItem()),
				this.listViewModel.view$.subscribe((item: UIListItem) => this.editItem(item)),
				this.listViewModel.delete$.subscribe((items: UIListItem[]) => this.deleteSelection(items)),
				this.listViewModel.updated$.subscribe((item: UIListChange) => this.updateField(item)),
			);
		}));

		//-------------------
		// setup the editview
		this.editViewModel = new UIEditViewModel({
			showInline: true,
			showButtons: false,
			showSave: this.auth.hasRoleAdminUpdate(),
			editViewItems: [
				new UIEditViewItem({ label: 'User', field: 'systemUserId', type: 'select', optionsBsFunc: () => this._cacheData.fetchGetResultItemsBs(this._systemUserService), required: true }),
				new UIEditViewItem({ label: 'Active', field: 'isActive', type: 'checkbox' }),
			]
		});

		// subscribe to events when the model will be ready
		this.addSubscriptions(this.editViewModel.initialize$.subscribe(() => {
			this.addSubscriptions(
				this.editViewModel.reload$.subscribe(() => this.reloadEdit()),
				this.editViewModel.cancel$.subscribe(() => this.closeEditModal()),
				this.editViewModel.save$.subscribe(() => this.onEditSave()),
				this.editViewModel.error$.subscribe((message) => this.editAlert.errorMessage = message),
				this.editViewModel.change$.subscribe((item) => this.onEditChange(item)),
			);
		}));

		// setup the validation handlers
		this.editViewModel.beforeValidateDataCallback = (data: any) => { this.editAlert.reset(); }; // clear the error messages before validating
		this.editViewModel.validateDataCallback = (data: any) => { return this.onValidateData(data); } // hook to the local method (note we must use a lambda function, or 'this' will lose scope inside the function)
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}

	ngOnInit() {
		this._cacheData.loadCacheAll(); // remove if not needed
	}


	//////////////////////////////////////////////////////////////////////
	// listview portion

	reloadList(searchOptions?: SearchOptions | null) {
		this.listAlert.reset();

		if (searchOptions != null) {
			this._searchOptions = searchOptions;
		}


		this.listViewModel.pager.loading = true;
		this._data.getItems(this._searchOptions).subscribe({
			next: (items) => {
				if (items === undefined || items.length == 0) return; // no items

				let pager = items.pop()!.pager; // pop the pager
				this.listViewModel.items = items.map(x => {
					let item: UIListItem = new UIListItem();
					item.data = x;
					item.selected = false;
					item.id = x.systemAgentId!;
					item.showButton = x.systemUserId != this.auth.getLocalUserId();
					return item;
				});
				this.listViewModel.pager.update(pager!, this.listViewModel.items);
				this.listViewModel.pager.loading = false;
			},
			error: (err) => {
				this.listViewModel.pager.loading = false;
				this.listAlert.errorMessage = "Failed to get items. Reason: " + err.statusText;
			}
		});
	}

	deleteSelection(selectedItems: UIListItem[]) {
		let selectedIds = selectedItems.map(x => x.id!);
		this.listAlert.reset();
		this.listViewModel.pager.loading = true;

		this._data.deleteBulk(selectedIds).subscribe({
			next: () => {
				this.reloadList();
			},
			error: (err) => {
				this.listViewModel.pager.loading = false;
				this.listAlert.errorMessage = 'Failed to delete one or more items. Reason: ' + err.statusText;
			}
		});
	}

	updateField(change: UIListChange) {
		this._data.putColumn(change.updateOptions).subscribe({
			next: (result) => change.commit(result),
			error: (err) => this.listAlert.errorMessage = 'Failed to update field. Reason: ' + err.statusText,
		});
	}

	addItem() {
		this.showEditModal(0);
	}

	editItem(data: UIListItem) {
		this.showEditModal(data.id!);
	}

	//////////////////////////////////////////////////////////////////////
	// editview portion

	showEditModal(dataId: number) {
		// reset the edit view
		this.editViewModel.data = null;
		this.editViewModel.reset();

		// reload and show dialog
		this.editDataId = dataId;
		this.reloadEdit()
		this.uiModal.show();
	}

	closeEditModal() {
		this.editDataId = null;
		this.uiModal.hide();
	}

	reloadEdit() {
		this.editAlert.reset();
		if (this.editDataId == null) return;
		this.editLoading = true;

		if (this.editDataId == 0) {
			// enforce creating new items via the API. It's cleaner.
			this._data.getItemNew().subscribe({
				next: (item) => {
					// TODO: remove the comment block below if not needed
					// add new item => do some special treatment on the fields if needed:
					//this.editViewModel.editViewItems
					//	.filter(x => x.field == 'someField')
					//	.forEach(x => { x.disabled = false; x.required = true; });
					item.isActive = true; // default to items to active
					this.editViewModel.data = item;
					this.editViewModel.reset();
                    this.editLoading = false;
				},
				error: (err) => {
					this.editLoading = false;
					this.editAlert.errorMessage = 'Could not get new item. Reason: ' + err.statusText;
				}
			});

		} else {
			this._data.getItemById(this.editDataId).subscribe({
				next: (item) => {
					// TODO: remove the comment block below if not needed
					// edit a new item => do some special treatment on the fields if needed:
					//this.editViewModel.editViewItems
					//	.filter(x => x.field == 'someField')
					//	.forEach(x => { x.disabled = true; x.required = false; });
					this.editViewModel.data = item;
					this.editViewModel.reset();
					this.editLoading = false;
				},
				error: (err) => {
					this.editLoading = false;
					this.editAlert.errorMessage = 'Item not found. Reason: ' + err.statusText;
				}
			});
		}
	}

	onEditChange(item: UIEditViewItemComponent) {
		// TODO: react to a field change, if needed
	}

	onValidateData(data: any): UIEditViewValidateResult {
		let admin = <SystemAgentModel>data;
		let result = new UIEditViewValidateResult();

		return result;
	}

	onEditSave() {
		this.editAlert.reset();

		// must validate manually, since button is hosted on dialog modal, and not edit view.
		let result = this.uiEditViewComponent.validateData(); // this raises the error$ event
		if (!result.isValid) {
			return;
		}
		this.editSaving = true;
		let httpObservable: Observable<SystemAgentModel> | null = null;
		if (this.editDataId == 0) {
			httpObservable = this._data.post(this.editViewModel.data);
		} else {
			httpObservable = this._data.put(this.editDataId!, this.editViewModel.data);
		}
		httpObservable.subscribe({
			next: () => {
				this.editSaving = false;
				this.reloadList();
				this.closeEditModal();
			},
			error: (err) => {
				this.editSaving = false;
				let verb = this.editDataId == 0 ? "insert" : "update";
				this.editAlert.errorMessage = `Failed to ${verb} item. Reason: ${err.statusText}`;
			}
		});
	}

}
