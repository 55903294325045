
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

import { FileService } from './file.service';
import { saveAs } from 'file-saver';

// add imports
import { ListResultItem, IListResultItem } from '../models/list-result-item'; 
import { SystemLogModel, ISystemLogModel } from '../models/system-log-model'; 

@Injectable()
export class SystemLogsService {

	constructor(public _http: HttpClient, public _auth: AuthService, private _fileService: FileService) { } 
	
	public getAllDates(): Observable<ListResultItem[]> {
		
		return this._http.get<ListResultItem[]>(`api/System/SystemLogs/getAllDates`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IListResultItem[]).map(x => new ListResultItem(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public downloadLogs(i_oSearchOptions: SearchOptions): Observable<any> {
		
		return this._http.post(`api/System/SystemLogs/downloadLogs`, i_oSearchOptions, { headers: this._auth.initAuthHeaders(), observe: 'response', responseType: 'blob' })
			.pipe(
				map(response =>  {
					var blob = response.body;
					let filename = this._fileService.getFilenameFromResponse(response);
					saveAs(blob!, filename);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItems(i_oSearchOptions: SearchOptions): Observable<SystemLogModel[]> {
		
		return this._http.post<SystemLogModel[]>(`api/System/SystemLogs/getItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as ISystemLogModel[]).map(x => new SystemLogModel(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemById(id: number): Observable<SystemLogModel> {
		
		return this._http.get<SystemLogModel>(`api/System/SystemLogs/getItemById/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemLogModel(<ISystemLogModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
}

