import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { BuyerOffersService } from '@services/buyer-offers.service'
import {CommonModule} from "@angular/common";
import {
  ListResultItem,
  SearchOptions,
  UIAlertComponent, UIAlertItem, UIComponentBase,
  UIEditViewComponent, UIEditViewItem, UIEditViewItemComponent, UIEditViewModel, UIEditViewValidateResult,
  UIHeaderComponent, UIListChange, UIListItem, UIListItemStyle, UIListViewColumn,
  UIListViewComponent, UIListViewModel,
  UILoadingBarComponent, UIModalComponent
} from "@buildable/foundation";
import {AuthService} from "@services/auth.service";
import {CacheDataService} from "@services/cache-data.service";
import {SystemAdminsService} from "@services/system-admins.service";
import {SystemUsersService} from "@services/system-users.service";
import {SystemAdminModel} from "@models/system-admin-model";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged, noop,
  Observable,
  Observer,
  of,
  Subject,
  switchMap,
  tap
} from "rxjs";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {paymentMethodsList} from "@models/payment-methods";
import {FormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {MlsDataService} from "@services/mls-data.service";
import {SearchFields} from "@models/search-fields";
import {MlsDataModel} from "@models/mls-data-model";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'app-offer-form',
  standalone: true,
  imports: [
    CommonModule,
    UIAlertComponent, UIHeaderComponent, UIListViewComponent, UILoadingBarComponent, UIEditViewComponent,
    UIEditViewItemComponent, FormsModule, TypeaheadModule
  ],
  templateUrl: './offer-form.component.html',
  providers: [BuyerOffersService, MlsDataService]
})
export class OfferFormComponent extends UIComponentBase implements OnInit, OnDestroy{

  public editAlert: UIAlertItem = new UIAlertItem();
  public editViewModel: UIEditViewModel;
  public editDataId: number | null = 0;
  public editLoading: boolean = false;
  public editSaving: boolean = false;

  private _searchOptions: SearchOptions = new SearchOptions();

  // find a reference to the editview component
  @ViewChild(UIEditViewComponent)
  uiEditViewComponent!: UIEditViewComponent;

  // Typeahead variables
  public addressSuggestions: string[] = [];
  public typeaheadLoading: boolean = false;
  public typeaheadNoResults: boolean = false;
  public searchTerms = new Subject<string>();
  suggestions$?: Observable<string[]>;
  errorMessage?: string;

  constructor(
    public auth: AuthService,
    private _cacheData: CacheDataService,
    private _data: BuyerOffersService,
    private _mlsDataService: MlsDataService,
    private router: Router
  ) {
    super();

    //-------------------
    // setup the editview
    this.editViewModel = new UIEditViewModel({
      showInline: false,
      showButtons: true,
      saveButtonText: 'Submit',
      showSave: true,
      editViewItems: [
        new UIEditViewItem({ label: 'Address', field: 'address', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer Name', field: 'buyerName', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer Phone', field: 'buyerPhone', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer Email', field: 'buyerEmail', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer 2 Name', field: 'buyer2Name', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer 2 Phone', field: 'buyer2Phone', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer 2 Email', field: 'buyer2Email', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Asking Price', field: 'askingPrice', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Payment Method', field: 'paymentMethod', type: 'select',
          options: paymentMethodsList, size: 'full' }),
        new UIEditViewItem({ label: 'Down Payment', field: 'downPayment', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Earnest Money', field: 'earnestMoney', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Want to to sell Home?', field: 'buyerHasHomeToSell', type: 'checkbox', size: 'full' }),
        new UIEditViewItem({ label: 'Home to sell address', field: 'addressToSell', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Number of days to sell', field: 'noDaysToSell', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Closing Date', field: 'closingDate', type: 'date', size: 'full' }),
      ]
    });

    // subscribe to events when the model will be ready
    this.addSubscriptions(this.editViewModel.initialize$.subscribe(() => {
      this.addSubscriptions(
        this.editViewModel.reload$.subscribe(() => this.reloadEdit()),
        this.editViewModel.save$.subscribe(() => this.onEditSave()),
        this.editViewModel.error$.subscribe((message) => this.editAlert.errorMessage = message),
        this.editViewModel.change$.subscribe((item) => this.onEditChange(item)),
      );
    }));

    // setup the validation handlers
    this.editViewModel.beforeValidateDataCallback = (data: any) => { this.editAlert.reset(); }; // clear the error messages before validating
    this.editViewModel.validateDataCallback = (data: any) => { return this.onValidateData(data); } // hook to the local method (note we must use a lambda function, or 'this' will lose scope inside the function)
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    this._cacheData.loadCacheAll(); // remove if not needed

    this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => {
        return this._mlsDataService.getAddressSuggestions(this.createSearchOption(term));
      })
    );

    this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.editViewModel.data.address);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this._mlsDataService.getAddressSuggestions(this.createSearchOption(query)).pipe(
            map((data: string[]) => data || []),
            tap(() => noop, err => {
              // in case of http error
              this.errorMessage = err && err.statusText || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
  }


  private createSearchOption(address: string): SearchOptions {
    return new SearchOptions({
      fields: new SearchFields({
        address: address
      })
    });
  }

  //////////////////////////////////////////////////////////////////////
  // editview portion
  getAddressSuggestions(address: string) {
    this.searchTerms.next(address);
  }
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  reloadEdit() {
    this.editAlert.reset();
    if (this.editDataId == null) return;
    this.editLoading = true;

    if (this.editDataId == 0) {
      // enforce creating new items via the API. It's cleaner.
      this._data.getItemNew().subscribe({
        next: (item) => {
          this.editViewModel.data = item;
          this.editViewModel.reset();
          this.editLoading = false;
        },
        error: (err) => {
          this.editLoading = false;
          this.editAlert.errorMessage = 'Could not get new item. Reason: ' + err.statusText;
        }
      });

    } else {
      this._data.getItemById(this.editDataId).subscribe({
        next: (item) => {
          this.editViewModel.data = item;
          this.editViewModel.reset();
          this.editLoading = false;
        },
        error: (err) => {
          this.editLoading = false;
          this.editAlert.errorMessage = 'Item not found. Reason: ' + err.statusText;
        }
      });
    }
  }

  onEditChange(item: UIEditViewItemComponent) {
    // TODO: react to a field change, if needed
  }

  onValidateData(data: any): UIEditViewValidateResult {
    let offer = <BuyerOfferModel>data;
    let result = new UIEditViewValidateResult();

    return result;
  }

  onEditSave() {
    this.editAlert.reset();

    // must validate manually, since button is hosted on dialog modal, and not edit view.
    let result = this.uiEditViewComponent.validateData(); // this raises the error$ event
    if (!result.isValid) {
      return;
    }

    this.editSaving = true;
    let httpObservable: Observable<BuyerOfferModel> | null = null;
    if (this.editDataId == 0) {
      httpObservable = this._data.post(this.editViewModel.data);
    } else {
      httpObservable = this._data.put(this.editDataId!, this.editViewModel.data);
    }
    httpObservable.subscribe({
      next: (item: BuyerOfferModel) => {
        this.editSaving = false;
        this.editAlert.success('Successfully saved offer data.');
        this.router.navigate(['/buyer/offer-detail', item.buyerOfferId]);
      },
      error: (err) => {
        this.editSaving = false;
        let verb = this.editDataId == 0 ? "insert" : "update";
        this.editAlert.errorMessage = `Failed to ${verb} item. Reason: ${err.statusText}`;
      }
    });
  }
}
