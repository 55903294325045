<div class="full-height row">
  <div class="col-md-6 d-flex flex-column justify-content-center">
    <h1 class="h1-responsive fw-bold">Tell us about the home!</h1>
    <p class="mt-3 subheader">Type the address of the home you’re interested in </p>
    <div class="mt-3">
      <input type="text"
        class="wizard-input"
        [(ngModel)]="address"
        id="address"
        #addressInput
        (keyup)="getAddressSuggestions(addressInput.value)"
        [typeahead]="suggestions$"
        [typeaheadAsync]="true"
        placeholder="Enter Address"
        (ngModelChange)="updateBuyerOffer()"
      />
    </div>
  </div>

  <div class="col-md-6 d-flex flex-column justify-content-center">
<!--   -->
  </div>
</div>
