<div class="container ">
  <div class="card p-5 ">
    <div>
      <ui-alert [alert]="wizardAlert"></ui-alert>

      <div class="full-height ">
        <div class="d-flex flex-column justify-content-center">
          <div class="mb-lg-5 mb-2 pb-lg-5">
            <h1 class="h1-responsive fw-bold">Thanks! We will be in touch shortly.</h1>
            <p class="mt-3 subheader">Here's what to expect:</p>
          </div>

          <div class="d-flex flex-column justify-content-lg-center">
            <ol class="step-list horizontal d-none d-lg-flex">
              <li>We call you to <br> discuss</li>
              <li>You decide <br>go/ no go</li>
              <li>We prepare offer</li>
              <li>We submit & <br>negotiate</li>
              <li>We call with <br> seller response</li>
            </ol>
            <ol class="step-list vertical d-block d-lg-none">
              <li>We call you to discuss</li>
              <li>You decide go/ no go</li>
              <li>We prepare offer</li>
              <li>We submit & negotiate</li>
              <li>We call with seller response</li>
            </ol>
          </div>
        </div>
        </div>
    </div>

    <div>
      <div class="d-flex justify-content-end align-items-center">
        <button
          class="btn btn-primary btn-wizard"
          (click)="home()"
        >
          Home
        </button>
      </div>
    </div>
  </div>


</div>
