
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

export enum SystemPermission {
	// Enumeration values
  PortalAccess = 1,
	AdminPortalAccess = 2,
	AdminAdd = 3,
	AdminView = 4,
	AdminUpdate = 5,
	AdminDelete = 6,
	AdminUserAdd = 7,
	AdminUserView = 8,
	AdminUserUpdate = 9,
	AdminUserDelete = 10,
	UserAdd = 11,
	UserView = 12,
	UserUpdate = 13,
	UserDelete = 14,
  BuyerAdd = 15,
  BuyerView = 16,
  BuyerUpdate = 17,
  BuyerDelete = 18,
  AgentAdd = 19,
  AgentView = 20,
  AgentUpdate = 21,
  AgentDelete = 22,
}

export enum SystemPermissionAccessType {
	// Enumeration values
	View = 0,
	Add = 1,
	Update = 2,
	Delete = 3,
	Portal = 4,

}

export enum SystemPermissionGroupType {
	// Enumeration values
	User = 0,
	Admin = 1,
	AdminUser = 2,

}







