<div class="container">
	<ui-header [header]="'Change Password'"></ui-header>
	<div *ngIf="!successMessage">
		<div class="form-group">
			<label>Current Password</label>
			<input type="password" class="form-control" [(ngModel)]="password" required />
		</div>

		<div class="form-group">
			<label>Create a new password</label>
			<input type="password" class="form-control" [(ngModel)]="password1" required />
			<p class="text-muted small">Must contain at least eight characters, including one lowercase and one uppercase letter, one number, and one special character (! &#64; # $ or %).</p>
		</div>

		<div class="form-group">
			<label>Confirm new password</label>
			<input type="password" class="form-control mb-0" [(ngModel)]="password2" required />
		</div>
	</div>

	<div *ngIf="errorMessage" class="alert alert-danger">
		{{ errorMessage }}
	</div>
	<div *ngIf="successMessage" class="alert alert-success">
		{{ successMessage }}
	</div>

	<p *ngIf="updating" class="loading">Updating your password...</p>

	<p>
		<button *ngIf="!successMessage" [disabled]="updating" class="btn btn-secondary mr-2" (click)="cancel()">Cancel</button>
		<button *ngIf="!successMessage" [disabled]="updating" class="btn btn-success" (click)="changePassword()">Change password</button>
		<button *ngIf="successMessage" class="btn btn-primary" (click)="goToHome()">Go to login<fa-icon class="ml-2" [icon]="ARROW_RIGHT"></fa-icon></button>
	</p>
</div>