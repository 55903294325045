
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


import { Pager, IPager } from '@buildable/foundation'; 
// add imports
import { SystemAdminRoleAssignmentModel, ISystemAdminRoleAssignmentModel } from './system-admin-role-assignment-model'; 
import { SystemUserModel, ISystemUserModel } from './system-user-model'; 
import { ListResultItem, IListResultItem } from './list-result-item'; 



export interface ISystemAdminModel {
	// Inherited properties
	selected?: boolean | null;
	editing?: boolean | null;
	pager?: IPager | null;

	// Class properties
	systemAdminRoleAssignments?: ISystemAdminRoleAssignmentModel[] | null;
	systemUser?: ISystemUserModel | null;
	systemAdminId?: number | null;
	systemUserId?: number | null;
	isActive?: boolean | null;
	dateAdded?: Date | null;
	dateModified?: Date | null;
	systemRolesCSV?: string | null;
	systemRoles?: IListResultItem[] | null;
	systemUserEmailAddress?: string | null;
	systemUserFirstName?: string | null;
	systemUserLastName?: string | null;

}



export class SystemAdminModel implements ISystemAdminModel  {
	constructor(init?: Partial<ISystemAdminModel>) { // allows us to assign this object C# style
		Object.assign(this, init);
		// Date initializers
		if (init?.dateAdded) this.dateAdded = new Date(init.dateAdded);
		if (init?.dateModified) this.dateModified = new Date(init.dateModified);

	}

	
	// Inherited properties
	selected?: boolean | null = false;
	editing?: boolean | null = false;
	pager?: Pager | null = null;

	// Class properties
	systemAdminRoleAssignments?: SystemAdminRoleAssignmentModel[] | null = [];
	systemUser?: SystemUserModel | null = null;
	systemAdminId?: number | null = 0;
	systemUserId?: number | null = null;
	isActive?: boolean | null = true;
	dateAdded?: Date | null = null;
	dateModified?: Date | null = null;
	systemRolesCSV?: string | null = null;
	systemRoles?: ListResultItem[] | null = [];
	systemUserEmailAddress?: string | null = null;
	systemUserFirstName?: string | null = null;
	systemUserLastName?: string | null = null;

	
}



