import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { UIComponentBase, UIAlertItem, UIListViewColumn, UIListItem, UIListItemStyle, UIListViewModel, ListResultItem, UIAlertComponent, UIHeaderComponent, UIListViewComponent } from '@buildable/foundation';
import { SearchOptions, SearchFilter, SearchFilterOperatorEnum } from '@buildable/foundation';
import { SearchFilterCompareTypeEnum } from '@models/search-filter-compare-type-enum';
import { AuthService } from '@services/auth.service';

/* specific to this listview */
import { SystemLogsService } from '@services/system-logs.service';
import { Title } from '@angular/platform-browser';
import { SystemLogType } from '@models/system-log-type';
import { LogNinja } from '@services/log-ninja.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'system-log-master',
  standalone: true,
  imports: [
    CommonModule, FormsModule,
    UIAlertComponent, UIHeaderComponent, UIListViewComponent,
  ],
  templateUrl: './system-log-master.component.html',
  providers: [SystemLogsService], // does not need to be SINGLETON, or global (saves on space)
})
export class SystemLogMasterComponent extends UIComponentBase implements OnInit, OnDestroy {

  public listAlert: UIAlertItem = new UIAlertItem();
  public listViewModel: UIListViewModel;
  private _searchOptions: SearchOptions = new SearchOptions();

  public filterLogTypeSelected: number | null = null;
  public filterDateSelected: ListResultItem | null = null;
  public dateListBs = new BehaviorSubject<ListResultItem[]>([]);
  public tailLog = false;

  constructor(
    public auth: AuthService,
    private _router: Router,
    private _data: SystemLogsService,
    private _titleService: Title,
    private _logger: LogNinja
  ) {
    super();

    // Set the title of this page
    this._titleService.setTitle("Portal | Log");

    this.listViewModel = new UIListViewModel({
      showPager: true,
      showButtons: true,
      showSearch: true,
      showFilter: false,
      //addText: 'Add',
      addEnabled: false,
      //deleteText: 'Delete',
      deleteEnabled: false,
      viewText: 'View',
      viewEnabled: this.auth.hasRoleAdminView(),
      reloadText: '',
      reloadEnabled: true,
      inlineEditEnabled: false,
      showLoaderSkeleton: false, // disable the skeleton loading
      items: [],
      columns: [
        //new UIListViewColumn({ label: 'ID', field: 'SystemLogId' }),
        new UIListViewColumn({ label: 'Added', field: 'dateAdded', type: 'date' }),
        new UIListViewColumn({
          label: 'Type', field: 'systemLogTypeName', type: 'text', classFunc: (item) => {
            switch (item.data!['systemLogTypeId']!) {
              case SystemLogType.LowLevel: return 'badge rem-rounded-pill bg-secondary';
              case SystemLogType.Debug: return 'badge rem-rounded-pill bg-info text-dark';
              case SystemLogType.Info: return 'badge rem-rounded-pill bg-light text-dark';
              case SystemLogType.Warning: return 'badge rem-rounded-pill bg-warning text-dark';
              case SystemLogType.Error: return 'badge rem-rounded-pill bg-danger';
              case SystemLogType.Fatal: return 'badge rem-rounded-pill bg-danger';
              default: return 'badge rem-rounded-pill bg-light text-dark';
            }
          },
          style: new UIListItemStyle({ size: '1em' }),
        }),
        new UIListViewColumn({ label: 'Thread', field: 'threadId', type: 'text' }),
        new UIListViewColumn({ label: 'Source', field: 'sourceFileName', type: 'text' }),
        new UIListViewColumn({ label: 'Method', field: 'memberName', type: 'text' }),
        new UIListViewColumn({
          label: 'Description', field: 'descriptionShort', type: 'text',
          //classFunc: (item) => {
          //	switch (item.data.systemLogTypeId) {
          //		case SystemLogTypeModel.CST_LOWLEVEL: return 'badge rem-rounded-pill bg-secondary';
          //		case SystemLogTypeModel.CST_DEBUG: return 'badge rem-rounded-pill bg-info text-dark';
          //		case SystemLogTypeModel.CST_INFO: return 'badge rem-rounded-pill bg-light text-dark';
          //		case SystemLogTypeModel.CST_WARNING: return 'badge rem-rounded-pill bg-warning text-dark';
          //		case SystemLogTypeModel.CST_ERROR: return 'badge rem-rounded-pill bg-danger';
          //		case SystemLogTypeModel.CST_FATAL: return 'badge rem-rounded-pill bg-danger';
          //	}
          //},
          style: new UIListItemStyle({ size: '1em' }),
        }),
      ]
    });
    // subscribe to events when the model will be ready
    this.addSubscriptions(this.listViewModel.initialize$.subscribe(() => {
      this.addSubscriptions(
        this.listViewModel.reload$.subscribe(searchOptions => this.reload(searchOptions)),
        this.listViewModel.back$.subscribe(() => this._router.navigate(['/home', 0])),
        this.listViewModel.add$.subscribe(() => this._router.navigate(['/system/logs/item', 0])),
        this.listViewModel.view$.subscribe((item: UIListItem) => this._router.navigate(['/system/logs/item', item.id])),
      );
    }));
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    // getch all dates
    this._data.getAllDates().subscribe((item) => {
      this.dateListBs.next(item.reverse());
    });
  }

  filterLogType() {
    this.reload();
  }
  filterDate() {
    this.reload();
  }

  getSearchOptions(searchOptions?: SearchOptions | null): SearchOptions {
    if (searchOptions != null) {
      this._searchOptions = searchOptions;
    }

    this._searchOptions.filters = [[]];
    if (this.filterLogTypeSelected! > 0) {
      this._searchOptions.filters[0].push(
        new SearchFilter({
          compareType: SearchFilterCompareTypeEnum.Number,
          operatorType: SearchFilterOperatorEnum.Is,
          property: 'systemLogTypeId',
          value: this.filterLogTypeSelected!.toString(),
        })
      );
    }

    if (this.filterDateSelected) {
      this._logger.log('dateSelected', this.filterDateSelected);
      this._searchOptions.filters[0].push(
        new SearchFilter({
          compareType: SearchFilterCompareTypeEnum.DateTime,
          operatorType: SearchFilterOperatorEnum.Is,
          property: 'dateAdded',
          value: this.filterDateSelected.toString(),
        })
      );
    }
    return this._searchOptions;
  }

  reload(searchOptions?: SearchOptions | null, showLoader = true) {
    this.listAlert.reset();

    let options = this.getSearchOptions(searchOptions);

    if (showLoader) { // disable the ghosting when reloading from signalR
      this.listViewModel.pager.loading = true;
    }

    this._data.getItems(options).subscribe({
      next: (items) => {
        if (items === undefined || items.length == 0) return; // no items
        
        let pager = items.pop()!.pager; // pop the pager
        this.listViewModel.items = items.map(x => {
          let item: UIListItem = new UIListItem();
          item.data = x;
          item.selected = false;
          item.id = x.systemLogId!;
          item.showButton = true;
          return item;
        });
        this.listViewModel.pager.update(pager!, this.listViewModel.items);
        if (showLoader) { // disable the ghosting when reloading from signalR
          this.listViewModel.pager.loading = false;
        }
      },
      error: (err) => {
        this.listAlert.error("Failed to get records.", err.statusText);
        if (showLoader) { // disable the ghosting when reloading from signalR
          this.listViewModel.pager.loading = false;
        }
      }
    });
  }

  downloadLogs() {
    this.listAlert.reset();
    this.listAlert.info("Downloading log...");
    this.listViewModel.pager.loading = true;

    let options = this.getSearchOptions();
    // clone the options, so we can force the sorting. Note: does not support cloning Date objects.
    //options = JSON.parse(JSON.stringify(options)) as SearchOptions;
    //options.sortBy = "SystemLogId";
    //options.isSortAscending = true; // sort in chronological order of IDs

    this._data.downloadLogs(options).subscribe({
      next: () => {
        this.listAlert.reset();
        this.listViewModel.pager.loading = false;
      },
      error: (err) => {
        this.listAlert.error("Failed to download records.", err.statusText);
        this.listViewModel.pager.loading = false;
      }
    });
  }
}
