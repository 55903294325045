
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


// add imports
import { ListResultItem, IListResultItem } from './list-result-item'; 



export interface IListResult {
    
    // Class properties
	listName?: string | null;
	items?: IListResultItem[] | null;

}

export class ListResult implements IListResult  {
    constructor(init?: Partial<IListResult>) { // allows us to assign this object C# style
		Object.assign(this, init);
	}

    
    
    // Class properties
	listName?: string | null = null;
	items?: ListResultItem[] | null = [];

    
}



