<!-- login form -->
<ng-container>
  <div
    class="card p-3"
    style="width: 90%; max-width: 390px; margin: 0 auto;"
  >
    <div class="card-body text-left">
      <h4>Reset Password</h4>
      <p>We'll send you a link to reset your password.</p>
      <p>What's your email?</p>
      <p class="form-group">
        <input
          type="email"
          class="form-control"
          [(ngModel)]="resetPasswordEmail"
          required
        />
      </p>
      <div *ngIf="resetPasswordLoading()" class="loading">
        Please wait while we check the system...
      </div>
      <div *ngIf="resetPasswordErrorMessage()" class="alert alert-danger">
        {{ resetPasswordErrorMessage() }}
      </div>
      <div *ngIf="resetPasswordSuccessMessage()" class="alert alert-success">
        {{ resetPasswordSuccessMessage() }}
      </div>
      <button
        [disabled]="resetPasswordLoading() || resetPasswordSuccessMessage()"
        type="button"
        class="btn btn-primary mr-1"
        (click)="resetPassword()"
      >
        Send password reset email
      </button>
    </div>
  </div>
</ng-container>
