<div class="full-height row">
  <div class="col-md-6 d-flex flex-column justify-content-center">
    <h1 class="h1-responsive fw-bold">Who is buying this property?</h1>
    <p class="mt-3 subheader">A buyer is anyone who will be a legal owner of the home! </p>
    <div class="mt-4">
      <input type="text"
             class="wizard-input"
             [(ngModel)]="buyerName"
             id="buyer_name"
             placeholder="Buyer Name"
             (ngModelChange)="updateBuyerOffer()"
      >
    </div>
    <div class="mt-4">
      <input type="text"
             class="wizard-input"
             [(ngModel)]="buyerPhone"
             id="buyer_phone"
             placeholder="Buyer Phone"
             (ngModelChange)="updateBuyerOffer()"
      >
    </div>
    <div class="mt-4">
      <input type="text"
             class="wizard-input"
             [(ngModel)]="buyerEmail"
             id="buyer_email"
             placeholder="Buyer Email"
             (ngModelChange)="updateBuyerOffer()"
      >
    </div>
  </div>

</div>
