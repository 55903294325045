<div class="container">
	<div class="mx-auto">
		<div class="card">
			<div class="card-header">
				<h3 class="card-title mb-0">Unauthorized Access</h3>
			</div>
			<div class="card-body">
				<p class="mb-3">You are not authorized to access this area.</p>
				<p>
					<a [routerLink]="['/home']" class="btn btn-primary">
						<ng-container *ngIf="isLoggedIn(); else notLoggedIn">
							Go to homepage<fa-icon class="ml-2" [icon]="ARROW_RIGHT"></fa-icon>
						</ng-container>
						<ng-template #notLoggedIn>
							Go to login<fa-icon class="ml-2" [icon]="ARROW_RIGHT"></fa-icon>
						</ng-template>
					</a>
				</p>
			</div>
		</div>
	</div>
</div>

