import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";
import {MlsDataService} from "@services/mls-data.service";
import {SearchOptions} from "@buildable/foundation";
import {SearchFields} from "@models/search-fields";

@Component({
  selector: 'step-5-price',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule],
  templateUrl: './step-5-price.component.html',
  styleUrls: ['step-5-price.component.scss'],
  providers: [MlsDataService]
})
export class Step5PriceComponent implements OnInit {
  public askingPrice: number | null = null;

  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);
  private _mlsDataService = inject(MlsDataService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(): void {
    this.buyerOffer.askingPrice = this.askingPrice;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);
    this.setIsValid();
  }

  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.askingPrice = this.buyerOffer.askingPrice || null;
    this.setIsValid();

    this.getAskingPrice();
  }

  getAskingPrice() {
    const searchOptions = new SearchOptions({
      fields: new SearchFields({
        address: this.buyerOffer.address
      })
    })

    this._mlsDataService.getAskingPrice(searchOptions).subscribe({
      next: price => {
        // Set current price from MLS data as default
        if (this.buyerOffer.askingPrice == null || this.buyerOffer.askingPrice <= 0) {
          this.askingPrice = price;
          this.updateBuyerOffer();
        }
      }
    });
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.askingPrice != null && this.askingPrice > 0;
  }
}
