import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";

@Component({
  selector: 'step-10-home-to-sell-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule],
  templateUrl: './step-10-home-to-sell-address.component.html',
  styleUrls: ['step-10-home-to-sell-address.component.scss'],
})
export class Step10HomeToSellAddressComponent implements OnInit {

  public address: string = "";

  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(): void {
    this.buyerOffer.addressToSell = this.address;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);
    this.setIsValid();
  }

  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.address = this.buyerOffer.addressToSell || "";
    this.setIsValid();
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.address != null && this.address.trim().length > 0;
  }

}
