<div class="container mb-5">
  <ui-alert [alert]="listAlert"></ui-alert>
  <ui-header [header]="'Agents Management'"></ui-header>
  <p>Add Homewrite agents</p>
  <hr />
  <ui-listview [viewModel]="listViewModel"></ui-listview>
</div>

<!-- this the dialog to edit the list, no need for multiple components -->
<ui-modal (cancelClick)="closeEditModal()">
  <div header>
    <h4 class="modal-title">
      {{ editDataId == 0 ? "New Agent" : "Edit Agent" }}
    </h4>
    <ui-loading-bar
      [show]="editLoading || editSaving"
      [backgroundClass]="'bg-white'"
    ></ui-loading-bar>
  </div>
  <!-- we need the container-fluid to prevent horizontal scrollbar from appearing -->
  <div body class="container-fluid">
    <ui-alert [alert]="editAlert"></ui-alert>
    <ng-container *ngIf="editViewModel.data">
      <ui-editview [viewModel]="editViewModel"> </ui-editview>
    </ng-container>
  </div>
  <div footer>
    <button type="button" class="btn btn-light mr-2" (click)="closeEditModal()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success"
      [disabled]="!editViewModel.showSave"
      (click)="onEditSave()"
    >
      Save
    </button>
  </div>
</ui-modal>
