import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IconsModule } from '../../icons/icons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, IconsModule, RouterModule],
  templateUrl: './learn-more.component.html'
})
export class LearnMoreComponent {
  public readonly ARROW_RIGHT = faArrowRight;
  public isLoggedIn = signal(false);

  public auth = inject(AuthService);
  private _router = inject(Router);

  constructor() {
    this.isLoggedIn.set(this.auth.isLoggedIn());
  }
}
