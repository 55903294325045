import { Inject, Injectable, InjectionToken } from '@angular/core';

export const USE_LOG_NINJA = new InjectionToken<boolean>('USE_LOG_NINJA');

const VOID_FUNCTION = (..._: any[]) => { };

@Injectable({
  providedIn: 'root',
})
export class LogNinja {
  private $console: Partial<Console> = {
    log: console.log.bind(console),
    debug: console.debug.bind(console),
    error: console.error.bind(console),
    warn: console.warn.bind(console),
    clear: console.clear.bind(console),
  };

  public log = this.$console.log!;
  public debug = this.$console.debug!;
  public error = this.$console.error!;
  public warn = this.$console.warn!;
  public clear = this.$console.clear!;

  constructor(@Inject(USE_LOG_NINJA) shouldDisableLogger: boolean) {
    const localStorageDebug = localStorage.getItem('enableLogNinja') === 'true';

    if (shouldDisableLogger && !localStorageDebug) {
      this.log = VOID_FUNCTION;
      this.debug = VOID_FUNCTION;
      this.error = VOID_FUNCTION;
      this.warn = VOID_FUNCTION;
      this.clear = VOID_FUNCTION;
    }
  }
}
