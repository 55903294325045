
<main class="homepage">
  <div class="homepage__logo-header-container mb-4">
    <div class="homepage__logo-header"></div>
  </div>

  <div class="hero">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column my-6 px-4 px-md-0">
            <div>
              <h4 class="fw-normal h3-responsive mb-0">Agent expertise without the stress.</h4>
            </div>

            <ol class="hero-list h5-responsive glass-overlay">
              <li>Search for homes online</li>
              <li>Go to open houses</li>
              <li>Come to Homewrite.com</li>
            </ol>

            <div>
              <a *ngIf="isLoggedIn()" [routerLink]="['/buyer']" class="btn btn-secondary  text-uppercase px-3">
                Start an Offer
              </a>
              <a *ngIf="!isLoggedIn()" [routerLink]="['/register']" class="btn btn-secondary  text-uppercase px-3">
                Start an Offer
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="about">
    <div class="container">
      <div class="row py-6">
        <div class="col-12">
          <img
            src="/images/homepage/homepage_2.webp"
            alt="about-image"
            class="w-100 about-image image-cover">
        </div>

        <div class="col-lg-5 mt-5">
          <h4 class="about-header h2-responsive fw-bold">We are Homewrite.com</h4>
        </div>

        <div class="col-lg-5 mt-5">
          <ul class="about-list h5-responsive">
            <li>Offer writing & negotiation</li>
            <li>Closing Guidance</li>
            <li>No exclusive contracts</li>
            <li>Home by home basis </li>
            <li>24/7 Agent Availability</li>
          </ul>
        </div>

        <div class="col-lg-2 mt-5 about-offer">
          <p class="text-lg-center m-0">Proudly offering</p>
          <img src="/images/homewrite_offer.png" alt="homewrite offer" class="w-100 about-offer-image">
        </div>
      </div>
    </div>
  </div>

  <div class="homewrite-way">
    <div class="container">
      <div class="py-6">
        <div class="text-uppercase text-center">
          <h4 class="h2-responsive">The Homewrite way</h4>
        </div>

        <!-- Show on desktop devices -->
        <div class="homewrite-way-container horizontal mt-5 d-none d-lg-flex">

          <!-- Section: What You Do -->
          <div class="what-you-do">
            <h4 class="h4-responsive mb-4">WHAT YOU DO</h4>
            <div class="">
              <p>Tell us the home you want</p>
              <fa-icon [icon]="['fas', 'laptop']" class="icon"/>
            </div>
          </div>

          <div class="divider"></div>

          <!-- Section: What We Do -->
          <div class="what-we-do">
            <h2 class="h4-responsive mb-4">WHAT WE DO</h2>
            <div class="steps">
              <div class="step ">
                <fa-icon [icon]="['fas', 'phone']" class="icon"/>

                <p>Call you to strategize</p>
              </div>
              <div class="step">
                <div class="d-flex justify-content-center">
                  <img src="/images/homewrite_logo_icon.png" alt="logo-icon" class="logo-icon icon">
                </div>
                <p>Write your <br> offer</p>
              </div>
              <div class="step">
                <fa-icon [icon]="['fas', 'message']" class="icon"/>

                <p>Negotiate <br>Deal</p>
              </div>
              <div class="step">
                <fa-icon [icon]="['fas', 'headset']" class="icon"/>
                <p>Guide you to closing</p>
              </div>
              <div class="step last-child">
                <fa-icon [icon]="['fas', 'key']" class="icon"/>

                <p>Give you <br> Keys</p>
              </div>
            </div>
          </div>

        </div>

        <!-- Show on mobile devices -->
        <div class="homewrite-way-container vertical mt-5 d-flex d-lg-none">

          <!-- Section: What You Do -->
          <div class="what-you-do mb-2">
            <h4 class="h4-responsive mb-4">WHAT YOU DO</h4>
            <div class="">
              <p>Tell us the home you want</p>
              <fa-icon [icon]="['fas', 'laptop']" class="icon"/>
            </div>
          </div>

          <div class="divider"></div>

          <!-- Section: What We Do -->
          <div class="what-we-do mt-5">
            <h2 class="h4-responsive mb-4">WHAT WE DO</h2>
            <div class="steps">
              <div class="step ">
                <fa-icon [icon]="['fas', 'phone']" class="icon"/>

                <p>Call you to <br>strategize</p>
              </div>
              <div class="step">
                <div class="d-flex justify-content-center">
                  <img src="/images/homewrite_logo_icon.png" alt="logo-icon" class="logo-icon icon">
                </div>
                <p>Write your <br> offer</p>
              </div>
              <div class="step">
                <fa-icon [icon]="['fas', 'message']" class="icon"/>

                <p>Negotiate <br>Deal</p>
              </div>
              <div class="step">
                <fa-icon [icon]="['fas', 'headset']" class="icon"/>
                <p>Guide you <br>to closing</p>
              </div>
              <div class="step last-child">
                <fa-icon [icon]="['fas', 'key']" class="icon"/>

                <p>Give you <br> Keys</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="vision">
    <div class="container">
      <div class="row py-6">
        <div class="col-lg-6">
          <img
            src="/images/homepage/homepage_3.webp"
            alt="vision"
            class="w-100 image-cover"
          >
        </div>
        <div class="col-lg-6">
          <h4 class="h1-responsive text-white py-lg-5 pl-lg-4 pt-5">
            Our vision is to give
            you the guidance
            and expertise you
            need, without the
            stress of hiring an
            agent.
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="power-of-you my-6">
    <div class="container">
      <h2 class="h2-responsive text-center text-uppercase">The Power of You</h2>
      <div class="row pt-5">
        <div class="col-lg-3 d-flex justify-content-center mb-4">
          <img
            src="/images/homewrite_offer.png"
            alt="vision"
            class="w-100 offer-image"
          >
        </div>
        <div class="col-lg-8">
          <p class="h4-responsive">
            With Homewrite OFFER+ the power is in your
            hands. Only a fraction of a seller’s commission
            is needed for our services, empowering you to
            choose how to spend the rest.
          </p>
          <p class="h4-responsive">
            Make a more competitive offer, or make your
            home more affordable- the choice is yours.
          </p>

        </div>
      </div>
    </div>
  </div>

  <div class="founder-message py-6">
    <div class="container body-responsive px-3">
      <p>Dear Homebuyer,</p>
      <p>
        Homewrite.com is a real estate brokerage based in Kirkland Washington. We are built to empower the buyer of today, by
        enabling people to make offers on homes without the complexity, stress and cost of a traditional real estate agent.
      </p>
      <p>
        We believe in making your purchase simple, and ultimately about you. Instead of requiring exclusive contracts that tie you to one
        agent, you can use us as many times as you need, on a home by home basis, no commitment required. Our team of licensed
        local agents are available 24/7, ready to write your offer when it’s convenient for you.
      </p>
      <p>
        We recognize that the modern buyer can find their own home through the use of online searches and open houses. We also
        understand that while 90% of an agents time is spent on the home search, 90% of their value is provided during offer negotiations
        and closing. So that’s what we do: Write offers, negotiate, and guide you to closing.
      </p>
      <p>
        Our model encourages buyers to do what they’re already doing: Searching for homes. When you’ve found one for you, simply
        complete our “start offer” questionnaire and one of our agents will contact you to discuss. If you decide to submit an offer, we will
        spring into action and write your offer, negotiate it and guide you to closing. We handle inspections, coordinate with your lender,
        and provide guidance all the way until the day we hand you your keys.
      </p>
      <p>
        Homewrite.com believes in the empowerment of the buyer. Real estate agents aren’t the hero in this story, you are.
      </p>

      <div class="d-flex justify-content-end">
        <div>
          <img
            src="/images/homewrite_founder_signature.png"
            alt="founder signature"
            class="founder-signature"
          >
          <p>- Nico Lang <br> Founder, Homewrite.com</p>
        </div>
      </div>
    </div>
  </div>


  <div class="our-leaders py-6">
    <div class="container">
      <h2 class="h2-responsive text-center">Our Leaders</h2>
      <div class="row mt-lg-5">
        <div class="col-lg-6 d-flex flex-column align-items-center text-center mt-5 mt-lg-0">
          <img src="/images/headshot_nico_lang.webp" alt="Nico Lang" class="headshot">
          <h5 class="h5-responsive mt-4 font-weight-bolder">Nico Lang</h5>
          <p class=" body-responsive fw-bold">Founder and CEO</p>
          <p class="leader-description">Nico is a real estate veteran. He believes
            in empowering home buyers, and making
            home buying simpler, quicker & easier. </p>
        </div>

        <div class="col-lg-6 d-flex flex-column align-items-center text-center mt-5 mt-lg-0">
          <img src="/images/headshot_markus_echeverri.webp" alt="Markus Echeverri" class="headshot">
          <h5 class="h5-responsive mt-4 font-weight-bolder">Markus Echeverri</h5>
          <p class=" body-responsive fw-bold">Chief Financial Officer</p>
          <p class="leader-description">Markus guides Homewrite’s business and
            investor relations strategy, in an effort to
            reduce the cost of the real estate agent. </p>
        </div>

<!--        <div class="col-lg-4 d-flex flex-column align-items-center text-center mt-5 mt-lg-0">-->
<!--          <img src="/images/headshot_megan_suggs.webp" alt="Megan Suggs" class="headshot">-->
<!--          <h5 class="h5-responsive mt-4 font-weight-bolder">Megan Suggs</h5>-->
<!--          <p class=" body-responsive fw-bold">Chief Technology Officer</p>-->
<!--          <p class="leader-description">Megan oversees the development and-->
<!--            implementation of the platform we’re-->
<!--            building to simplify home buying. </p>-->
<!--        </div>-->

      </div>
    </div>
  </div>


  <div class="partners">
    <div class="container py-6">
      <h4 class="h2-responsive text-center">Proudly partnerning with</h4>
      <div class="d-flex justify-content-center mt-5">
        <img
          src="/images/homepage/homepage_4.webp"
          alt="Guild Mortgage logo"
          class="partner-logo"
        >
      </div>
    </div>
  </div>

  <footer class="homepage-footer py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h5 class="h3-responsive fw-bold">Contact Us</h5>
          <p class="body-responsive">11335 NE 122nd Way, Suite 105 <br>Kirkland, WA 98034
          <p class="body-responsive">206-612-4055<br>
            <a href="mailto:support@homewrite.com">support&#64;homewrite.com</a>
          </p>
        </div>

        <div class="col-lg-6 d-flex align-items-end">
          <img  src="/images/homepage/homepage_5.webp" alt="logo" class="w-100 footer-logo">
        </div>
      </div>
    </div>
  </footer>
</main>


