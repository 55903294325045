import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";

@Component({
  selector: 'step-11-home-to-sell-days',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule],
  templateUrl: './step-11-home-to-sell-days.component.html',
  styleUrls: ['step-11-home-to-sell-days.component.scss'],
})
export class Step11HomeToSellDaysComponent implements OnInit{

  public daysToSell: number | null = null;

  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(): void {
    this.buyerOffer.noDaysToSell = this.daysToSell;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);
    this.setIsValid();
  }

  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.daysToSell = this.buyerOffer.noDaysToSell || null;
    this.setIsValid();
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.daysToSell != null && this.daysToSell > 0;
  }
}
