<div class="container mb-5">
	<ui-header [header]="'User Management'"></ui-header>
	<ui-alert [alert]="listAlert"></ui-alert>
	<ui-listview [viewModel]="listViewModel">
		<ng-container ngProjectAs="[altButtons]">
			<button *ngIf="listViewModel.addEnabled" [disabled]="uiListViewComponent && !uiListViewComponent.hasSelection()"
					type="button" class="btn btn-link ml-2" (click)="showResetPasswordModal()">
				<fa-icon [icon]="['fas','sync']" class="mr-2"></fa-icon>Reset password<span *ngIf="uiListViewComponent && uiListViewComponent.getSelectionCount() > 1">s</span>
			</button>
		</ng-container>
	</ui-listview>
</div>

<!-- this the dialog to edit the list, no need for multiple components -->
<ui-modal (cancelClick)="closeResetPasswordModal()">
	<div header>
		<h4 class="modal-title">Reset Password<span *ngIf="uiListViewComponent && uiListViewComponent.getSelectionCount() > 1">s</span></h4>
		<ui-loading-bar [show]="resettingPassword" [backgroundClass]="'bg-white'"></ui-loading-bar>
	</div>
	<!-- we need the container-fluid to prevent horizontal scrollbar from appearing -->
	<div body class="container-fluid">
		<p class="mb-3">
			Are you sure you would like to reset the password<span *ngIf="uiListViewComponent && uiListViewComponent.getSelectionCount() > 1">s</span> for
			the selected user<span *ngIf="uiListViewComponent && uiListViewComponent.getSelectionCount() > 1">s</span>?
		</p>
		<div class="alert alert-warning">
			<div class="d-inline-flex">
				<fa-icon class="text-warning pr-3" [icon]="['fas','exclamation-triangle']"></fa-icon>
				<p class="mb-0">
					<strong>This action cannot be undone.</strong> The user<span *ngIf="uiListViewComponent && uiListViewComponent.getSelectionCount() > 1">s</span>
					will have to reset their password<span *ngIf="uiListViewComponent && uiListViewComponent.getSelectionCount() > 1">s</span>
					before they can log back in to the system.
				</p>
			</div>
		</div>
		<ui-alert [alert]="resettingAlert"></ui-alert>
	</div>
	<div footer>
		<button type="button" class="btn btn-primary mr-1" [disabled]="resettingPassword" (click)="onResetPassword()">
			Yes, reset password<span *ngIf="uiListViewComponent && uiListViewComponent.getSelectionCount() > 1">s</span>
		</button>
		<button type="button" class="btn btn-link" (click)="closeResetPasswordModal()">Cancel</button>
	</div>
</ui-modal>
