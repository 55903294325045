import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {Router, ActivatedRoute, RouterLink} from '@angular/router';

import { UIComponentBase } from '@buildable/foundation';
import { LogNinja } from '@services/log-ninja.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@app/icons/icons.module';
import {SystemUserModel} from "@models/system-user-model";
import {AuthUser} from "@models/auth-user";
import { ButtonLoadingDirective } from '@app/directives/button-loading.directive';

@Component({
  selector: 'home',
  standalone: true,
  imports: [
    CommonModule, FormsModule, FontAwesomeModule, IconsModule, RouterLink,
    ButtonLoadingDirective,
  ],
  templateUrl: './buyer-register.component.html',
  styleUrls: ['./buyer-register.component.scss']
})
export class BuyerRegisterComponent extends UIComponentBase implements OnInit, OnDestroy {


  public signingUp = false;
  public errorMessage = '';
  public showSuccessMessage = false;

  public authUser = new AuthUser();
  public userConfirmPassword = '';


  private _debug = false;


  public auth = inject(AuthService);
  private _logger = inject(LogNinja);

  constructor() {
    super();
  }

  ngOnInit() {

  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }



  buyerRegister() {
    if (!this.authUser.password || this.authUser.password.length < 0) {
      this.errorMessage = 'A password is required';
      return;
    }

    if (this.authUser.password !== this.userConfirmPassword) {
      this.errorMessage = "Your passwords don't match";
      return;
    }

    this.signingUp = true;
    this.errorMessage = '';
    this.auth.buyerRegister(this.authUser).subscribe({
      next: () => {
        this.errorMessage = '';
        this.signingUp = false;
        this.showSuccessMessage = true;
      },
      error: (error: any) => {
        this.signingUp = false;
        this.errorMessage = error.statusText;
      },
      complete: () => {
        if (this._debug) this._logger.log('sign up finished');
      }
    });
  }


}
