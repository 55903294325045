
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


import { Pager, IPager } from '@buildable/foundation'; 



export interface IListResultItem {
    // Inherited properties
	selected?: boolean | null;
	editing?: boolean | null;
	pager?: IPager | null;

    // Class properties
	label?: string | null;
	value?: any | null;
	checked?: boolean | null;
	group?: string | null;
	description?: string | null;

}

export class ListResultItem implements IListResultItem  {
    constructor(init?: Partial<IListResultItem>) { // allows us to assign this object C# style
		Object.assign(this, init);
	}

    
    // Inherited properties
	selected?: boolean | null = false;
	editing?: boolean | null = false;
	pager?: Pager | null = null;

    // Class properties
	label?: string | null = null;
	value?: any | null = null;
	checked?: boolean | null = null;
	group?: string | null = null;
	description?: string | null = null;

    
}



