<div class="full-height row">
  <div class="d-flex flex-column justify-content-center">
    <div class="pt-4 px-3">
      <h1 class="h1-responsive fw-bold">Which payment method will you be using?</h1>
    </div>
    <div class="col-md-6 mt-4">
      <fieldset class="payment-method-options">
        <label class="payment-box" [ngClass]="{'active': paymentMethod == PaymentMethods.Cash}">
          <input type="radio"
            [(ngModel)]="paymentMethod"
            name="paymentMethod"
            [value]="PaymentMethods.Cash"
            (ngModelChange)="updateBuyerOffer()"
          >
          <span>Cash</span>
        </label>
        <label class="payment-box" [ngClass]="{'active': paymentMethod == PaymentMethods.Financing}">
          <input type="radio"
            [(ngModel)]="paymentMethod"
            name="paymentMethod"
            [value]="PaymentMethods.Financing"
            (ngModelChange)="updateBuyerOffer()"
          />
          <span>Financing</span>
        </label>
      </fieldset>
    </div>
  </div>

</div>
