
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder





export interface IUpdateOptions {
    
    // Class properties
	itemId?: number | null;
	columnName?: string | null;
	value?: any | null;

}

export class UpdateOptions implements IUpdateOptions  {
    constructor(init?: Partial<IUpdateOptions>) { // allows us to assign this object C# style
		Object.assign(this, init);
	}

    
    
    // Class properties
	itemId?: number | null = 0;
	columnName?: string | null = null;
	value?: any | null = null;

    
}



