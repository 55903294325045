import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';

import {
  SearchOptions,
  UIAlertComponent,
  UIAlertItem,
  UIComponentBase,
  UIEditViewComponent,
  UIHeaderComponent,
  UIListItem,
  UIListViewColumn,
  UIListViewComponent,
  UIListViewModel,
  UILoadingBarComponent,
  UIModalComponent
} from '@buildable/foundation';

import {AuthService} from '@services/auth.service';
import {CacheDataService} from '@services/cache-data.service';

/* specific to this listview */
import {CommonModule} from '@angular/common';
import {BuyerOffersService} from "../../services/buyer-offers.service";
import {OfferStatus} from "@models/offer-status";
import {Router, RouterLink} from "@angular/router";
import {SearchFields} from "@models/search-fields";
import {getStatusText, getStatusTextClass} from "@app/utils/offer-status-utils";
import {NgSelectComponent} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";

@Component({
	selector: 'offers-list',
	standalone: true,
  imports: [
    CommonModule,
    UIAlertComponent, UIHeaderComponent, UIListViewComponent, UILoadingBarComponent, UIEditViewComponent, UIModalComponent, NgSelectComponent, FormsModule, RouterLink,
  ],
	templateUrl: './offers-list.component.html',
	providers: [BuyerOffersService], // does not need to be SINGLETON, or global (saves on space)
})
export class OffersListComponent extends UIComponentBase implements OnInit, OnDestroy, AfterViewInit {

	public listAlert: UIAlertItem = new UIAlertItem();
	public listViewModel: UIListViewModel;

	private _searchOptions: SearchOptions = new SearchOptions();

  @ViewChild('viewBtnColumn') viewBtnColumnTemplate!: TemplateRef<any>;
  @ViewChild('statusColumn') statusColumnTemplate!: TemplateRef<any>;

  public filterOptions = [
    {
      "value": OfferStatus.OfferOpen,
      "label": "Open"
    },
    {
      "value": OfferStatus.OfferApproved,
      "label": "Approved"
    },
    {
      "value": OfferStatus.OfferRejected,
      "label": "Rejected"
    },
    {
      "value": OfferStatus.OfferSigned,
      "label": "Signed"
    },
    {
      "value": OfferStatus.OfferCompleted,
      "label": "Completed"
    },

  ];

  public selectedStatus: OfferStatus | null = null;

	constructor(
		public auth: AuthService,
		private _cacheData: CacheDataService,
		private _data: BuyerOffersService,
    private _router: Router,
	) {
		super();

		//--------------------------
		// setup the compact listview
		this.listViewModel = new UIListViewModel({
			showPager: true,
			showButtons: true,
			showTitle: false,
			showSearch: true,
			showFilter: false,
			addEnabled: false,
			deleteText: 'Delete',
			deleteEnabled: false,
			viewEnabled: false,
      viewText:'View',
			reloadText: '',
			reloadEnabled: true,
			inlineEditEnabled: false,
      showCheckbox: false,
			items: [],
			columns: [
        new UIListViewColumn({ label: '', field: 'viewOfferBtn', type: 'custom' }),
        new UIListViewColumn({ label: 'Status', field: 'status', type: 'custom' }),
        new UIListViewColumn({ label: 'Buyer Name', field: 'buyerName', type: 'text', link: true }),
        new UIListViewColumn({ label: 'Email', field: 'buyerEmail', type: 'email' }),
        new UIListViewColumn({ label: 'Phone', field: 'buyerPhone', type: 'phone' }),
				new UIListViewColumn({ label: 'Home Address', field: 'address', type: 'text'  }),
				new UIListViewColumn({ label: 'Date Added', field: 'dateAdded', type: 'date' }),
			]
		});
		// subscribe to events when the model will be ready
		this.addSubscriptions(this.listViewModel.initialize$.subscribe(() => {
			this.addSubscriptions(
				this.listViewModel.reload$.subscribe((searchOptions?) => this.reloadList(searchOptions)),
				this.listViewModel.view$.subscribe((item: UIListItem) => this.viewItem(item)),
				this.listViewModel.delete$.subscribe((items: UIListItem[]) => this.deleteSelection(items)),
			);
		}));

	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}

	ngOnInit() {
		this._cacheData.loadCacheAll(); // remove if not needed

	}

  ngAfterViewInit() {
    setTimeout(() => {
      this.listViewModel.columns.find(x => x.field == 'status')!.customItemTemplate = this.statusColumnTemplate;
      this.listViewModel.columns.find(x => x.field == 'viewOfferBtn')!.customItemTemplate = this.viewBtnColumnTemplate;
    }, 0);
  }


  //////////////////////////////////////////////////////////////////////
	// listview portion

	reloadList(searchOptions?: SearchOptions | null) {
		this.listAlert.reset();

		if (searchOptions != null) {
			this._searchOptions = searchOptions;
		} else {
      this._searchOptions.sortBy = "dateAdded";
      this._searchOptions.isSortAscending = false;
    }

    this._searchOptions.fields = new SearchFields({
      offerStatuses: [
        OfferStatus.OfferOpen,
        OfferStatus.OfferApproved,
        OfferStatus.OfferCompleted,
        OfferStatus.OfferSigned
      ]
    });

    if (this.selectedStatus != null) {
      this._searchOptions.fields = new SearchFields({
        offerStatuses: [this.selectedStatus]
      });
    }

		this.listViewModel.pager.loading = true;
		this._data.getItems(this._searchOptions).subscribe({
			next: (items) => {
				if (items === undefined || items.length == 0) return; // no items

				let pager = items.pop()!.pager; // pop the pager
				this.listViewModel.items = items.map(x => {
					let item: UIListItem = new UIListItem();
					item.data = x;
					item.selected = false;
					item.id = x.buyerOfferId!;
					item.showButton = x.systemUserId != this.auth.getLocalUserId();
					return item;
				});
				this.listViewModel.pager.update(pager!, this.listViewModel.items);
				this.listViewModel.pager.loading = false;
			},
			error: (err) => {
				this.listViewModel.pager.loading = false;
				this.listAlert.errorMessage = "Failed to get items. Reason: " + err.statusText;
			}
		});
	}

  viewItem(item: UIListItem) {
    console.log('view')
    this._router.navigate(['/agent/offers', item.id])
  }

	deleteSelection(selectedItems: UIListItem[]) {
		let selectedIds = selectedItems.map(x => x.id!);
		this.listAlert.reset();
		this.listViewModel.pager.loading = true;

		this._data.deleteBulk(selectedIds).subscribe({
			next: () => {
				this.reloadList();
			},
			error: (err) => {
				this.listViewModel.pager.loading = false;
				this.listAlert.errorMessage = 'Failed to delete one or more items. Reason: ' + err.statusText;
			}
		});
	}


  protected readonly getStatusText = getStatusText;
    protected readonly getStatusTextClass = getStatusTextClass;
  protected readonly OfferStatus = OfferStatus;
}
