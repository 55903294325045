import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {PaymentMethods} from "@models/payment-methods";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";

@Component({
  selector: 'step-9-home-to-sell',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule],
  templateUrl: './step-9-home-to-sell.component.html',
  styleUrls: ['step-9-home-to-sell.component.scss']
})
export class Step9HomeToSellComponent implements OnInit{
  public buyerHasHomeToSell: boolean | null = null;

  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(): void {
    this.buyerOffer.buyerHasHomeToSell = this.buyerHasHomeToSell;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);
    this.setIsValid();
  }

  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.buyerHasHomeToSell = this.buyerOffer.buyerHasHomeToSell || null;
    this.setIsValid();
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.buyerHasHomeToSell != null;
  }
}
