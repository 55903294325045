<div class="container mb-5">
  <div class="mb-2">
    <a class="text-secondary mb-2" [routerLink]="['/agent/offers']">
      <fa-icon [icon]="['fas', 'arrow-left']"/>
      Back to Offers
    </a>
  </div>

  <div *ngIf="offer.buyerOfferId && !offer.mlsDataId" class="alert alert-warning" role="alert">
    Please review the offer. The address does not match any MLS records in our system, and approval cannot be processed.
  </div>

  <ui-alert [alert]="editAlert"></ui-alert>


  <div class="d-md-flex justify-content-between align-items-center">
    <div class="d-flex align-items-end">
      <ui-header [header]="'Offer Detail'"></ui-header>
      <div class="mb-1">
        <p *ngIf="offer.status" class="ml-2" [ngClass]="getStatusTextClass(offer.status)">
          {{ getStatusText(offer.status) }}
        </p>
      </div>
    </div>
    <div *ngIf="offer.status == OfferStatus.OfferOpen">
      <button class="btn btn-secondary mr-2" (click)="openEditModal()">
        <fa-icon [icon]="['fas', 'pencil']" class="mr-2"/>
        Edit
      </button>

      <button class="btn btn-primary" (click)="openApproveModal()" [disabled]="editLoading || !offer.mlsDataId">
        <span *ngIf="!editLoading">
          <fa-icon [icon]="['fas', 'check']" class="mr-2"/>
          Approve
        </span>
        <span *ngIf="editLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="editLoading"> Loading</span>
      </button>
    </div>
  </div>

  <hr>

  <div class="d-flex">
    <button
      *ngIf="offer.preApprovalFileKey != null"
      class="btn btn-light mr-4"
      (click)="downloadFile(offer.preApprovalFileKey)"
      [disabled]="isDownloadingFile"
    >
      <fa-icon [icon]="['fas', 'download']" class="mr-2"></fa-icon>
      Download pre-approval
    </button>

    <button
      *ngIf="offer.offerDocument != null && offer.offerDocument.generatedDocumentKey != null"
      class="btn btn-light mr-4"
      (click)="downloadFile(offer.offerDocument.generatedDocumentKey)"
      [disabled]="isDownloadingFile"
    >
      <fa-icon [icon]="['fas', 'download']" class="mr-2"></fa-icon>
      Download generated form
    </button>

    <button
      *ngIf="offer.offerDocument != null && offer.offerDocument.signedDocumentKey != null"
      class="btn btn-info"
      (click)="downloadFile(offer.offerDocument.signedDocumentKey)"
      [disabled]="isDownloadingFile"
    >
      <fa-icon [icon]="['fas', 'download']" class="mr-2"></fa-icon>
      Download signed form
    </button>
  </div>

  <hr>


  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Address:</div>
        <div class="">{{ offer.address }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Buyer Name:</div>
        <div class="">{{ offer.buyerName }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Buyer Phone:</div>
        <div>{{ offer.buyerPhone }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Buyer Email:</div>
        <div>{{ offer.buyerEmail }}</div>
      </div>
    </div>


  </div>

  <div class="row mt-4">

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Asking Price:</div>
        <div>{{ offer.askingPrice | currency }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Payment Method:</div>
        <div>{{ getPaymentMethodName(offer.paymentMethod) }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Down Payment:</div>
        <div class="">{{ offer.downPayment | currency }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Earnest Money:</div>
        <div>{{ offer.earnestMoney | currency }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Want to sell home?:</div>
        <div>{{ offer.buyerHasHomeToSell ? "Yes" : "No" }}</div>
      </div>
    </div>

    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Home to sell address:</div>
        <div class="">{{ offer.addressToSell }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Number of days to sell:</div>
        <div *ngIf="offer?.noDaysToSell != null">
          {{ offer.noDaysToSell }} days
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">


    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Closing date:</div>
        <div>{{ offer.closingDate | date}}</div>
      </div>
    </div>

  </div>

  <hr>

  <h3 class="mt-4">Mls Data for Offer</h3>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Style Code:</div>
        <div class="">{{ offer.mlsData?.styleCode || '-' }}</div>
      </div>
    </div>
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Listing Number:</div>
        <div class="">{{ offer.mlsData?.listingNumber || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Owner Name:</div>
        <div class="">{{ offer.mlsData?.ownerName || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Owner Name 2:</div>
        <div>{{ offer.mlsData?.ownerName2 || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">APN:</div>
        <div>{{ offer.mlsData?.apn || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Unit:</div>
        <div>{{ offer.mlsData?.unit || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Building Complex or Project Name:</div>
        <div class="">{{ offer.mlsData?.buildingComplexOrProjectName || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Parking Space Numbers:</div>
        <div>{{ offer.mlsData?.parkingSpaceNumbers || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Storage No:</div>
        <div>{{ offer.mlsData?.storageNo || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Address:</div>
        <div class="">{{ offer.mlsData?.address || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">City:</div>
        <div>{{ offer.mlsData?.city || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">County:</div>
        <div>{{ offer.mlsData?.county || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">State:</div>
        <div class="">{{ offer.mlsData?.state || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Zip Code:</div>
        <div>{{ offer.mlsData?.zipCode || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Appliances Included:</div>
        <div>{{ offer.mlsData?.appliancesIncluded || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Current Price:</div>
        <div>{{ offer.mlsData?.currentPrice | currency }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Title Company:</div>
        <div class="">{{ offer.mlsData?.titleCompany || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Possession:</div>
        <div>{{ offer.mlsData?.possession || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Offers Review Date:</div>
        <div>{{ offer.mlsData?.offersReviewDateString || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">FIRPTA:</div>
        <div class="">{{ offer.mlsData?.firpta ? "Yes" : "No" || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Association Dues:</div>
        <div>{{ offer.mlsData?.associationDues | currency }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">BBC:</div>
        <div>{{ offer.mlsData?.bbc || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Owner Phone Number:</div>
        <div>{{ offer.mlsData?.ownerPhoneNumber || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Listing Office Name:</div>
        <div class="">{{ offer.mlsData?.listingOfficeName || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Office ID:</div>
        <div>{{ offer.mlsData?.listingOfficeId || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Agent Full Name:</div>
        <div>{{ offer.mlsData?.listingAgentFullName || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Listing Agent ID:</div>
        <div class="">{{ offer.mlsData?.listingAgentId || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Office Phone:</div>
        <div>{{ offer.mlsData?.listingOfficePhone || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Agent Cellular:</div>
        <div>{{ offer.mlsData?.listingAgentCellular || '-' }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Water Company:</div>
        <div>{{ offer.mlsData?.waterCompany || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4 pb-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Power Company:</div>
        <div class="">{{ offer.mlsData?.powerCompany || '-'}}</div>
      </div>
    </div>
  </div>

</div>


<!-- this the dialog to edit the offer, no need for multiple components -->
<ui-modal #editModal (cancelClick)="closeEditModal()" [modalLarge]="true">
  <div header>
    <h4 class="modal-title">
      Edit Offer
    </h4>
    <ui-loading-bar
      [show]="editLoading || editSaving"
      [backgroundClass]="'bg-white'"
    ></ui-loading-bar>
  </div>
  <!-- we need the container-fluid to prevent horizontal scrollbar from appearing -->
  <div body class="container-fluid">
    <ui-alert [alert]="editAlert"></ui-alert>
    <ng-container *ngIf="editViewModel.data">
      <ui-editview [viewModel]="editViewModel"  [customLayout]="true">
        <div customLayout>
          <div class="row mb-2">
            <div class="col-12">
              <label for="address">Address</label>
              <input type="text"
                     class="ui-editview-input form-control w-md-100"
                     [(ngModel)]="editViewModel.data.address"
                     id="address"
                     #addressInput
                     (keyup)="getAddressSuggestions(addressInput.value)"
                     [typeahead]="suggestions$"
                     [typeaheadAsync]="true"
              >
            </div>
          </div>

          <div class="row mb-2">
            <ui-editview-item field="buyerName" class="col-md-4"></ui-editview-item>
            <ui-editview-item field="buyerPhone" class="col-md-4"></ui-editview-item>
            <ui-editview-item field="buyerEmail" class="col-md-4"></ui-editview-item>
          </div>

          <!--        <div class="row mb-2">-->
          <!--          <ui-editview-item field="buyer2Name" class="col-md-4"></ui-editview-item>-->
          <!--          <ui-editview-item field="buyer2Phone" class="col-md-4"></ui-editview-item>-->
          <!--          <ui-editview-item field="buyer2Email" class="col-md-4"></ui-editview-item>-->
          <!--        </div>-->

          <div class="row mb-2">
            <ui-editview-item field="askingPrice" class="col-md-4"></ui-editview-item>
            <ui-editview-item field="paymentMethod" class="col-md-4"></ui-editview-item>
            <ui-editview-item field="downPayment" class="col-md-4"></ui-editview-item>
          </div>

          <div class="row mb-2">
            <ui-editview-item field="earnestMoney" class="col-md-4"></ui-editview-item>
            <ui-editview-item field="buyerHasHomeToSell" class="col-md-4"></ui-editview-item>
          </div>

          <div *ngIf="editViewModel.data.buyerHasHomeToSell" class="row mb-2">
            <ui-editview-item field="addressToSell" class="col-md-8"></ui-editview-item>
            <ui-editview-item field="noDaysToSell" class="col-md-4"></ui-editview-item>
          </div>

          <div class="row mb-4">
            <ui-editview-item field="closingDate" class="col-md-4"></ui-editview-item>
          </div>

        </div>
      </ui-editview>
    </ng-container>
  </div>
  <div footer>
    <button type="button" class="btn btn-light mr-2" (click)="closeEditModal()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success"
      [disabled]="!editViewModel.showSave"
      (click)="onEditSave()"
    >
      Save
    </button>
  </div>
</ui-modal>


<!-- this the dialog to confirm approve the offer, no need for multiple components -->
<ui-modal #approveModal (cancelClick)="closeApproveModal()" [modalLarge]="true">
  <div header>
    <h4 class="modal-title">
      Confirm Approve Offer
    </h4>
    <ui-loading-bar
      [show]="editLoading || editSaving"
      [backgroundClass]="'bg-white'"
    ></ui-loading-bar>
  </div>
  <!-- we need the container-fluid to prevent horizontal scrollbar from appearing -->
  <div body class="container-fluid">
    <p>
      After you approve this offer, a copy will be sent to the DocuSign for the Buyer to review.
      You'll be notified when the document is signed.
    </p>
  </div>
  <div footer>
    <button type="button" class="btn btn-light mr-2" (click)="closeApproveModal()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="approveOffer()"
    >
      Approve
    </button>
  </div>
</ui-modal>

