
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


import { Pager, IPager } from '@buildable/foundation'; 
// add imports
import { SystemRolePermissionAssignmentModel, ISystemRolePermissionAssignmentModel } from './system-role-permission-assignment-model'; 
import { ListResultItem, IListResultItem } from './list-result-item'; 
import { SystemRoleGroups } from './system-role-groups'; 



export interface ISystemRoleModel {
	// Inherited properties
	selected?: boolean | null;
	editing?: boolean | null;
	pager?: IPager | null;

	// Class properties
	systemRolePermissionAssignments?: ISystemRolePermissionAssignmentModel[] | null;
	systemRoleId?: number | null;
	systemRoleGroup?: SystemRoleGroups | null;
	name?: string | null;
	isActive?: boolean | null;
	isDefaultForGroup?: boolean | null;
	isAdminDefaultForGroup?: boolean | null;
	dateAdded?: Date | null;
	dateModified?: Date | null;
	systemPermissions?: IListResultItem[] | null;
	systemRoleGroupName?: string | null;

}



export class SystemRoleModel implements ISystemRoleModel  {
	constructor(init?: Partial<ISystemRoleModel>) { // allows us to assign this object C# style
		Object.assign(this, init);
		// Date initializers
		if (init?.dateAdded) this.dateAdded = new Date(init.dateAdded);
		if (init?.dateModified) this.dateModified = new Date(init.dateModified);

	}

	
	// Inherited properties
	selected?: boolean | null = false;
	editing?: boolean | null = false;
	pager?: Pager | null = null;

	// Class properties
	systemRolePermissionAssignments?: SystemRolePermissionAssignmentModel[] | null = [];
	systemRoleId?: number | null = 0;
	systemRoleGroup?: SystemRoleGroups | null = null;
	name?: string | null = null;
	isActive?: boolean | null = true;
	isDefaultForGroup?: boolean | null = null;
	isAdminDefaultForGroup?: boolean | null = null;
	dateAdded?: Date | null = null;
	dateModified?: Date | null = null;
	systemPermissions?: ListResultItem[] | null = [];
	systemRoleGroupName?: string | null = null;

	
}



