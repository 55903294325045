import { Directive, Input, ViewContainerRef, ElementRef, ComponentRef } from '@angular/core';
import { ButtonLoadingSpinnerComponent } from './directive-components/button-loading-spinner.component';
import { CommonModule } from '@angular/common';

@Directive({
  selector: 'button[loading]',
  standalone: true,
  providers: [CommonModule]
})
/**
 * This directive is used to show a loading spinner inside a button. Just set loading="true" on the button to show the spinner.
 * Also disables the button while the spinner is shown.
 */
export class ButtonLoadingDirective {

  public spinnerRef: ComponentRef<ButtonLoadingSpinnerComponent>
  isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;


  constructor(
    private elementRef: ElementRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    // add new instance of MatProgressSpinner to the viewContainer
    const spinnerRef = this.viewContainer.createComponent(ButtonLoadingSpinnerComponent);
    const spinnerEl = spinnerRef.location.nativeElement as HTMLElement;
    spinnerEl.classList.add('d-flex');
    spinnerEl.classList.add('justify-content-center');
    spinnerEl.classList.add('align-items-center');
    spinnerEl.classList.add('hidden');  // initially hidden

    spinnerEl.classList.add('button-loading-spinner');
    spinnerEl.style.height = '0';

    // hack for firefox since it doesn't actually enable subpixel unless you do this
    // subpixel is critical othwerwise the animation will look choppy
    if (this.isFirefox) {
      spinnerEl.style.transform = 'rotate(0.1deg)'
    }

    this.spinnerRef = spinnerRef;

    const buttonEl = this.elementRef.nativeElement as HTMLButtonElement;

    // there are weird cases where the button element get's moved around in the dom, causing this to be a sibling of the button
    //    instead of a child. This is a hack to fix that
    setTimeout(() => {
      buttonEl.appendChild(spinnerEl);
    }, 1);
  }

  /**
   * hide all children of the button except the spinner
   */
  hideAllChildren() {
    const children = this.elementRef.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].classList.contains('button-loading-spinner')) {
        continue;
      }
      children[i].classList.add('hidden');
    }
  }

  /**
   * show all children of the button except the spinner
   */
  showAllChildren() {
    const children = this.elementRef.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].classList.contains('button-loading-spinner')) {
        continue;
      }
      children[i].classList.remove('hidden');
    }
  }


  @Input() set loading(isLoading: boolean) {
    console.log('loading', isLoading);
    if (isLoading) {

      // important to set the width and height of the button to the current width and height
      // otherwise the button will shift in size when the spinner is shown
      const boundingRect = this.elementRef.nativeElement.getBoundingClientRect();
      this.elementRef.nativeElement.style.width = boundingRect.width + 'px';
      this.elementRef.nativeElement.style.height = boundingRect.height + 'px';

      this.elementRef.nativeElement.disabled = true;
      this.elementRef.nativeElement.classList.add('button-loading-spinner');

      // show the spinner
      this.spinnerRef.location.nativeElement.classList.remove('hidden');
      this.hideAllChildren();

    } else {

      this.elementRef.nativeElement.disabled = false;
      this.elementRef.nativeElement.classList.remove('button-loading-spinner');
      this.elementRef.nativeElement.style.width = '';
      this.elementRef.nativeElement.style.height = '';

      // hide the spinner
      this.spinnerRef.location.nativeElement.classList.add('hidden');
      this.showAllChildren();
    }
  }
}
