import { Component, inject, OnInit } from '@angular/core';
import { UIComponentBase } from '@buildable/foundation';
import { AuthService } from '@services/auth.service';
import {ActivatedRoute, RouterLink} from '@angular/router';
import { LogNinja } from '@services/log-ninja.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-registration',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './user-registration.component.html',
  styles: []
})
export class UserRegistrationComponent extends UIComponentBase implements OnInit {
  public loading = true;
  public validHash = false;
  public errorMessageHash = '';
  public errorMessage = '';
  public successMessage = '';
  public hash = '';

  public emailAddress = '';
  public password1 = '';
  public password2 = '';
  public last4SNN = '';
  public passwordsValid = false;

  public auth = inject(AuthService);
  private _route = inject(ActivatedRoute);
  private _logger = inject(LogNinja);

  constructor() {
    super();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.loading = true;

    this.addSubscriptions(this._route.params.subscribe(params => {
      this.hash = params['hash']; // the reset hash
      if (this.hash == '') {
        this.errorMessageHash = 'No key provided. Cannot proceed.';
        this.validHash = false;
        this.loading = false;
      } else {
        this.validHash = true;
        this.auth.completeRegistration(this.hash).subscribe({
          next: () => {
            this.loading = false;
            this.successMessage = "Registration successful! You may now login with your account";
          },
          error: (error: any) => {
            this.loading = false;
            this.errorMessage = error.statusText;
            return null;
          },
          complete: () => {
            this._logger.log('complete registration finished');
          }
        });
      }
    }));
  }
}
