<div class="container mb-5">
  <ui-alert [alert]="editAlert"></ui-alert>
  <div class="d-flex justify-content-between align-items-center">
    <ui-header [header]="'Buyer Offer Detail'"></ui-header>
    <div>
      <button class="btn btn-primary" (click)="downloadFormPdf()" [disabled]="loadingForm">
        <span *ngIf="!loadingForm">Download Form</span>
        <span *ngIf="loadingForm" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="loadingForm"> Loading</span>
      </button>
    </div>
  </div>
  <hr>




  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Address:</div>
        <div class="">{{ offerModel.address }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Buyer Name:</div>
        <div class="">{{ offerModel.buyerName }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Buyer Phone:</div>
        <div>{{ offerModel.buyerPhone }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Buyer Email:</div>
        <div>{{ offerModel.buyerEmail }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Buyer 2 Name:</div>
        <div>{{ offerModel.buyer2Name }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Buyer 2 Phone:</div>
        <div class="">{{ offerModel.buyer2Phone }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Buyer 2 Email:</div>
        <div>{{ offerModel.buyer2Email }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Asking Price:</div>
        <div>{{ offerModel.askingPrice | currency }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Payment Method:</div>
        <div>{{ getPaymentMethodName(offerModel.paymentMethod) }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Down Payment:</div>
        <div class="">{{ offerModel.downPayment | currency }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Earnest Money:</div>
        <div>{{ offerModel.earnestMoney | currency }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Want to sell home?:</div>
        <div>{{ offerModel.buyerHasHomeToSell ? "Yes" : "No" }}</div>
      </div>
    </div>

    <div class="col-md-3">
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Home to sell address:</div>
        <div class="">{{ offerModel.addressToSell }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Number of days to sell:</div>
        <div>{{ offerModel.noDaysToSell }} days</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Closing date:</div>
        <div>{{ offerModel.closingDate | date}}</div>
      </div>
    </div>

  </div>

  <hr>
  <h3 class="mt-4">Mls Data for Offer</h3>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Style Code:</div>
        <div class="">{{ offerModel.mlsData?.styleCode }}</div>
      </div>
    </div>
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Listing Number:</div>
        <div class="">{{ offerModel.mlsData?.listingNumber }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Owner Name:</div>
        <div class="">{{ offerModel.mlsData?.ownerName }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Owner Name 2:</div>
        <div>{{ offerModel.mlsData?.ownerName2 }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">APN:</div>
        <div>{{ offerModel.mlsData?.apn }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Unit:</div>
        <div>{{ offerModel.mlsData?.unit }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Building Complex or Project Name:</div>
        <div class="">{{ offerModel.mlsData?.buildingComplexOrProjectName }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Parking Space Numbers:</div>
        <div>{{ offerModel.mlsData?.parkingSpaceNumbers }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Storage No:</div>
        <div>{{ offerModel.mlsData?.storageNo }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Address:</div>
        <div class="">{{ offerModel.mlsData?.address }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">City:</div>
        <div>{{ offerModel.mlsData?.city }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">County:</div>
        <div>{{ offerModel.mlsData?.county }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">State:</div>
        <div class="">{{ offerModel.mlsData?.state }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Zip Code:</div>
        <div>{{ offerModel.mlsData?.zipCode }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Appliances Included:</div>
        <div>{{ offerModel.mlsData?.appliancesIncluded }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Current Price:</div>
        <div>{{ offerModel.mlsData?.currentPrice | currency }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Title Company:</div>
        <div class="">{{ offerModel.mlsData?.titleCompany }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Possession:</div>
        <div>{{ offerModel.mlsData?.possession }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Offers Review Date:</div>
        <div>{{ offerModel.mlsData?.offersReviewDateString }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">FIRPTA:</div>
        <div class="">{{ offerModel.mlsData?.firpta ? "Yes" : "No" }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Association Dues:</div>
        <div>{{ offerModel.mlsData?.associationDues | currency }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">BBC:</div>
        <div>{{ offerModel.mlsData?.bbc }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Owner Phone Number:</div>
        <div>{{ offerModel.mlsData?.ownerPhoneNumber }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div>
        <div class=" font-weight-bold">Listing Office Name:</div>
        <div class="">{{ offerModel.mlsData?.listingOfficeName }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Office ID:</div>
        <div>{{ offerModel.mlsData?.listingOfficeId }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Agent Full Name:</div>
        <div>{{ offerModel.mlsData?.listingAgentFullName }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Listing Agent ID:</div>
        <div class="">{{ offerModel.mlsData?.listingAgentId }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Office Phone:</div>
        <div>{{ offerModel.mlsData?.listingOfficePhone }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Listing Agent Cellular:</div>
        <div>{{ offerModel.mlsData?.listingAgentCellular }}</div>
      </div>
    </div>

    <div class="col-md-3">
      <div>
        <div class="font-weight-bold">Water Company:</div>
        <div>{{ offerModel.mlsData?.waterCompany }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-3">
      <div>
        <div class=" font-weight-bold">Power Company:</div>
        <div class="">{{ offerModel.mlsData?.powerCompany }}</div>
      </div>
    </div>
  </div>
</div>
