
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';



@Injectable()
export class MlsDataService {

	constructor(public _http: HttpClient, public _auth: AuthService) { } 
	
	public importMlsCsvData(file: File): Observable<number> {
		let __methodFileWrapper = new FormData();
		__methodFileWrapper.append('file', file);

		return this._http.post<number>(`api/MlsData/importMlsCsvData`, __methodFileWrapper, { headers: { }})
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getAddressSuggestions(i_oSearchOptions: SearchOptions): Observable<string[]> {
		
		return this._http.post<string[]>(`api/MlsData/getAddressSuggestions`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getAskingPrice(i_oSearchOptions: SearchOptions): Observable<number> {
		
		return this._http.post<number>(`api/MlsData/getAskingPrice`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
}

