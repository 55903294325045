import {ChangeDetectorRef, Component, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "@danielmoncada/angular-datetime-picker";

@Component({
  selector: 'step-12-closing-date',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule,OwlDateTimeModule,
    OwlNativeDateTimeModule,],
  templateUrl: './step-12-closing-date.component.html',
  styleUrls: ['step-12-closing-date.component.scss'],
})
export class Step12ClosingDateComponent implements OnInit{

  public closingDate: WritableSignal<Date | null> = signal(null);
  public closingDateString: WritableSignal<string> = signal('');

  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(event: any): void {
    const date = new Date(event.value);
    this.buyerOffer.closingDate = date;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);
    this.setIsValid();
  }

  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.closingDate.set(this.buyerOffer.closingDate || null);
    this.setIsValid();
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.closingDate() != null;
  }
}
