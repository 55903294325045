import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { BuyerOffersService } from '@services/buyer-offers.service'
import {CommonModule} from "@angular/common";
import {
  SearchOptions,
  UIAlertComponent, UIAlertItem, UIComponentBase,
  UIEditViewComponent, UIEditViewItem, UIEditViewItemComponent, UIEditViewModel, UIEditViewValidateResult,
  UIHeaderComponent, UIListViewComponent, UILoadingBarComponent, UIModalComponent,
} from "@buildable/foundation";
import {AuthService} from "@services/auth.service";
import {CacheDataService} from "@services/cache-data.service";
import {
  debounceTime,
  distinctUntilChanged, noop,
  Observable,
  Observer,
  of,
  Subject,
  switchMap,
  tap
} from "rxjs";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {getPaymentMethodName, paymentMethodsList} from "@models/payment-methods";
import {FormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {MlsDataService} from "@services/mls-data.service";
import {SearchFields} from "@models/search-fields";
import {map} from "rxjs/operators";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {getStatusText, getStatusTextClass} from "@app/utils/offer-status-utils";
import {OfferStatus} from "@models/offer-status";
import {FilesService} from "@services/files.service";

@Component({
  selector: 'offer-detail',
  standalone: true,
  imports: [
    CommonModule,
    UIAlertComponent, UIHeaderComponent, UIListViewComponent, UILoadingBarComponent, UIEditViewComponent,
    UIEditViewItemComponent, FormsModule, TypeaheadModule, UIModalComponent, FaIconComponent, RouterLink
  ],
  templateUrl: './offer-detail.component.html',
  providers: [BuyerOffersService, MlsDataService, FilesService]
})
export class OfferDetailComponent extends UIComponentBase implements OnInit, OnDestroy{

  public editAlert: UIAlertItem = new UIAlertItem();
  public editViewModel: UIEditViewModel;
  public editLoading: boolean = false;
  public editSaving: boolean = false;

  public isDownloadingFile: boolean = false;


  // find a reference to the editview component
  @ViewChild(UIEditViewComponent)
  uiEditViewComponent!: UIEditViewComponent;

  @ViewChild('editModal')
  uiModal!: UIModalComponent;

  @ViewChild('approveModal')
  approveModal!: UIModalComponent;

  // Typeahead variables
  public searchTerms = new Subject<string>();
  suggestions$?: Observable<string[]>;
  errorMessage?: string;

  public offer: BuyerOfferModel = new BuyerOfferModel();
  public dataId: number = 0;

  constructor(
    public auth: AuthService,
    private _cacheData: CacheDataService,
    private _data: BuyerOffersService,
    private _mlsDataService: MlsDataService,
    private router: Router,
    private _route: ActivatedRoute,
    private _filesService: FilesService
  ) {
    super();

    //-------------------
    // setup the editview
    this.editViewModel = new UIEditViewModel({
      showInline: false,
      showButtons: false,
      saveButtonText: 'Submit',
      showSave: true,
      editViewItems: [
        new UIEditViewItem({ label: 'Address', field: 'address', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer Name', field: 'buyerName', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer Phone', field: 'buyerPhone', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer Email', field: 'buyerEmail', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer 2 Name', field: 'buyer2Name', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer 2 Phone', field: 'buyer2Phone', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Buyer 2 Email', field: 'buyer2Email', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Asking Price', field: 'askingPrice', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Payment Method', field: 'paymentMethod', type: 'select',
          options: paymentMethodsList, size: 'full' }),
        new UIEditViewItem({ label: 'Down Payment', field: 'downPayment', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Earnest Money', field: 'earnestMoney', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Want to to sell Home?', field: 'buyerHasHomeToSell', type: 'checkbox', size: 'full' }),
        new UIEditViewItem({ label: 'Home to sell address', field: 'addressToSell', type: 'text', size: 'full' }),
        new UIEditViewItem({ label: 'Number of days to sell', field: 'noDaysToSell', type: 'number', size: 'full' }),
        new UIEditViewItem({ label: 'Closing Date', field: 'closingDate', type: 'date', size: 'full' }),
      ]
    });

    // subscribe to events when the model will be ready
    this.addSubscriptions(this.editViewModel.initialize$.subscribe(() => {
      this.addSubscriptions(
        this.editViewModel.save$.subscribe(() => this.onEditSave()),
        this.editViewModel.error$.subscribe((message) => this.editAlert.errorMessage = message),
        this.editViewModel.change$.subscribe((item) => this.onEditChange(item)),
      );
    }));

    // setup the validation handlers
    this.editViewModel.beforeValidateDataCallback = (data: any) => { this.editAlert.reset(); }; // clear the error messages before validating
    this.editViewModel.validateDataCallback = (data: any) => { return this.onValidateData(data); } // hook to the local method (note we must use a lambda function, or 'this' will lose scope inside the function)
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    this._cacheData.loadCacheAll(); // remove if not needed

    // hook up to the route: note this will get re-executed routinely,
    // but the component won't get recreated, so any initialization depending on routes need to be done in the handler below
    this.addSubscriptions(this._route.params.subscribe(params => {
      this.offer = new BuyerOfferModel();
      this.dataId = +params['id']; // (+) converts string 'id' to a number
      this.reload();
    }));

    this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => {
        return this._mlsDataService.getAddressSuggestions(this.createSearchOption(term));
      })
    );

    this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.editViewModel.data.address);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this._mlsDataService.getAddressSuggestions(this.createSearchOption(query)).pipe(
            map((data: string[]) => data || []),
            tap(() => noop, err => {
              // in case of http error
              this.errorMessage = err && err.statusText || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
  }


  private createSearchOption(address: string): SearchOptions {
    return new SearchOptions({
      fields: new SearchFields({
        address: address
      })
    });
  }

  //////////////////////////////////////////////////////////////////////
  // editview portion
  approveOffer() {
    this.closeApproveModal();
    this.editLoading = true;

    this._data.approveOfferById(this.dataId).subscribe({
      next: () => {
        this.editAlert.success("Successfully approved offer.");
        this.editLoading = false;
        this.reload();
      },
      error: err => {
        this.editAlert.error('Unable to approve offer.', err.statusText);
        this.editLoading = false;
      }
    })
  }


  reload() {
    this.editLoading = true;

    this._data.getItemById(this.dataId).subscribe({
      next: offer => {
        this.editViewModel.data = offer;
        Object.assign(this.offer, offer);
        this.editLoading = false
      },
      error: err => {
        this.editAlert.error('Unable to get offer.', err.statusText);
        this.editLoading = false;
      }
    })
  }

  getAddressSuggestions(address: string) {
    this.searchTerms.next(address);
  }


  onEditChange(item: UIEditViewItemComponent) {
    // TODO: react to a field change, if needed
  }

  onValidateData(data: any): UIEditViewValidateResult {
    let offer = <BuyerOfferModel>data;
    let result = new UIEditViewValidateResult();

    return result;
  }

  onEditSave() {
    this.editAlert.reset();

    // must validate manually, since button is hosted on dialog modal, and not edit view.
    let result = this.uiEditViewComponent.validateData(); // this raises the error$ event
    if (!result.isValid) {
      return;
    }

    this.editSaving = true;

    this._data.put(this.dataId, this.editViewModel.data).subscribe({
      next: (item: BuyerOfferModel) => {
        this.editSaving = false;
        this.editAlert.success('Successfully saved offer data.');
        this.closeEditModal();
        this.reload();
      },
      error: (err) => {
        this.editSaving = false;
        let verb = this.dataId == 0 ? "insert" : "update";
        this.editAlert.errorMessage = `Failed to ${verb} item. Reason: ${err.statusText}`;
      }
    });
  }

  openEditModal() {
    this.uiModal.show();
  }

  closeEditModal() {
    this.uiModal.hide();
  }

  openApproveModal() {
    this.approveModal.show();
  }

  closeApproveModal() {
    this.approveModal.hide();
  }

  downloadFile(fileKey: string) {
    this.isDownloadingFile = true;

    this._filesService.downloadFile(fileKey).subscribe({
      next: (blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileKey;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.isDownloadingFile = false;
      },
      error: (error) => {
        console.log(error)
        this.editAlert.error("Error downloading file.", error.statusText);
        this.isDownloadingFile = false;
      }
    });
  }

  protected readonly getPaymentMethodName = getPaymentMethodName;
  protected readonly getStatusText = getStatusText;
  protected readonly OfferStatus = OfferStatus;
  protected readonly getStatusTextClass = getStatusTextClass;
}
