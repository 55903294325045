import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterState, ActivatedRoute } from '@angular/router';
import { LogNinja } from '@services/log-ninja.service';

// https://www.thecodecampus.de/blog/angular-universal-handle-404-set-status-codes/
@Component({
  selector: 'not-found',
  standalone: true,
  templateUrl: './not-found.component.html',
  providers: []
})
export class NotFoundComponent {
  private _router = inject(Router);
  private _logger = inject(LogNinja);

  constructor() {
    const state: RouterState = this._router.routerState;
    const route: ActivatedRoute = state.root;

    this._logger.debug(route.snapshot);
    this._logger.debug(state.snapshot);
  }
}
