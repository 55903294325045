import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, distinctUntilChanged, noop, Observable, Observer, of, Subject, switchMap, tap} from "rxjs";
import {map} from "rxjs/operators";
import {WizardOfferService} from "@services/wizard-offer.service";
import {MlsDataService} from "@services/mls-data.service";
import {SearchOptions} from "@buildable/foundation";
import {SearchFields} from "@models/search-fields";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {TransitionComponent} from "@components/_generics/transition.component";
import {BuyerOfferModel} from "@models/buyer-offer-model";

@Component({
  selector: 'step-2-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule],
  templateUrl: './step-2-address.component.html',
  styleUrls: ['step-2-address.component.scss'],
  providers: [MlsDataService]
})
export class Step2AddressComponent implements OnInit {
  private _mlsDataService = inject(MlsDataService);

  public address: string = "";
  // Typeahead variables
  public addressSuggestions: string[] = [];
  public typeaheadLoading: boolean = false;
  public typeaheadNoResults: boolean = false;
  public searchTerms = new Subject<string>();
  suggestions$?: Observable<string[]>;
  errorMessage?: string;

  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(): void {
    this.buyerOffer.address = this.address;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);

    this.setIsValid();
  }


  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.address = this.buyerOffer.address || "";

    this.setIsValid();

    this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => {
        return this._mlsDataService.getAddressSuggestions(this.createSearchOption(term));
      })
    );

    this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.address);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this._mlsDataService.getAddressSuggestions(this.createSearchOption(query)).pipe(
            map((data: string[]) => data || []),
            tap(() => noop, err => {
              // in case of http error
              this.errorMessage = err && err.statusText || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
  }

  getAddressSuggestions(address: string) {
    this.searchTerms.next(address);
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.address.trim().length > 0;
  }

  private createSearchOption(address: string): SearchOptions {
    return new SearchOptions({
      fields: new SearchFields({
        address: address
      })
    });
  }
}
