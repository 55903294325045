import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";

@Component({
  selector: 'step-8-earnest-money',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule],
  templateUrl: './step-8-earnest-money.component.html',
  styleUrls: ['step-8-earnest-money.component.scss']
})
export class Step8EarnestMoneyComponent implements OnInit{
  public earnestMoney: number | null = null;

  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(): void {
    this.buyerOffer.earnestMoney = this.earnestMoney;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);
    this.setIsValid();
  }

  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.earnestMoney = this.buyerOffer.earnestMoney || null;
    this.setIsValid();
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.earnestMoney != null && this.earnestMoney > 0;
  }
}
