import { Injectable } from '@angular/core';
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {AuthService} from "@services/auth.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WizardOfferService {
  private buyerOffer: BuyerOfferModel;
  private buyerOfferTemp: BuyerOfferModel;
  private readonly localStorageKey: string;

  private isValidSubject = new BehaviorSubject<boolean>(false);
  isValid$ = this.isValidSubject.asObservable();

  constructor(private authService: AuthService) {
    this.localStorageKey = `buyerOffer_${this.authService.getLocalUserId()}`;
    this.isValidSubject.next(false);
    this.buyerOffer = this.loadBuyerOfferFromStorage() || new BuyerOfferModel();
    this.buyerOfferTemp = this.loadBuyerOfferFromStorage() || new BuyerOfferModel();
  }

  getBuyerOffer(): BuyerOfferModel {
    return this.buyerOffer;
  }

  setBuyerOffer(buyerOffer: BuyerOfferModel): void {
    this.buyerOffer = buyerOffer;
    this.saveBuyerOfferToStorage();
  }

  saveBuyerOffer(): void {
    Object.assign(this.buyerOffer, this.buyerOfferTemp);
    this.saveBuyerOfferToStorage();
  }
  setBuyerOfferTemp(buyerOffer: BuyerOfferModel): void {
    this.buyerOfferTemp = buyerOffer;
  }

  getIsValid(): boolean {
    return this.isValidSubject.value;
  }

  setIsValid(value: boolean): void {
    this.isValidSubject.next(value);
  }

  private loadBuyerOfferFromStorage(): BuyerOfferModel | null {
    const data = localStorage.getItem(this.localStorageKey);
    return data ? JSON.parse(data) as BuyerOfferModel : null;
  }

  private saveBuyerOfferToStorage(): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.buyerOffer));
  }

  public saveStepToStorage(step: number): void {
    localStorage.setItem(`${this.localStorageKey}_step`, step.toString());
  }

  public getSavedStep(): number {
    return parseInt(localStorage.getItem(`${this.localStorageKey}_step`) || '0', 10);
  }
}
