<div class="bg-login">
    <div class="row mx-0">
        <div class="col-sm-5 mx-sm-auto py-5">
			<div class="card mb-0" style="border: 0;">
				<div class="modal-header card-header">
					<h4 class="card-title">
						New User Verification
					</h4>
				</div>
				<div class="card-body">
					<p *ngIf="loading" class="loading">Please wait while we check your validation key...</p>
					<div *ngIf="errorMessageHash">
						<div class="alert alert-danger mb-4">
							{{ errorMessageHash }}
						</div>
					</div>
					<div *ngIf="validHash">
						<div *ngIf="errorMessage" class="alert alert-danger">
							{{ errorMessage }}
						</div>
						<div *ngIf="successMessage" class="alert">
							{{ successMessage }}
              <hr>
              <div class="mt-3 text-center">
                <a class=" small" [routerLink]="['/login']">
                  Go to Log In
                </a>
              </div>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>
