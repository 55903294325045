<div class="container">
  <h1>Design System</h1>
  <hr />
  <div class="row">
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-header">Body</div>
        <div class="card-body">
          <p>Pack my red box with five dozen quality jugs</p>
          <p><em>Fix problem quickly with galvanized jets</em></p>
          <p>
            <a href="javascript:void(0)"
              >Quick fox jumps nightly above wizard</a
            >
          </p>
          <ul>
            <li>Grumpy wizards</li>
            <li>make a toxic brew</li>
            <li>for the jovial queen</li>
          </ul>
          <p>
            <strong
              >The wizard quickly jinxed the gnomes before they
              vaporized</strong
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-header">Modal</div>
        <div class="card-body">
          <div
            class="modal"
            tabindex="-1"
            role="dialog"
            style="position: unset; display: block"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Modal title</h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Modal body text goes here.</p>
                </div>
                <div class="modal-footer">
                  <!-- save changes buttons should always use btn-success -->
                  <button type="button" class="btn btn-success">
                    Save changes
                  </button>
                  <button
                    type="button"
                    class="btn btn-link"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-header">Card</div>
        <div class="card-body">
          <div class="card">
            <img
              src="/resources/ProjectCompany-ds-reader.jpg"
              class="card-img-top"
            />
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <a href="javascript:void(0)" class="btn btn-primary"
                >Go somewhere</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-4 mb-4">
      <div class="card">
        <div class="card-header">Typography</div>
        <div class="card-body">
          <h1>Header1</h1>
          <p>Lorem ipsum dolores est.</p>
          <h2>Header2</h2>
          <p>Lorem ipsum dolores est.</p>
          <h3>Header3</h3>
          <p>Lorem ipsum dolores est.</p>
          <h4>Header4</h4>
          <p>Lorem ipsum dolores est.</p>
          <h5>Header5</h5>
          <p>Lorem ipsum dolores est.</p>
          <h6>Header6</h6>
          <p>Lorem ipsum dolores est.</p>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4">
      <div class="card">
        <div class="card-header">Buttons</div>
        <div class="card-body">
          <a class="btn btn-primary btn-block" href="javascript:void(0)"
            >Primary</a
          >
          <a class="btn btn-secondary btn-block" href="javascript:void(0)"
            >Secondary</a
          >
          <a class="btn btn-success btn-block" href="javascript:void(0)"
            >Success</a
          >
          <a class="btn btn-info btn-block" href="javascript:void(0)">Info</a>
          <a class="btn btn-warning btn-block" href="javascript:void(0)"
            >Warning</a
          >
          <a class="btn btn-danger btn-block" href="javascript:void(0)"
            >Danger</a
          >
          <a class="btn btn-dark btn-block" href="javascript:void(0)">Dark</a>
          <a class="btn btn-light btn-block" href="javascript:void(0)">Light</a>
          <a class="btn btn-link btn-block" href="javascript:void(0)">Link</a>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4">
      <div class="card">
        <div class="card-header">Alerts</div>
        <div class="card-body">
          <div class="alert alert-primary" role="alert">
            <h4 class="alert-heading">Well done!</h4>
            <p>
              Aww yeah, you successfully read this important alert message. This
              example text is going to run a bit longer so that you can see how
              spacing within an alert works with this kind of content.
            </p>
            <hr />
            <p class="mb-0">
              Whenever you need to, be sure to use margin utilities to keep
              things nice and tidy.
            </p>
          </div>
          <div class="alert alert-secondary">Lorem ipsum dolores est.</div>
          <div class="alert alert-success">Lorem ipsum dolores est.</div>
          <div class="alert alert-info">Lorem ipsum dolores est.</div>
          <div class="alert alert-warning">Lorem ipsum dolores est.</div>
          <div class="alert alert-danger">Lorem ipsum dolores est.</div>
          <div class="alert alert-dark">Lorem ipsum dolores est.</div>
          <div class="alert alert-light">Lorem ipsum dolores est.</div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-header">Form</div>
        <div class="card-body">
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="inputEmail4"
                  placeholder="Email"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="inputPassword4"
                  placeholder="Password"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="inputAddress">Address</label>
              <input
                type="text"
                class="form-control"
                id="inputAddress"
                placeholder="1234 Main St"
              />
            </div>
            <div class="form-group">
              <label for="inputAddress2">Address 2</label>
              <input
                type="text"
                class="form-control"
                id="inputAddress2"
                placeholder="Apartment, studio, or floor"
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputCity">City</label>
                <input type="text" class="form-control" id="inputCity" />
              </div>
              <div class="form-group col-md-4">
                <label for="inputState">State</label>
                <select id="inputState" class="form-control">
                  <option selected>Choose...</option>
                  <option>...</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="inputZip">ZIP</label>
                <input type="text" class="form-control" id="inputZip" />
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label class="form-check-label" for="gridCheck">
                  Check me out
                </label>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              href="javascript:void(0)"
            >
              Sign in<fa-icon
                [icon]="['fas', 'sign-in']"
                class="ml-2"
              ></fa-icon>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-header">Radio &amp; Checkbox</div>
        <div class="card-body">
          <form>
            <fieldset class="form-group">
              <div class="row">
                <legend class="col-form-label col-3 pt-0">Radios</legend>
                <div class="col-9">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="gridRadios"
                      id="gridRadios1"
                      value="option1"
                      checked
                    />
                    <label class="form-check-label" for="gridRadios1">
                      First radio
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="gridRadios"
                      id="gridRadios2"
                      value="option2"
                    />
                    <label class="form-check-label" for="gridRadios2">
                      Second radio
                    </label>
                  </div>
                  <div class="form-check disabled">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="gridRadios"
                      id="gridRadios3"
                      value="option3"
                      disabled
                    />
                    <label class="form-check-label" for="gridRadios3">
                      Third disabled radio
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="form-group row">
              <div class="col-3">Checkboxes</div>
              <div class="col-9">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="gridCheck1"
                  />
                  <label class="form-check-label" for="gridCheck1">
                    First checkbox
                  </label>
                </div>
                <div class="form-check disabled">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="gridCheck2"
                  />
                  <label class="form-check-label" for="gridCheck2">
                    Second checkbox
                  </label>
                </div>
                <div class="form-check disabled">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="gridCheck3"
                    disabled
                  />
                  <label class="form-check-label" for="gridCheck2">
                    Third disabled checkbox
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="card">
        <div class="card-header table-header">Table</div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table-hover table-striped table">
              <caption>
                List of users
              </caption>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Louella</td>
                  <td>Aitken</td>
                  <td>laitken0&#64;hubpages.com</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Heindrick</td>
                  <td>Domini</td>
                  <td>hdomini1&#64;vk.com</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Kennith</td>
                  <td>Halsey</td>
                  <td>khalsey2&#64;irs.gov</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>Mareah</td>
                  <td>Gladdis</td>
                  <td>mgladdis3&#64;ameblo.jp</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>Antone</td>
                  <td>Rembrant</td>
                  <td>arembrant4&#64;rediff.com</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>Codie</td>
                  <td>Pinnijar</td>
                  <td>cpinnijar5&#64;cyberchimps.com</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td>Saul</td>
                  <td>Maris</td>
                  <td>smaris6&#64;constantcontact.com</td>
                </tr>
                <tr>
                  <th scope="row">8</th>
                  <td>Erny</td>
                  <td>Chiverton</td>
                  <td>echiverton7&#64;slate.com</td>
                </tr>
                <tr>
                  <th scope="row">9</th>
                  <td>Ruben</td>
                  <td>Newcombe</td>
                  <td>rnewcombe8&#64;rambler.ru</td>
                </tr>
                <tr>
                  <th scope="row">10</th>
                  <td>Valeria</td>
                  <td>Croxford</td>
                  <td>vcroxford9&#64;g.co</td>
                </tr>
              </tbody>
              <!--<tfoot>
						TODO
					</tfoot>-->
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
