import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  TemplateRef,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  HostBinding,
} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  query,
  group,
  AnimationEvent,
} from '@angular/animations';
import { CommonModule } from '@angular/common';

interface TemplateWithCondition {
  template: TemplateRef<any>;
  condition: boolean;
}

const defaultAnimationDurationMs = 400;

@Component({
  selector: 'app-transition',
  templateUrl: './transition.component.html',
  styleUrls: ['./transition.component.scss'],
  imports: [CommonModule],
  animations: [
    trigger('componentTransition', [
      transition(':increment', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
          style({
            position: 'absolute',
            width: '100%',
            transform: 'translate3d(0, 0, 0)',
          }),
        ]),
        group([
          query(':leave', [
            animate(
              `${defaultAnimationDurationMs}ms ease-in-out`,
              style({ transform: 'translate3d(-100%, 0, 0)' })
            ),
          ]),
          query(':enter', [
            style({ transform: 'translate3d(100%, 0, 0)' }),
            animate(
              `${defaultAnimationDurationMs}ms ease-in-out`,
              style({ transform: 'translate3d(0, 0, 0)' })
            ),
          ]),
        ]),
      ]),
      transition(':decrement', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
          style({
            position: 'absolute',
            width: '100%',
            transform: 'translate3d(0, 0, 0)',
          }),
        ]),
        group([
          query(':leave', [
            animate(
              `${defaultAnimationDurationMs}ms ease-in-out`,
              style({ transform: 'translate3d(100%, 0, 0)' })
            ),
          ]),
          query(':enter', [
            style({ transform: 'translate3d(-100%, 0, 0)' }),
            animate(
              `${defaultAnimationDurationMs}ms ease-in-out`,
              style({ transform: 'translate3d(0, 0, 0)' })
            ),
          ]),
        ]),
      ]),
    ]),
  ],
  standalone: true,
})
export class TransitionComponent implements AfterContentInit, OnChanges {
  @ContentChildren(TemplateRef)
  templates!: QueryList<TemplateRef<any>>;

  @Input()  currentIndex = 0;
  @Input()  templateConditions: boolean[] = [];
  @Output() lastItem = new EventEmitter<void>();
  @Output() indexChange = new EventEmitter<number>();

  templatesArray: TemplateWithCondition[] = [];


  isTransitioning = false;

  // Add the queuedAction property
  queuedAction: 'next' | 'previous' | null = null;

  // Bind containerWidth to the host element's style.width.px
  @HostBinding('style.width.px') containerWidth: number | null = null;

  constructor(private cdr: ChangeDetectorRef, private hostElement: ElementRef) {}

  ngAfterContentInit() {
    this.updateTemplatesArray();
    this.indexChange.emit(this.currentIndex);
    if (this.currentIndex === this.templatesArray.length - 1) {
      this.lastItem.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['templateConditions']) {
      this.updateTemplatesArray();
    }
  }

  private updateTemplatesArray() {
    if (!this.templates) return;

    this.templatesArray = this.templates.toArray().map((template, index) => ({
      template,
      condition: this.templateConditions[index] ?? true,
    }));
    this.cdr.detectChanges(); // Refresh view
  }

  get currentTemplate() {
    const availableTemplates = this.templatesArray.filter((t) => t.condition);
    return availableTemplates[this.currentIndex]?.template || null;
  }

  get progressPercentage(): number {
    const availableCount = this.templatesArray.filter((t) => t.condition).length;
    return ((this.currentIndex + 1) / availableCount) * 100;
  }

  // Listen to animation start and end events
  onAnimationStart(event: AnimationEvent) {
    this.isTransitioning = true;
  }

  onAnimationDone(event: AnimationEvent) {
    this.isTransitioning = false;

    // Reset containerWidth to allow it to adjust to new content
    this.containerWidth = null;

    // Execute queued action if any
    if (this.queuedAction) {
      const action = this.queuedAction;
      this.queuedAction = null; // Clear the queued action

      // Use setTimeout to ensure the view updates before the next transition
      setTimeout(() => {
        if (action === 'next') {
          this.next();
        } else if (action === 'previous') {
          this.previous();
        }
      });
    }
  }

  public next() {
    if (this.isTransitioning) {
      this.queuedAction = 'next';
      return;
    }
    this.setContainerWidth(); // Freeze width before transition
    this.moveNext();
  }

  private moveNext() {
    const availableTemplates = this.templatesArray.filter((t) => t.condition);
    if (this.currentIndex < availableTemplates.length - 2) {
      this.currentIndex++;
      this.indexChange.emit(this.currentIndex);
    } else if (this.currentIndex < availableTemplates.length - 1) {
      this.currentIndex++;
      this.lastItem.emit();
      this.indexChange.emit(this.currentIndex);
    }
  }

  public previous() {
    if (this.isTransitioning) {
      this.queuedAction = 'previous';
      return;
    }
    this.setContainerWidth(); // Freeze width before transition
    this.movePrevious();
  }

  private movePrevious() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.indexChange.emit(this.currentIndex);
    }
  }

  private setContainerWidth() {
    if (this.hostElement && this.hostElement.nativeElement) {
      this.containerWidth = this.hostElement.nativeElement.offsetWidth;
    }
  }
}
