import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpResponseBase } from '@angular/common/http';

import { LogNinja } from './log-ninja.service';

@Injectable()
export class FileService {
  private _logger = inject(LogNinja);

  constructor() { }

  // see: https://angular.io/api/common/http/HttpResponse
  // ArrayBuffer will be the type of BODY
  downloadResponse(response: HttpResponse<ArrayBuffer>, fileName?: string): void {
    // safest method for downloading a file. Response should be coming in directly from the API
    var contentType = response.headers.get("content-type") ?? undefined;

    if (!fileName) {
      fileName = this.getFilenameFromResponse(response); // handles double quotes strings
    }

    var blob = new Blob([response.body!], { type: contentType });
    let file = new File([blob], fileName, { type: contentType, lastModified: Date.now() });

    // easiest method (we can always use FileSaver.saveAs(blob, filename) if this method fails)
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = fileName;
    link.click();
  }

  public getFilenameFromResponse(response: HttpResponseBase): string {
    if (response && response.headers) {
      let contentHeader = response.headers.get('content-disposition');
      if (!contentHeader) return '';

      // Tokenized, expect something like: >> attachment; filename=crm_accounts.csv; filename*=UTF-8''crm_accounts.csv <<
      // Tokenized, expect something like: >> attachment; filename="My Quoted FileName.csv"; filename*=UTF-8''crm_accounts.csv <<
      let tokens: string[] = contentHeader.split(';');
      if (tokens.length < 2) {
        this._logger.warn('getFilename(): Unrecognized header for content-disposition. Unable to locate filename');
        return '';
      }

      // Make sure type is attachment
      if (tokens[0].indexOf("attachment") < 0 && tokens[0].indexOf("inline") < 0) {
        this._logger.warn('getFilename(): Attempt to get filename from header that does not have attachment');
        return '';
      }

      // looking for "filename="
      let searchStr = "filename=";
      let fileStr = tokens[1];
      let loc = fileStr.indexOf(searchStr);
      if (loc >= 0) {
        let filename = fileStr.substr(loc + searchStr.length);
        filename = filename.replace(/^"(.*)"$/, '$1'); // remove surrounding quotes.
        return filename;
      }
    }
    return '';
  }
}
