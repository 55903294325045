import { Component, inject, ViewEncapsulation } from '@angular/core';
import { AuthService } from './services/auth.service';
import { SessionService } from './services/session.service';
import { LogNinja } from '@services/log-ninja.service';
import { RouterOutlet } from '@angular/router';
import { NavMenuComponent } from '@components/nav/navmenu.component';
import { NavFooterComponent } from '@components/nav/nav-footer.component';
import { CommonModule } from '@angular/common';
import { CacheService } from '@services/cache.service';
import { CacheDataService } from '@services/cache-data.service';
import { FileService } from '@services/file.service';
import { CookieService } from 'ngx-cookie-service';
import { LoadingService } from '@services/loading.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PageTitleService } from '@services/page-title.service';
import {StringHelperService} from "@buildable/foundation";

// declare the Index.chsml authentication check
declare var _MyProductVersion: boolean;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, CommonModule,
    NavMenuComponent, NavFooterComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    // Add new root providers here
    AuthService,
    CacheService,
    CacheDataService,
    SessionService,
    FileService,
    CookieService,
    LoadingService,
    DeviceDetectorService,
    LogNinja,
    StringHelperService,
    PageTitleService,
  ],
  encapsulation: ViewEncapsulation.None   // makes this CSS global
})
export class AppComponent {
  public loggedIn = false;
  public collapse = false;
  public version = _MyProductVersion;

  public auth = inject(AuthService);
  private _session = inject(SessionService);
  private _logger = inject(LogNinja);
  private _pageTitle = inject(PageTitleService);

  constructor() {
    this._session.isUserLoggedIn$.subscribe(value => {
      this.loggedIn = value;
    });
  }

  public toggleCollapse() {
    this.collapse = !this.collapse;
    this._logger.log('app: collapse = ' + this.collapse);
  }

  public getSidebarClass() {
    if (this.collapse) {
      return 'side-collapsed sidebar d-none d-md-block bg-light';
    } else {
      return 'sidebar col-md-2 d-none d-md-block bg-light';
    }
  }

  public getMainbarClass() {
    if (this.loggedIn) {
      if (this.collapse) {
        return 'main main-collapsed ml-sm-auto';
      } else {
        return 'main main-normal';
      }
    } else {
      return 'main';
    }
  }
}
