<div class="full-height row">
  <div class="col-md-11 d-flex flex-column justify-content-center">
    <h1 class="h1-responsive fw-bold">What will your Earnest Money be?</h1>

    <div class="row">
      <div class="col-md-7 mt-4">
        <div class="wizard-input-currency mb-4">
          <span class="input-group-text">$</span>
          <input type="number"
            [(ngModel)]="earnestMoney"
            id="earnest-money"
            min="1"
            placeholder="Enter dollar amount"
            (ngModelChange)="updateBuyerOffer()"
          />
        </div>
        <span class="subheader">
          This is a deposit that goes towards your down payment and the
          price of the home. We recommend 3%  of the purchase price!
        </span>
      </div>
    </div>
  </div>
</div>
