
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

export enum SearchFilterCompareTypeEnum {
    // Enumeration values
	String = 1,
	Number = 2,
	DateTime = 3,
	TableEnum = 4,
	Boolean = 5,
	Enum = 8,

}







