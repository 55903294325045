<div class="full-height row">
  <div class="col-md-6 d-flex flex-column justify-content-center">
      <h1 class="h1-responsive fw-bold">Do you have a home to sell?</h1>
      <div class="mt-4">
        <fieldset class="payment-method-options">
          <label class="payment-box" [ngClass]="{'active': buyerHasHomeToSell == true}">
            <input type="radio"
                   [(ngModel)]="buyerHasHomeToSell"
                   name="paymentMethod"
                   [value]="true"
                   (ngModelChange)="updateBuyerOffer()"
            >
            <span>Yes</span>
          </label>
          <label class="payment-box" [ngClass]="{'active': buyerHasHomeToSell == false}">
            <input type="radio"
                   [(ngModel)]="buyerHasHomeToSell"
                   name="paymentMethod"
                   [value]="false"
                   (ngModelChange)="updateBuyerOffer()"
            >
            <span>No</span>
          </label>
        </fieldset>
    </div>
  </div>

</div>
