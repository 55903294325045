
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


import { Pager, IPager } from '@buildable/foundation'; 
// add imports
import { SystemUserModel, ISystemUserModel } from './system-user-model'; 
import { SystemLogType } from './system-log-type'; 
import { SystemLogSource } from './system-log-source'; 



export interface ISystemLogModel {
	// Inherited properties
	selected?: boolean | null;
	editing?: boolean | null;
	pager?: IPager | null;

	// Class properties
	systemUser?: ISystemUserModel | null;
	systemLogId?: number | null;
	systemUserId?: number | null;
	type?: SystemLogType | null;
	source?: SystemLogSource | null;
	dateAdded?: Date | null;
	threadId?: number | null;
	sourceFileName?: string | null;
	memberName?: string | null;
	description?: string | null;
	systemUserName?: string | null;
	normalUserId?: number | null;
	normalUserName?: string | null;
	ipAddress?: string | null;
	descriptionShort?: string | null;

}



export class SystemLogModel implements ISystemLogModel  {
	constructor(init?: Partial<ISystemLogModel>) { // allows us to assign this object C# style
		Object.assign(this, init);
		// Date initializers
		if (init?.dateAdded) this.dateAdded = new Date(init.dateAdded);

	}

	
	// Inherited properties
	selected?: boolean | null = false;
	editing?: boolean | null = false;
	pager?: Pager | null = null;

	// Class properties
	systemUser?: SystemUserModel | null = null;
	systemLogId?: number | null = 0;
	systemUserId?: number | null = null;
	type?: SystemLogType | null = null;
	source?: SystemLogSource | null = null;
	dateAdded?: Date | null = null;
	threadId?: number | null = null;
	sourceFileName?: string | null = null;
	memberName?: string | null = null;
	description?: string | null = null;
	systemUserName?: string | null = null;
	normalUserId?: number | null = null;
	normalUserName?: string | null = null;
	ipAddress?: string | null = null;
	descriptionShort?: string | null = null;

	
}



