
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


import { Pager, IPager } from '@buildable/foundation'; 



export interface ISystemUserModel {
	// Inherited properties
	selected?: boolean | null;
	editing?: boolean | null;
	pager?: IPager | null;

	// Class properties
	systemUserId?: number | null;
	isActive?: boolean | null;
	isDeleted?: boolean | null;
	emailAddress?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	dateAdded?: Date | null;
	dateModified?: Date | null;
	datePasswordResetHashExpire?: Date | null;
	dateLastLogin?: Date | null;
	datePasswordReminded?: Date | null;
	password?: string | null;
	passwordResetHash?: string | null;
	name?: string | null;

}



export class SystemUserModel implements ISystemUserModel  {
	constructor(init?: Partial<ISystemUserModel>) { // allows us to assign this object C# style
		Object.assign(this, init);
		// Date initializers
		if (init?.dateAdded) this.dateAdded = new Date(init.dateAdded);
		if (init?.dateModified) this.dateModified = new Date(init.dateModified);
		if (init?.datePasswordResetHashExpire) this.datePasswordResetHashExpire = new Date(init.datePasswordResetHashExpire);
		if (init?.dateLastLogin) this.dateLastLogin = new Date(init.dateLastLogin);
		if (init?.datePasswordReminded) this.datePasswordReminded = new Date(init.datePasswordReminded);

	}

	
	// Inherited properties
	selected?: boolean | null = false;
	editing?: boolean | null = false;
	pager?: Pager | null = null;

	// Class properties
	systemUserId?: number | null = 0;
	isActive?: boolean | null = true;
	isDeleted?: boolean | null = null;
	emailAddress?: string | null = null;
	firstName?: string | null = null;
	lastName?: string | null = null;
	dateAdded?: Date | null = null;
	dateModified?: Date | null = null;
	datePasswordResetHashExpire?: Date | null = null;
	dateLastLogin?: Date | null = null;
	datePasswordReminded?: Date | null = null;
	password?: string | null = null;
	passwordResetHash?: string | null = null;
	name?: string | null = null;

	
}



