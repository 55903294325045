<div class="full-height row">
  <div class="col-md-6 d-flex flex-column justify-content-center">
    <h1 class="h1-responsive fw-bold">Down Payment</h1>
    <p class="mt-3 subheader">If you are financing, this is the amount of cash you will pay at closing (the bank loans
      you the rest)! A standard down payment is usually 10%-20%. Don’t worry if you
      don’t have 10%, your lender will go over all specifics with you!</p>
    <div class="mt-4">
      <div class="wizard-input-currency">
        <span class="input-group-text">$</span>
        <input type="number"
               [(ngModel)]="downPayment"
               id="down-payment"
               min="1"
               placeholder="Enter dollar amount"
               (ngModelChange)="updateBuyerOffer()"
        >
      </div>
    </div>
  </div>
</div>
