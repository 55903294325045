<div class="container">
  <div class="card p-lg-5 p-4 mb-4">
    <div *ngIf="!showUploader" class="d-flex flex-column justify-content-center">
      <h1 class="h1-responsive fw-bold">Welcome to Homewrite!</h1>
      <p class="mt-3 subheader">Let’s get started: </p>
      <ol class="mt-4 step-list ">
        <li class="subheader font-weight-normal">Step 1: Get a pre-approval letter and upload it to Homewrite.com</li>
        <li class="subheader font-weight-normal">Step 2: Go find the home for you</li>
        <li class="subheader font-weight-normal">Step 3: Complete our questionnaire when you found the home you want!</li>
      </ol>
      <div class="d-flex justify-content-start mt-lg-4 pt-4">
        <button class="btn btn-primary mx-2 w-lg-25 rounded-pill px-4" style="height: 60px;" (click)="goUploadPreApproval()">
          Upload Pre-Approval
        </button>
      </div>
    </div>

    <div class="row d-flex flex-column justify-content-center" *ngIf="showUploader">

      <div class="col-md-8">
        <h1 class="h1-responsive fw-bold">Upload Pre-approval</h1>
        <ui-alert [alert]="alert"></ui-alert>
        <p class="mt-3 subheader">
          We recommend (okay okay, we’re being polite: require) getting pre-approved prior to
          submitting an offer on a home. If you have a pre-approval letter please upload it here. If
          you haven’t been pre-approved and need help, call Nico and he’ll help you get started!
        </p>


        <input
          type="file"
          #fileInput
          accept=".pdf"
          (change)="onFileSelected($event)"
          class="d-none"
        />

        <button
          class="btn btn-primary mx-2 w-lg-25 rounded-pill mt-5 px-4"
          style="height: 50px;"
          (click)="fileInput.click()"
          [disabled]="isLoading"
        >
          <fa-icon *ngIf="!isLoading" [icon]="['fas', 'upload']" class="mr-2"></fa-icon>
          <span *ngIf="!isLoading">Upload</span>
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="isLoading"> Loading</span>
        </button>
      </div>
    </div>
  </div>
</div>
