<div class="container">
	<h2>MLS Data Import</h2>

  <div class="mt-4">
    <ui-alert [alert]="listAlert"></ui-alert>
    <div class="row">
      <div class="custom-file col-4 mr-4">
        <input
          #csvFileInput
          type="file"
          class="custom-file-input"
          id="csvFileInput"
          (change)="onFileSelected($event)"
          accept="text/csv"
        />
        <label class="custom-file-label" for="csvFileInput">Choose file</label>
      </div>
      <button class="btn btn-primary" (click)="importCsvData()">
        Import CSV
      </button>
    </div>
  </div>

</div>
