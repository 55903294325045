<!-- login form -->
<ng-container *ngIf="!loggedIn()">
  <div
    class="card py-3 mt-3 px-0"
    style="width: 90%; max-width: 390px; margin: 0 auto"
    [attr.forceRenderKey]="forceRenderKey()"
  >
    <div class="card-body pt-0 pb-0 px-4">
      <h2 class="sr-only">Login Form</h2>
      <!-- <h4 class="mb-4 fw-bold">Welcome to Homewrite</h4> -->
      <div class="login-logo mb-2"></div>

      <!-- on mobile, offer the login to the app -->
      <div class="form-group text-left">
        <label for="loginEmail"><span class="sr-only">Login </span>Email</label>
        <input
          id="loginEmail"
          class="form-control"
          type="email"
          [(ngModel)]="userLogin"
          (keyup.enter)="login()"
        />
      </div>
      <div class="form-group text-left">
        <label for="loginPw">Password</label>
        <input
          id="loginPw"
          class="form-control"
          type="password"
          [(ngModel)]="userPassword"
          (keyup.enter)="login()"
        />
        <a
          class="small"
          data-toggle="modal"
          data-target="#dialogForgotPassword"
          (click)="resetPasswordInit()"
        >
          Forgot your password?
        </a>
      </div>

      <div class="form-group">
        <button
          class="btn btn-block btn-primary"
          [loading]="loggingIn()"
          (click)="login()"
        >
          <span>Log in</span>
          <fa-icon [icon]="['fas', 'sign-in']" class="ml-2"></fa-icon>
        </button>
      </div>

      <div *ngIf="!loggingIn() && errorMessage()" class="alert alert-danger">
        {{ errorMessage() }}
      </div>

    </div>

    <hr class="mb-0"/>
    <div class="mt-3 text-center">
      <small class="mb-1 mr-1">Don't have an account?</small>
      <a class="small" [routerLink]="['/register']">Sign Up</a>
    </div>
  </div>

  <!-- forgot password modal -->
  <div
    class="modal fade"
    id="dialogForgotPassword"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialogForgotPassword"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Reset Password</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>No worries. We'll send you a link to reset your password.</p>
          <p>What's your email?</p>
          <p class="form-group">
            <input
              type="email"
              class="form-control"
              [(ngModel)]="resetPasswordEmail"
              required
              placeholder="you@example.com"
            />
          </p>
          <div *ngIf="resetPasswordLoading()" class="loading">
            Please wait while we check the system...
          </div>
          <div *ngIf="resetPasswordErrorMessage()" class="alert alert-danger">
            {{ resetPasswordErrorMessage() }}
          </div>
          <div *ngIf="resetPasswordSuccessMessage()" class="alert alert-success">
            {{ resetPasswordSuccessMessage() }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            [disabled]="resetPasswordLoading() || resetPasswordSuccessMessage()"
            type="button"
            class="btn btn-primary mr-1"
            (click)="resetPassword()"
          >
            Send password reset email
          </button>
          <button type="button" class="btn btn-link" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- user logs in but has multiple types of accounts-->
<div
  *ngIf="
    loggedIn() &&
    !userIdentityCurrent() &&
    userIdentities() &&
    userIdentities().length > 1
  "
>
  <div
    class="card pt-5 p-3"
    style="width: 90%; max-width: 520px; margin: 0 auto"
  >
    <div class="card-body">
      <div class="text-center">
        <h2>Welcome, {{ auth.getLocalUserName() }}.</h2>
        <p class="mb-3">
          Your login is associated with multiple accounts.<br />
          Please select the account you wish to use:
        </p>
      </div>
      <ul class="list-group mb-3">
        <ng-container *ngFor="let userIdentity of userIdentities(); index as i">
          <!-- TODO: add icons for different user identities; append identity **role** name (first and last will typically be the same) -->
          <li
            *ngIf="userIdentity.identityType == 'admin'"
            class="list-group-item"
          >
            <div class="form-check">
              <input
                id="ident_{{ i }}"
                type="radio"
                class="form-check-input"
                [value]="userIdentity"
                [(ngModel)]="selectedIdentity"
              />
              <label for="ident_{{ i }}" class="form-check-label">
                {{ userIdentity.identityName }}: {{ userIdentity.firstName }}
                {{ userIdentity.lastName }}
              </label>
            </div>
          </li>
          <li
            *ngIf="userIdentity.identityType == 'agent'"
            class="list-group-item"
          >
            <div class="form-check">
              <input
                id="ident_{{ i }}"
                type="radio"
                class="form-check-input"
                [value]="userIdentity"
                [(ngModel)]="selectedIdentity"
              />
              <label for="ident_{{ i }}" class="form-check-label">
                {{ userIdentity.identityName }}: {{ userIdentity.firstName }}
                {{ userIdentity.lastName }}
              </label>
            </div>
          </li>
          <li
            *ngIf="userIdentity.identityType == 'buyer'"
            class="list-group-item"
          >
            <div class="form-check">
              <input
                id="ident_{{ i }}"
                type="radio"
                class="form-check-input"
                [value]="userIdentity"
                [(ngModel)]="selectedIdentity"
              />
              <label for="ident_{{ i }}" class="form-check-label">
                {{ userIdentity.identityName }}: {{ userIdentity.firstName }}
                {{ userIdentity.lastName }}
              </label>
            </div>
          </li>
        </ng-container>
      </ul>
      <div align="center">
        <div class="group-buttons row">
          <div class="col-md-6">
            <button
              class="btn btn-primary btn-block"
              [disabled]="!selectedIdentity()"
              (click)="selectedIdentity() && loginToAccount(selectedIdentity())"
            >
              Continue
            </button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-link btn-block" (click)="auth.logout()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="loggingIn()" class="alert alert-info">
        <span class="loading">Contacting server...</span>
      </div>
      <div *ngIf="!loggingIn() && errorMessage()" class="alert alert-danger mt-2">
        {{ errorMessage() }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="loggedIn() && loggingIn()">
  <h2 class="text-center">Please wait...</h2>
</div>

<div *ngIf="loggedIn() && !loggingIn() && userIdentityCurrent()">
  <div class="container">
    <!-- TODO: loading state during transition/redirect to admin home -->

    <!-- LANDING HOME for ACCOUNT USERS ONLY -->
    <!-- TODO: move to its own homepage -->
    <ng-container *ngIf="userIdentityCurrent()?.identityType == 'account'">
      <div class="alert alert-info">
        Coming soon: <strong>Account User Dashboard</strong>!
      </div>
      <h2>Welcome back, {{ auth.getLocalUserName() }}.</h2>
    </ng-container>
  </div>
</div>

<div
  *ngIf="
    loggedIn() && !loggingIn() && !userIdentityCurrent() && !userIdentities()
  "
>
  <div class="row">
    <div class="mx-auto my-5 col-lg-6">
      <h2>No Roles Assigned</h2>
      <p>You have not yet been assigned to a user type.</p>
      <p class="mb-3">
        Please contact your system administrator to gain access to this system.
      </p>
      <p>
        <a class="btn btn-secondary" (click)="auth.logout()">
          <fa-icon icon="power-off" class="mr-2"></fa-icon>
          Log out
        </a>
      </p>
    </div>
  </div>
</div>
