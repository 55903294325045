import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private _showLoading$ = new BehaviorSubject<boolean>(false);
	/**
	 * Observable stream of Loading visibility state
	 */
	public get showLoading$(): Observable<boolean> {
		return this._showLoading$.asObservable();
    }

    constructor() { }

    /**
	 * Shows the loading indicator in Nav
	 */
	public show(): void {
		// delay prevents the 'changed after checked' error: https://github.com/angular/angular/issues/16921
		setTimeout(() => {
			this._showLoading$.next(true);
		});
    } 

    /**
	 * Hide the loading indicator in Nav
	 */
	public hide(): void {
		// delay prevents the 'changed after checked' error: https://github.com/angular/angular/issues/16921
		setTimeout(() => {
			this._showLoading$.next(false);
		});
    }
}
