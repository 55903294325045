
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


// add imports
import { ListResultItem, IListResultItem } from '../models/list-result-item'; 
import { SystemRoleModel, ISystemRoleModel } from '../models/system-role-model'; 
import { UpdateOptions, IUpdateOptions } from '../models/update-options'; 

@Injectable()
export class SystemRolesService {

	constructor(public _http: HttpClient, public _auth: AuthService) { } 
	
	public getResultItems(i_oSearchOptions: SearchOptions): Observable<ListResultItem[]> {
		
		return this._http.post<ListResultItem[]>(`api/System/SystemRoles/getResultItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IListResultItem[]).map(x => new ListResultItem(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItems(i_oSearchOptions: SearchOptions): Observable<SystemRoleModel[]> {
		
		return this._http.post<SystemRoleModel[]>(`api/System/SystemRoles/getItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as ISystemRoleModel[]).map(x => new SystemRoleModel(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemNew(): Observable<SystemRoleModel> {
		
		return this._http.get<SystemRoleModel>(`api/System/SystemRoles/getItemNew`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemRoleModel(<ISystemRoleModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemById(id: number): Observable<SystemRoleModel> {
		
		return this._http.get<SystemRoleModel>(`api/System/SystemRoles/getItemById/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemRoleModel(<ISystemRoleModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getById(id: number): Observable<SystemRoleModel> {
		
		return this._http.get<SystemRoleModel>(`api/System/SystemRoles/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemRoleModel(<ISystemRoleModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public putColumn(i_oUpdateOptions: UpdateOptions): Observable<UpdateOptions> {
		
		return this._http.put<UpdateOptions>(`api/System/SystemRoles/putColumn`, i_oUpdateOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new UpdateOptions(<IUpdateOptions>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public put(id: number, i_oValue: SystemRoleModel): Observable<SystemRoleModel> {
		
		return this._http.put<SystemRoleModel>(`api/System/SystemRoles/${id}`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemRoleModel(<ISystemRoleModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public post(i_oValue: SystemRoleModel): Observable<SystemRoleModel> {
		
		return this._http.post<SystemRoleModel>(`api/System/SystemRoles`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemRoleModel(<ISystemRoleModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public delete(id: number): Observable<any> {
		
		return this._http.delete<any>(`api/System/SystemRoles/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public deleteBulk(i_iIdArray: number[]): Observable<any> {
		
		return this._http.post<any>(`api/System/SystemRoles/deleteBulk`, i_iIdArray, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
}

