<!-- same as create password -->
<div class="container">
	<div class="row">
		<div class="col-12 text-center">
			<h1 *ngIf="reset">
				Password Reset
			</h1>
			<h1 *ngIf="!reset">
				{{authResultTemp ? ('Welcome '+authResultTemp.fullName) : ''}}
			</h1>
		</div>
		<div class="col-12 text-center">
			<ng-container *ngIf="!reset">
				<p class="h5">Thank you for registering on Comply Smiple. We have set up an account for you already,</p>
				<p class="h5">and in order to begin, you will need to create a password below.</p>
			</ng-container>

			<p *ngIf="loading" class="loading">Please wait while we check your validation key...</p>

			<div *ngIf="errorMessageHash">

				<div class="alert alert-danger alert-max-width">
					{{ errorMessageHash }}
				</div>

				<button class="btn btn-primary" (click)="goToLogin()">Go to login</button>

			</div>
		</div>
	</div>

	<ng-container *ngIf="validHash">

		<p class="text-center mx-auto mt-3 cli-max-width">
			Your new password must contain at least eight characters, including one lowercase and one uppercase letter, one number, and one special character (! &#64; # $ or %).
		</p>

		<div class="row mt-3">
			<div class="col-6 mx-auto">
				<label>Create password</label>
				<input type='password' class="form-control" [(ngModel)]="password1" (ngModelChange)="validatePassword()"/>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-6 mx-auto">
				<label>Confirm password</label>
				<input type='password' class="form-control" [(ngModel)]="password2" (ngModelChange)="validatePassword()" />
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-6 mx-auto">

				<div *ngIf="errorMessage" class="alert alert-danger">
					{{ errorMessage }}
				</div>
				<div *ngIf="successMessage" class="alert alert-success">
					{{ successMessage }}
				</div>

				<p *ngIf="updating" class="loading">Please wait while we update your password...</p>

				<button *ngIf="!updating && !successMessage" class="btn btn-success btn-block" [disabled]="!passwordsValid" 
						(click)="updatePassword()">
					Change password
				</button>

				<button *ngIf="successMessage" class="btn btn-primary btn-block" (click)="goToLogin()">
					Go to login
				</button>
			</div>
		</div>

	</ng-container>

</div>
