import {Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {UIAlertComponent, UIAlertItem, UIComponentBase} from "@buildable/foundation";
import {MlsDataService} from "@services/mls-data.service";
import {BuyerOffersService} from "@services/buyer-offers.service";
import {Router} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'pre-approval-upload',
  standalone: true,
  imports: [CommonModule, FaIconComponent, UIAlertComponent, ReactiveFormsModule],
  templateUrl: './pre-approval-upload.component.html',
  styleUrls: ['pre-approval-upload.component.scss'],
  providers: [BuyerOffersService],
})
export class PreApprovalUploadComponent extends UIComponentBase implements OnInit{
  public alert: UIAlertItem = new UIAlertItem();

  public showUploader: boolean = false;

  public selectedFile: File | null = null;
  public isLoading: boolean = false;

  constructor(
    private _buyerOfferService: BuyerOffersService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
  }

  goUploadPreApproval() {
    this.showUploader = true;
  }

  onFileSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.selectedFile = file;

      this.alert.reset();
      this.isLoading = true

      this._buyerOfferService.uploadPreApproval(this.selectedFile).subscribe({
        next: (res) => {
          this.alert.success(`Successfully upload pre-approval.`);
          this.isLoading = false;
          this.router.navigate(['/buyer/offer-wizard']);
        },
        error: (err) => {
          this.alert.error("Unable to upload file.", err.statusText);
          this.isLoading = false;
        }
      });
    }
  }
}
