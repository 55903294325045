
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

export enum OfferStatus {
	// Enumeration values
  PreApprovalRequired,
  PreApprovalApproved,
  OfferOpen,
  OfferApproved,
  OfferRejected,
  OfferSigned,
  OfferCompleted,
}







