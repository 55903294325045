
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


// add imports
import { ListResultItem, IListResultItem } from '../models/list-result-item'; 
import { SystemAgentModel, ISystemAgentModel } from '../models/system-agent-model'; 
import { UpdateOptions, IUpdateOptions } from '../models/update-options'; 

@Injectable()
export class SystemAgentsService {

	constructor(public _http: HttpClient, public _auth: AuthService) { } 
	
	public getResultItems(i_oSearchOptions: SearchOptions): Observable<ListResultItem[]> {
		
		return this._http.post<ListResultItem[]>(`api/SystemAgents/getResultItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IListResultItem[]).map(x => new ListResultItem(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItems(i_oSearchOptions: SearchOptions): Observable<SystemAgentModel[]> {
		
		return this._http.post<SystemAgentModel[]>(`api/SystemAgents/getItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as ISystemAgentModel[]).map(x => new SystemAgentModel(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemById(id: number): Observable<SystemAgentModel> {
		
		return this._http.get<SystemAgentModel>(`api/SystemAgents/getItemById/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemAgentModel(<ISystemAgentModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemNew(): Observable<SystemAgentModel> {
		
		return this._http.get<SystemAgentModel>(`api/SystemAgents/getItemNew`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemAgentModel(<ISystemAgentModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public post(i_oValue: SystemAgentModel): Observable<SystemAgentModel> {
		
		return this._http.post<SystemAgentModel>(`api/SystemAgents`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemAgentModel(<ISystemAgentModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public putColumn(i_oUpdateOptions: UpdateOptions): Observable<UpdateOptions> {
		
		return this._http.put<UpdateOptions>(`api/SystemAgents/putColumn`, i_oUpdateOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new UpdateOptions(<IUpdateOptions>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public put(id: number, i_oValue: SystemAgentModel): Observable<SystemAgentModel> {
		
		return this._http.put<SystemAgentModel>(`api/SystemAgents/${id}`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new SystemAgentModel(<ISystemAgentModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public delete(id: number): Observable<any> {
		
		return this._http.delete<any>(`api/SystemAgents/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public deleteBulk(i_iIdArray: number[]): Observable<any> {
		
		return this._http.post<any>(`api/SystemAgents/deleteBulk`, i_iIdArray, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
}

