import {Component, OnDestroy, inject, ViewChild, ElementRef} from '@angular/core';
import { AuthService } from '@services/auth.service';
import {UIAlertComponent, UIAlertItem, UIComponentBase} from '@buildable/foundation';
import { RouterModule } from '@angular/router';
import {MlsDataService} from "@services/mls-data.service";

@Component({
	selector: 'admin-home',
	standalone: true,
    imports: [
        RouterModule,
        UIAlertComponent,
    ],
	templateUrl: './admin-home.component.html',
})
export class AdminHomeComponent extends UIComponentBase implements OnDestroy {
	public auth = inject(AuthService);

	constructor() {
		super();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}
}
