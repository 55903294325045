
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


// add imports
import { ListResult, IListResult } from '../models/list-result'; 

@Injectable()
export class CacheService {

	constructor(public _http: HttpClient, public _auth: AuthService) { } 
	
	public getEnums(): Observable<ListResult[]> {
		
		return this._http.post<ListResult[]>(`api/Session/getEnums`, null, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IListResult[]).map(x => new ListResult(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getEnumsAdmin(): Observable<ListResult[]> {
		
		return this._http.post<ListResult[]>(`api/Session/getEnumsAdmin`, null, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IListResult[]).map(x => new ListResult(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
}

