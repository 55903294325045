import { Component, OnInit, OnDestroy, inject, signal } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { SessionService } from '@services/session.service';
import { CookieService } from 'ngx-cookie-service';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { AuthUserIdentity } from '@models/auth-user-identity';
import { UIComponentBase } from '@buildable/foundation';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LogNinja } from '@services/log-ninja.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@app/icons/icons.module';
import { ButtonLoadingDirective } from '@directives/button-loading.directive';

// declare the Index.chsml development login and pass
declare var _MyInitialAuthentication: boolean;
declare var _MyInitialLogin: string;
declare var _MyInitialPassword: string;
declare var _MyDevelopment: boolean;

@Component({
  selector: 'login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    IconsModule,
    RouterLink,
    ButtonLoadingDirective,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends UIComponentBase implements OnInit, OnDestroy {
  public readonly DOT_CIRCLE = faDotCircle;
  public readonly ARROW_RIGHT = faArrowRight;
  public readonly EXCLAMATION_TRIANGLE = faExclamationTriangle;

  public loggedIn = signal(false);
  public loggingIn = signal(false);
  public errorMessage = signal('');

  public resetPasswordEmail = signal('');
  public resetPasswordErrorMessage = signal('');
  public resetPasswordSuccessMessage = signal('');
  public resetPasswordLoading = signal(false);

  public userIdentityCurrent = signal<AuthUserIdentity | null>(null);
  public userIdentities = signal<AuthUserIdentity[]>([]);
  public userLogin = signal('');
  public userPassword = signal('');

  public selectedIdentity = signal<AuthUserIdentity | null>(null);
  public rememberMyChoice = signal(false);
  public isMobile = signal(false);
  public forceRenderKey = signal(Math.random());

  private _debug = false;

  public myDevelopment = false;

  public auth = inject(AuthService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _cookie = inject(CookieService);
  private _session = inject(SessionService);
  private _deviceService = inject(DeviceDetectorService);
  private _logger = inject(LogNinja);

  constructor() {
    super();

    this.isMobile.set(this._deviceService.isMobile());
    this._logger.log('isMobile = ' + this.isMobile());

    // Indicate mode of the app
    try {
      this.myDevelopment = _MyDevelopment;
    } catch (err) {
      this._logger.warn(err);
    }

    this.addSubscriptions(
      this._session.isUserLoggedIn$.subscribe((value) => {
        this.loggedIn.set(value);
        this.userIdentities.set(this.auth.getLocalUserIdentities());
        if (this._debug) this._logger.debug('identities: ', this.userIdentities());
        this.userIdentityCurrent.set(this.auth.getLocalUserIdentityCurrent());
        this.selectedIdentity.set(this.userIdentityCurrent());
      }),
    );
  }

  ngOnInit() {
    // Do the initialization work in the route landing.
    // Because this component can be reloaded multiple times on identity switch.
    this.addSubscriptions(
      this._route.params.subscribe((_) => {
        if (this._debug)
          this._logger.debug('HOME => ngOnInit() => _MyInitialAuthentication = ' + _MyInitialAuthentication);

        if (this._cookie.check('loginRememberMyChoice')) {
          let value = this._cookie.get('loginRememberMyChoice');
          try {
            this.rememberMyChoice.set(JSON.parse(value));
          } catch (error) {
            this._cookie.delete('loginRememberMyChoice'); // delete bad cookie
            this.rememberMyChoice.set(false);
          }
        }

        if (this.loggedIn()) {
          this.autoRedirect();
        } else {
          if (_MyInitialLogin != null && _MyInitialLogin != '') {
            this.userLogin.set(_MyInitialLogin);
          }
          if (_MyInitialPassword != null && _MyInitialPassword != '') {
            this.userPassword.set(_MyInitialPassword);
          }
        }
      }),
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  autoRedirect() {
    // Auto-redirect to correct page based on role (if single role user)
    if (this.userIdentityCurrent() != null) {
      // Depending on the identity, we could redirect to specific pages.
      this.loggingIn.set(false);
      switch (this.userIdentityCurrent()!.identityType) {
        case 'admin':
          if (this._debug) this._logger.debug('Logging in as ADMIN..');
          this.adminHome();
          return;
        case 'buyer':
          if (this._debug) this._logger.debug('Logging in as BUYER..');
          this.buyerHome();
          return;
        case 'agent':
          if (this._debug) this._logger.debug('Logging in as AGENT..');
          this.agentRedirect();
          return;
        default: {
          this._logger.error('Unknown identity', this.userIdentityCurrent()!.identityType);
          break;
        }
      }
    } else {
      // No current identities => ask the user for one.
      if (this.userIdentities().length < 0) {
        // Problem => user does not have access to this system
        this._logger.error('No identities => case not implemented');
      } else if (this.userIdentities().length == 1) {
        // Auto login to the single account of the user
        this.loginToAccount(this.userIdentities()[0]);
      } else {
        if (this._cookie.check('loginIdentity')) {
          let hash = this._cookie.get('loginIdentity');
          try {
            // Find matching identity (to prevent issues)
            let matches = this.userIdentities().filter((x) => x.hash == hash);
            if (matches.length > 0) {
              // Auto login to the remembered identity
              this.loginToAccount(matches[0]);
            } else {
              this._cookie.delete('loginIdentity'); // delete bad identity
            }
          } catch (err) {
            this._cookie.delete('loginIdentity'); // delete bad identity
            this._logger.error('Invalid identity cookie', err);
          }
        }
        if (this._debug) this._logger.log('this.userIdentities = ', this.userIdentities());
      }
    }
  }

  login() {
    if (this.userLogin() == '' || this.userPassword() == '') {
      this.errorMessage.set('Please enter a login and password');
      return;
    }

    this.loggingIn.set(true);
    this.errorMessage.set('');
    this.auth.login(this.userLogin(), this.userPassword()).subscribe({
      next: (result) => {
        this.errorMessage.set('');
        this.loggingIn.set(false);
        this.autoRedirect();
      },
      error: (error: any) => {
        this.loggingIn.set(false);
        this.errorMessage.set(error.statusText);
      },
      complete: () => {
        if (this._debug) this._logger.log('login finished');
      },
    });
  }

  loginToAccount(userIdentity: AuthUserIdentity | undefined | null) {
    if (userIdentity == null) {
      this.errorMessage.set('Invalid identity');
      return;
    }

    if (this.rememberMyChoice()) {
      this._cookie.set('loginRememberMyChoice', JSON.stringify(this.rememberMyChoice()), new Date(9999, 12, 31)); // never expires
      this._cookie.set('loginIdentity', userIdentity.hash!, new Date(9999, 12, 31)); // never expires
    } else {
      this._cookie.delete('loginRememberMyChoice');
      this._cookie.delete('loginIdentity');
    }
    //
    this.errorMessage.set('');
    this.auth.loginToAccount(userIdentity).subscribe({
      next: () => {
        this.errorMessage.set('');
        this.autoRedirect();
      },
      error: (error: any) => {
        this.errorMessage.set(error.statusText);
        return null;
      },
      complete: () => {
        if (this._debug) this._logger.log('loginToAccount finished');
      },
    });
  }

  resetPasswordInit() {
    this.resetPasswordLoading.set(false);
    this.resetPasswordErrorMessage.set('');
    this.resetPasswordSuccessMessage.set('');
    this.resetPasswordEmail.set('');
  }

  resetPassword() {
    this.resetPasswordLoading.set(true);
    this.resetPasswordSuccessMessage.set('');
    this.resetPasswordErrorMessage.set('');
    if (this.resetPasswordEmail() == '') {
      this.resetPasswordErrorMessage.set('Please supply an email');
      return;
    }
    this.auth.resetPassword(this.resetPasswordEmail()).subscribe({
      next: () => {
        this.resetPasswordLoading.set(false);
        this.resetPasswordSuccessMessage.set('An email was sent to this address with a password reset link.');
      },
      error: (error: any) => {
        this.resetPasswordLoading.set(false);
        this.resetPasswordErrorMessage.set(error.statusText);
        return null;
      },
      complete: () => {
        if (this._debug) this._logger.debug('reset password finished');
      },
    });
  }

  /** Setup the admin home landing page */
  adminHome() {
    this._router.navigate(['/admin/home']);
  }

  buyerHome() {
    this._router.navigate(['/buyer']);
  }

  agentRedirect() {
    this._router.navigate(['/agent/offers']);
  }
}
