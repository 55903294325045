<div class="container mb-5">
  <ui-alert [alert]="editAlert"></ui-alert>
  <ui-header [header]="'Buyer Offer'"></ui-header>
  <ng-container *ngIf="editViewModel.data">
    <ui-editview [viewModel]="editViewModel"  [customLayout]="true">
      <div customLayout>
        <div class="row mb-2">
          <div class="col-md-6">
            <label for="address">Address</label>
            <input type="text"
                   class="ui-editview-input form-control w-md-100"
                   [(ngModel)]="editViewModel.data.address"
                   id="address"
                   #addressInput
                   (keyup)="getAddressSuggestions(addressInput.value)"
                   [typeahead]="suggestions$"
                   [typeaheadAsync]="true"
            >
          </div>
        </div>

        <div class="row mb-2">
          <ui-editview-item field="buyerName" class="col-md-4"></ui-editview-item>
          <ui-editview-item field="buyerPhone" class="col-md-4"></ui-editview-item>
          <ui-editview-item field="buyerEmail" class="col-md-4"></ui-editview-item>
        </div>

        <div class="row mb-2">
          <ui-editview-item field="buyer2Name" class="col-md-4"></ui-editview-item>
          <ui-editview-item field="buyer2Phone" class="col-md-4"></ui-editview-item>
          <ui-editview-item field="buyer2Email" class="col-md-4"></ui-editview-item>
        </div>

        <div class="row mb-2">
          <ui-editview-item field="askingPrice" class="col-md-4"></ui-editview-item>
          <ui-editview-item field="paymentMethod" class="col-md-4"></ui-editview-item>
          <ui-editview-item field="downPayment" class="col-md-4"></ui-editview-item>
        </div>

        <div class="row mb-2">
          <ui-editview-item field="earnestMoney" class="col-md-4"></ui-editview-item>
          <ui-editview-item field="buyerHasHomeToSell" class="col-md-4"></ui-editview-item>
        </div>

        <div class="row mb-2">
          <ui-editview-item field="addressToSell" class="col-md-8"></ui-editview-item>
          <ui-editview-item field="noDaysToSell" class="col-md-4"></ui-editview-item>
        </div>

        <div class="row mb-4">
          <ui-editview-item field="closingDate" class="col-md-4"></ui-editview-item>
        </div>

      </div>
    </ui-editview>
  </ng-container>
</div>
