import { Component } from '@angular/core';


@Component({
  selector: 'button-loading-spinner',
  templateUrl: './button-loading-spinner.component.html',
  styleUrls: ['./button-loading-spinner.component.scss'],
  standalone: true,
})
export class ButtonLoadingSpinnerComponent {
  constructor() { }
  ngOnInit() {
  }
}
