import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {WizardOfferService} from "@services/wizard-offer.service";

@Component({
  selector: 'step-1-welcome',
  standalone: true,
  imports: [CommonModule ],
  templateUrl: './step-1-welcome.component.html',
  styleUrls: ['step-1-welcome.component.scss']
})
export class Step1WelcomeComponent implements OnInit{

  private buyerOfferService = inject(WizardOfferService);

  ngOnInit() {
    this.buyerOfferService.setIsValid(true);
  }
}
