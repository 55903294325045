import {OfferStatus} from "../models/offer-status";

export function getStatusText(status: OfferStatus) {
  switch (status) {
    case OfferStatus.PreApprovalRequired:
      return 'Pre-approval required'
    case OfferStatus.PreApprovalApproved:
      return 'Pre-approval required'
    case OfferStatus.OfferOpen:
      return 'Open'
    case OfferStatus.OfferApproved:
      return 'Approved'
    case OfferStatus.OfferCompleted:
      return 'Completed'
    case OfferStatus.OfferRejected:
      return 'Rejected'
    case OfferStatus.OfferSigned:
      return 'Signed'
  }
}

export function getStatusTextClass(status: OfferStatus) {
  switch (status) {
    case OfferStatus.PreApprovalRequired:
      return 'badge badge-light'
    case OfferStatus.PreApprovalApproved:
      return 'badge badge-light'
    case OfferStatus.OfferOpen:
      return 'badge badge-warning'
    case OfferStatus.OfferApproved:
      return 'badge badge-success'
    case OfferStatus.OfferCompleted:
      return 'badge badge-primary'
    case OfferStatus.OfferRejected:
      return 'badge badge-danger'
    case OfferStatus.OfferSigned:
      return 'badge badge-info'
  }
}

