<div class="full-height row">
  <div class="col-md-6 d-flex flex-column justify-content-center">
    <h1 class="h1-responsive fw-bold">How much do you want to offer?</h1>
    <p class="mt-3 subheader">We will provide our recommendation and confirm
      your desired price before drafting your offer</p>
    <div class="mt-4">
      <div class="wizard-input-currency">
        <span class="input-group-text">$</span>
        <input type="number"
               [(ngModel)]="askingPrice"
               id="asking_price"
               min="1"
               placeholder="Asking Price"
               (ngModelChange)="updateBuyerOffer()"
        >
      </div>
    </div>
  </div>
</div>
