<div class="full-height row">
  <div class="d-flex flex-column justify-content-center">
    <div>
      <h1 class="col-md-9 h1-responsive fw-bold">What is the address of the home you need to sell?</h1>
      <div class="mt-3 col-md-6">
        <input type="text"
          class="wizard-input"
          [(ngModel)]="address"
          id="address"
          placeholder="Enter Address"
          (ngModelChange)="updateBuyerOffer()"
        />
      </div>
    </div>
  </div>

</div>
