import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";
import {CommonModule} from "@angular/common";
import {UIAlertComponent, UIAlertItem, UIHeaderComponent,} from "@buildable/foundation";
import {TransitionComponent} from '@components/_generics/transition.component';
import {Step1WelcomeComponent} from "@containers/buyer/offer-wizard/steps/step-1-welcome/step-1-welcome.component";
import {Step2AddressComponent} from "@containers/buyer/offer-wizard/steps/step-2-address/step-2-address.component";
import {Step3BuyerComponent} from "@containers/buyer/offer-wizard/steps/step-3-buyer/step-3-buyer.component";
import {Step5PriceComponent} from "@containers/buyer/offer-wizard/steps/step-5-price/step-5-price.component";
import {
  Step6FinancingComponent
} from "@containers/buyer/offer-wizard/steps/step-6-financing/step-6-financing.component";
import {
  Step7DownPaymentComponent
} from "@containers/buyer/offer-wizard/steps/step-7-down-payment/step-7-down-payment.component";
import {
  Step8EarnestMoneyComponent
} from "@containers/buyer/offer-wizard/steps/step-8-earnest-money/step-8-earnest-money.component";
import {
  Step9HomeToSellComponent
} from "@containers/buyer/offer-wizard/steps/step-9-home-to-sell/step-9-home-to-sell.component";
import {
  Step10HomeToSellAddressComponent
} from "@containers/buyer/offer-wizard/steps/step-10-home-to-sell-address/step-10-home-to-sell-address.component";
import {
  Step11HomeToSellDaysComponent
} from "@containers/buyer/offer-wizard/steps/step-11-home-to-sell-days/step-11-home-to-sell-days.component";
import {
  Step12ClosingDateComponent
} from "@containers/buyer/offer-wizard/steps/step-12-closing-date/step-12-closing-date.component";
import {
  Step13ReviewOfferComponent
} from "@containers/buyer/offer-wizard/steps/step-13-review-offer/step-13-review-offer.component";
import {PaymentMethods} from "@models/payment-methods";
import {Subscription} from "rxjs";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {BuyerOffersService} from "@services/buyer-offers.service";
import {Router} from "@angular/router";
import { ButtonLoadingDirective } from '@app/directives/button-loading.directive';

@Component({
  selector: 'app-offer-wizard',
  standalone: true,
  imports: [
    CommonModule, FontAwesomeModule,
    UIAlertComponent,
    TransitionComponent,
    ButtonLoadingDirective,
    Step1WelcomeComponent, Step2AddressComponent, Step3BuyerComponent, Step5PriceComponent,
    Step6FinancingComponent, Step7DownPaymentComponent, Step8EarnestMoneyComponent, Step9HomeToSellComponent,
    Step10HomeToSellAddressComponent, Step11HomeToSellDaysComponent, Step12ClosingDateComponent, Step13ReviewOfferComponent
  ],
  templateUrl: './offer-wizard.component.html',
  styleUrls: ['offer-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [BuyerOffersService]
})
export class OfferWizardComponent implements AfterViewInit, OnDestroy {
  public isLoading: boolean = false;
  public readonly faChevronLeft = faChevronLeft;
  public readonly faChevronRight = faChevronRight;

  private _wizardOfferService = inject(WizardOfferService);
  private _buyerOffersService = inject(BuyerOffersService);
  private _router = inject(Router);

  private cdr = inject(ChangeDetectorRef);

  public wizardAlert: UIAlertItem = new UIAlertItem();

  @ViewChild('wizardComponent') wizardComponent!: TransitionComponent;

  public buyerOffer = signal(this._wizardOfferService.getBuyerOffer());

  public wizardConditions: WritableSignal<boolean[]> = signal([]);

  private isValidSubscription: Subscription;
  public isValidStep: boolean = false;
  public isLastStep: boolean = false;
  public isFirstStep: boolean = false;

  public currentStep: number;

  constructor() {
    this.isValidSubscription = this._wizardOfferService.isValid$.subscribe(() => {
      this.isValidStep = this._wizardOfferService.getIsValid();
    });

    this.currentStep = this._wizardOfferService.getSavedStep();
    this.onIndexChange(this.currentStep);
  }


  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  previous() {
    this.wizardComponent.previous();
    this.isLastStep = false;
    this._wizardOfferService.saveStepToStorage(this.currentStep);
  }

  next() {
    this._wizardOfferService.saveBuyerOffer();

    this.updateBuyerOffer();

    this.wizardComponent.next();
    this._wizardOfferService.setIsValid(false);
    this._wizardOfferService.saveStepToStorage(this.currentStep);
  }

  onLastItem() { this.isLastStep = true; }
  onIndexChange(newIndex: number) {
    this.currentStep = newIndex;
    this.isFirstStep = newIndex === 0;
  }

  submit() {
    this.isLoading = true;

    // Get buyer offer from wizard
    this.buyerOffer.set(this._wizardOfferService.getBuyerOffer());

    this._buyerOffersService.submitOffer(this.buyerOffer()).subscribe({
      next: offer => {
        this.isLoading = false;
        this._router.navigate(['/buyer/offer']);
      },
      error: err => {
        this.wizardAlert.error("Unable to submit offer.", err.statusText);
        this.isLoading = false;
      }
    });
  }

  updateBuyerOffer() {
    this.buyerOffer.set(this._wizardOfferService.getBuyerOffer());

    this.wizardConditions.set([
      true, // step 1
      true, // step 2
      true, // step 3
      true, // step 4
      true, // step 5
      this.buyerOffer().paymentMethod == PaymentMethods.Financing, // step 6 => Show if buyer payment method = Financing
      true, // step 7
      true, // step 8
      this.buyerOffer().buyerHasHomeToSell == true, // step 9 -> Buyer has home to sell
      this.buyerOffer().buyerHasHomeToSell == true, // step 10 -> Buyer has home to sell
      true, // step 11
      true, // step 12
      true, // step 13
    ]);
  }

  ngOnDestroy() {
    this.isValidSubscription.unsubscribe();
  }
}
