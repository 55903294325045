import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { BuyerOffersService } from '@services/buyer-offers.service'
import {CommonModule} from "@angular/common";
import {
  ListResultItem,
  SearchOptions,
  UIAlertComponent, UIAlertItem, UIComponentBase,
  UIEditViewComponent, UIEditViewItem, UIEditViewItemComponent, UIEditViewModel, UIEditViewValidateResult,
  UIHeaderComponent, UIListChange, UIListItem, UIListItemStyle, UIListViewColumn,
  UIListViewComponent, UIListViewModel,
  UILoadingBarComponent, UIModalComponent
} from "@buildable/foundation";
import {AuthService} from "@services/auth.service";
import {CacheDataService} from "@services/cache-data.service";
import {SystemAdminModel} from "@models/system-admin-model";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged, noop,
  Observable,
  Observer,
  of,
  Subject,
  switchMap,
  tap
} from "rxjs";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {getPaymentMethodName, paymentMethodsList} from "@models/payment-methods";
import {FormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {MlsDataService} from "@services/mls-data.service";
import {SearchFields} from "@models/search-fields";
import {MlsDataModel} from "@models/mls-data-model";
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-offer-detail',
  standalone: true,
  imports: [
    CommonModule,
    UIAlertComponent, UIHeaderComponent, UIListViewComponent, UILoadingBarComponent, UIEditViewComponent,
    UIEditViewItemComponent, FormsModule, TypeaheadModule
  ],
  templateUrl: './offer-form-detail.component.html',
  providers: [BuyerOffersService, MlsDataService]
})
export class OfferFormDetailComponent extends UIComponentBase implements OnInit, OnDestroy{

  public dataId: number = 0;
  public offerModel: BuyerOfferModel = new BuyerOfferModel();
  public editAlert: UIAlertItem = new UIAlertItem();
  public editDataId: number | null = 0;
  public editLoading: boolean = false;
  public editSaving: boolean = false;

  public loadingForm: boolean = false;

  constructor(
    public auth: AuthService,
    private _cacheData: CacheDataService,
    private _data: BuyerOffersService,
    private _route: ActivatedRoute,
  ) {
    super();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    this._cacheData.loadCacheAll(); // remove if not needed

    // hook up to the route: note this will get re-executed routinely,
    // but the component won't get recreated, so any initialization depending on routes need to be done in the handler below
    this.addSubscriptions(this._route.params.subscribe(params => {
      this.dataId = +params['id']; // (+) converts string 'id' to a number
      console.log(this.dataId)
      this.reloadEdit();
    }));
  }

  reloadEdit() {
    this.editAlert.reset();
    if (this.editDataId == null) return;
    this.editLoading = true;

    if (this.dataId == 0) {
      // enforce creating new items via the API. It's cleaner.
      this._data.getItemNew().subscribe({
        next: (item) => {
          this.offerModel = item;
          this.editLoading = false;
        },
        error: (err) => {
          this.editLoading = false;
          this.editAlert.errorMessage = 'Could not get new item. Reason: ' + err.statusText;
        }
      });

    } else {
      this._data.getItemById(this.dataId).subscribe({
        next: (item) => {
          this.offerModel = item;
          this.editLoading = false;
        },
        error: (err) => {
          this.editLoading = false;
          this.editAlert.errorMessage = 'Item not found. Reason: ' + err.statusText;
        }
      });
    }
  }

  downloadFormPdf() {
    this.loadingForm = true;

    const searchOptions = new SearchOptions();
    searchOptions.fields = new SearchFields({
      offerId: this.dataId,
    });

    this._data.getFormPdf(searchOptions).subscribe({
      next: (blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.getFileName();
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.loadingForm = false;
      },
      error: (error) => {
        console.log(error)
        this.editAlert.error("Error downloading form.", error.statusText);
        this.loadingForm = false;
      }
    });
  }

  getFileName() {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
    const formattedTime = now.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
    return `Form_${formattedDate}-${formattedTime}.pdf`;
  }

  protected readonly getPaymentMethodName = getPaymentMethodName;
}
