
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder


import { Pager, IPager } from '@buildable/foundation'; 
// add imports
import { MlsDataModel, IMlsDataModel } from './mls-data-model'; 
import { SystemUserModel, ISystemUserModel } from './system-user-model'; 
import { OfferDocumentModel, IOfferDocumentModel } from './offer-document-model'; 
import { OfferStatus } from './offer-status'; 
import { PaymentMethods } from './payment-methods'; 



export interface IBuyerOfferModel {
	// Inherited properties
	selected?: boolean | null;
	editing?: boolean | null;
	pager?: IPager | null;

	// Class properties
	mlsData?: IMlsDataModel | null;
	systemUser?: ISystemUserModel | null;
	offerDocument?: IOfferDocumentModel | null;
	approvedBy?: ISystemUserModel | null;
	buyerOfferId?: number | null;
	mlsDataId?: number | null;
	systemUserId?: number | null;
	offerDocumentId?: number | null;
	approvedById?: number | null;
	status?: OfferStatus | null;
	hasPreApproval?: boolean | null;
	preApprovalFileKey?: string | null;
	address?: string | null;
	buyerName?: string | null;
	buyerPhone?: string | null;
	buyerEmail?: string | null;
	buyerAddress?: string | null;
	buyerCity?: string | null;
	buyerState?: string | null;
	buyerZip?: string | null;
	buyer2Name?: string | null;
	buyer2Phone?: string | null;
	buyer2Email?: string | null;
	askingPrice?: number | null;
	paymentMethod?: PaymentMethods | null;
	downPayment?: number | null;
	earnestMoney?: number | null;
	buyerHasHomeToSell?: boolean | null;
	addressToSell?: string | null;
	addressCityToSell?: string | null;
	addressStateToSell?: string | null;
	noDaysToSell?: number | null;
	dateAdded?: Date | null;
	dateModified?: Date | null;
	closingDate?: Date | null;
	askingPriceText?: string | null;
	closingDateFormatted?: string | null;
	buyerCityStateZip?: string | null;

}



export class BuyerOfferModel implements IBuyerOfferModel  {
	constructor(init?: Partial<IBuyerOfferModel>) { // allows us to assign this object C# style
		Object.assign(this, init);
		// Date initializers
		if (init?.dateAdded) this.dateAdded = new Date(init.dateAdded);
		if (init?.dateModified) this.dateModified = new Date(init.dateModified);
		if (init?.closingDate) this.closingDate = new Date(init.closingDate);

	}

	
	// Inherited properties
	selected?: boolean | null = false;
	editing?: boolean | null = false;
	pager?: Pager | null = null;

	// Class properties
	mlsData?: MlsDataModel | null = null;
	systemUser?: SystemUserModel | null = null;
	offerDocument?: OfferDocumentModel | null = null;
	approvedBy?: SystemUserModel | null = null;
	buyerOfferId?: number | null = 0;
	mlsDataId?: number | null = null;
	systemUserId?: number | null = null;
	offerDocumentId?: number | null = null;
	approvedById?: number | null = null;
	status?: OfferStatus | null = null;
	hasPreApproval?: boolean | null = false;
	preApprovalFileKey?: string | null = null;
	address?: string | null = null;
	buyerName?: string | null = null;
	buyerPhone?: string | null = null;
	buyerEmail?: string | null = null;
	buyerAddress?: string | null = null;
	buyerCity?: string | null = null;
	buyerState?: string | null = null;
	buyerZip?: string | null = null;
	buyer2Name?: string | null = null;
	buyer2Phone?: string | null = null;
	buyer2Email?: string | null = null;
	askingPrice?: number | null = 0;
	paymentMethod?: PaymentMethods | null = null;
	downPayment?: number | null = 0;
	earnestMoney?: number | null = 0;
	buyerHasHomeToSell?: boolean | null = false;
	addressToSell?: string | null = null;
	addressCityToSell?: string | null = null;
	addressStateToSell?: string | null = null;
	noDaysToSell?: number | null = null;
	dateAdded?: Date | null = null;
	dateModified?: Date | null = null;
	closingDate?: Date | null = null;
	askingPriceText?: string | null = null;
	closingDateFormatted?: string | null = null;
	buyerCityStateZip?: string | null = null;

	
}



