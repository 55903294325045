import { Component, OnInit, OnDestroy, ViewChild, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UIListViewColumn, UIListItem, UIListViewModel, UIListChange, UIListItemStyle, UIAlertItem, UIComponentBase, UIEditViewModel, UIEditViewValidateResult, UIEditViewComponent, UIModalComponent, SearchOptions, UIEditViewItem, UIAlertComponent, UIHeaderComponent, UIListViewComponent, UILoadingBarComponent } from '@buildable/foundation';

import { SystemRoleModel } from '@models/system-role-model';
import { AuthService } from '@services/auth.service';
import { CacheDataService } from '@services/cache-data.service';
import { SystemRolesService } from '@services/system-roles.service';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'system-role-item-list',
	standalone: true,
	imports: [
		CommonModule,
		UIAlertComponent, UIHeaderComponent, UIListViewComponent, UIModalComponent, UILoadingBarComponent, UIEditViewComponent,
	],
	templateUrl: './system-role-item-list.component.html',
	providers: [SystemRolesService ], // does not need to be SINGLETON, or global (saves on space)
})
export class SystemRoleItemListComponent extends UIComponentBase implements OnInit, OnDestroy {
	public listAlert: UIAlertItem = new UIAlertItem();
	public listViewModel: UIListViewModel;

	public editAlert: UIAlertItem = new UIAlertItem();
	public editViewModel: UIEditViewModel;
	public editDataId?: number | null;
	public editLoading: boolean = false;
	public editSaving: boolean = false;

	private _searchOptions: SearchOptions = new SearchOptions();

	// find a reference to the editview component
	@ViewChild(UIEditViewComponent)
	uiEditViewComponent!: UIEditViewComponent;
	// find a reference to the first modal included in this component. Saves having to assign an id to the modal
	@ViewChild(UIModalComponent)
	uiModal!: UIModalComponent;

	public auth = inject(AuthService);
	private _cacheData = inject(CacheDataService);
	private _data = inject(SystemRolesService);

	constructor() {
		super();

		//--------------------------
		// setup the compact listview
		this.listViewModel = new UIListViewModel({
			showPager: true,
			showButtons: true,
			//showTitle: true,
			//title: 'System Roles',
			showSearch: false,
			showFilter: false,
			addText: 'Add a role',
			addEnabled: this.auth.hasRoleAdminAdd(),
			deleteText: 'Delete',
			deleteEnabled: this.auth.hasRoleAdminDelete(),
			viewEnabled: this.auth.hasRoleAdminView(),
			reloadText: '',
			reloadEnabled: true,
			inlineEditEnabled: this.auth.hasRoleAdminUpdate(),
			items: [],
			columns: [
				//new UIListViewColumn({ label: 'ID', field: 'systemRoleId' }),
				new UIListViewColumn({ label: 'Active', field: 'isActive', type: 'boolean' }),
				new UIListViewColumn({ label: 'Name', field: 'name', type: 'text', canEdit: true, link: true, style: <UIListItemStyle>{ bold: true } }),
				new UIListViewColumn({ label: 'Role Group', field: 'systemRoleGroupName', type: 'text' }),
				new UIListViewColumn({ label: 'Default for Group', field: 'isDefaultForGroup', type: 'boolean' }),
				new UIListViewColumn({ label: 'Admin Default for Group', field: 'isAdminDefaultForGroup', type: 'boolean' }),
				new UIListViewColumn({ label: 'Added', field: 'dateAdded', type: 'date' }),
				new UIListViewColumn({ label: 'Modified', field: 'dateModified', type: 'date' }),
			]
		});
		// subscribe to events when the model will be ready
		this.addSubscriptions(this.listViewModel.initialize$.subscribe(() => {
			this.addSubscriptions(
				this.listViewModel.reload$.subscribe((searchOptions?) => this.reloadList(searchOptions)),
				this.listViewModel.add$.subscribe(() => this.addItem()),
				this.listViewModel.view$.subscribe((item: UIListItem) => this.editItem(item)),
				this.listViewModel.delete$.subscribe((items: UIListItem[]) => this.deleteSelection(items)),
				this.listViewModel.updated$.subscribe((item: UIListChange) => this.updateField(item)),
			);
		}));

		//-------------------
		// setup the editview
		this.editViewModel = new UIEditViewModel({
			showInline: true,
			showButtons: false,
			showSave: this.auth.hasRoleAdminUpdate(),
			//showReload: false,
			//showCancel: false,
			editViewItems: [
				new UIEditViewItem({ label: 'Active', field: 'isActive', type: 'checkbox' }),
				new UIEditViewItem({ label: 'Default for Group', field: 'isDefaultForGroup', type: 'checkbox' }),
				new UIEditViewItem({ label: 'Admin Default for Group', field: 'isAdminDefaultForGroup', type: 'checkbox' }),
				new UIEditViewItem({ label: 'Name', field: 'name', type: 'text', required: true }),
				new UIEditViewItem({ label: 'Role Group', field: 'systemRoleGroupId', type: 'select', optionsBs: this._cacheData.cachedSystemRoleGroup, required: true }),
				new UIEditViewItem({ label: 'Permissions', field: 'systemPermissions', type: 'checkbox-grid', required: true }),
			]
		});

		// subscribe to events when the model will be ready
		this.addSubscriptions(this.editViewModel.initialize$.subscribe(() => {
			this.addSubscriptions(
				this.editViewModel.reload$.subscribe(() => this.reloadEdit()),
				this.editViewModel.cancel$.subscribe(() => this.closeEditModal()),
				this.editViewModel.save$.subscribe(() => this.onEditSave()),
				this.editViewModel.error$.subscribe((message) => this.editAlert.errorMessage = message),
			);
		}));

		// setup the validation handlers
		this.editViewModel.beforeValidateDataCallback = (data: any) => { this.editAlert.reset(); }; // clear the error messages before validating
		this.editViewModel.validateDataCallback = (data: any) => { return this.onValidateData(data); } // hook to the local method (note we must use a lambda function, or 'this' will lose scope inside the function)
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}

	ngOnInit() {
		this._cacheData.loadCacheAll(); // remove if not needed
	}

	//////////////////////////////////////////////////////////////////////
	// listview portion

	reloadList(searchOptions?: SearchOptions | null) {
		this.listAlert.reset();

		if (searchOptions != null) {
			this._searchOptions = searchOptions;
		}

		this.listViewModel.pager.loading = true;
		this._data.getItems(this._searchOptions).subscribe({
			next: (items) => {
				if (items === undefined || items.length == 0) return; // no items

				let pager = items.pop()!.pager; // pop the pager
				this.listViewModel.items = items.map(x => {
					let item: UIListItem = new UIListItem();
					item.data = x;
					item.selected = false;
					item.id = x.systemRoleId!;
					item.showButton = true; // set to false to hide the edit button that appears in each row. 
					return item;
				});
				this.listViewModel.pager.update(pager!, this.listViewModel.items);
				this.listViewModel.pager.loading = false;
			},
			error: (err) => {
				this.listViewModel.pager.loading = false;
				this.listAlert.errorMessage = "Failed to get items. Reason: " + err.statusText;
			}
		});
	}

	deleteSelection(selectedItems: UIListItem[]) {
		let selectedIds = selectedItems.map(x => x.id!);
		this.listAlert.reset();
		this.listViewModel.pager.loading = true;

		this._data.deleteBulk(selectedIds).subscribe({
			next: () => {
				this.reloadList();
			},
			error: (err) => {
				this.listViewModel.pager.loading = false;
				this.listAlert.errorMessage = 'Failed to delete one or more items. Reason: ' + err.statusText;
			}
		});
	}

	updateField(change: UIListChange) {
		this._data.putColumn(change.updateOptions).subscribe({
			next: (result) => change.commit(result),
			error: (err) => this.listAlert.errorMessage = 'Failed to update field. Reason: ' + err.statusText,
		});
	}

	addItem() {
		this.showEditModal(0);
	}

	editItem(data: UIListItem) {
		this.showEditModal(data.id!);
	}

	//////////////////////////////////////////////////////////////////////
	// editview portion

	showEditModal(dataId: number) {
		// reset the edit view
		this.editViewModel.data = null;
		this.editViewModel.reset();

		// reload and show dialog
		this.editDataId = dataId;
		this.reloadEdit()
		this.uiModal.show();
	}
	
	closeEditModal() {
		this.editDataId = null;
		this.uiModal.hide();
	}

	reloadEdit() {
		this.editAlert.reset();
		if (this.editDataId == null) return;
		this.editLoading = true;

		if (this.editDataId == 0) {
			// enforce creating new items via the API. It's cleaner.
			this._data.getItemNew().subscribe({
				next: (item) => {
					// TODO: remove the comment block below if not needed
					// add new item => do some special treatment on the fields if needed:
					//this.editViewModel.editViewItems
					//	.filter(x => x.field == 'someField')
					//	.forEach(x => { x.disabled = false; x.required = true; });
					item.isActive = true; // default to items to active
					this.editViewModel.data = item;
					this.editViewModel.reset();
                    this.editLoading = false;
				},
				error: (err) => {
                    this.editLoading = false;
					this.editAlert.errorMessage = 'Could not get new item. Reason: ' + err.statusText;
				}
			});
		} else {
			this._data.getItemById(this.editDataId).subscribe({
				next: (item) => {
					// TODO: remove the comment block below if not needed
					// edit a new item => do some special treatment on the fields if needed:
					//this.editViewModel.editViewItems
					//	.filter(x => x.field == 'someField')
					//	.forEach(x => { x.disabled = true; x.required = false; });
					this.editViewModel.data = item;
					this.editViewModel.reset();
					this.editLoading = false;
				},
				error: (err) => {
					this.editLoading = false;
					this.editAlert.errorMessage = 'Item not found. Reason: ' + err.statusText;
				}
			});
		}
	}

	onValidateData(data: any): UIEditViewValidateResult {
		let item = <SystemRoleModel>data;
		let result = new UIEditViewValidateResult();

		// make sure that at least 1 role is selected
		if (item.systemPermissions!.filter(x => x.checked == true).length <= 0) {
			this.editViewModel.editViewItems!
				.filter(x => x.field == 'systemPermissions')
				.forEach(x => { x.valid = false; });
			result.isValid = false;
			result.appendError('Role must have one permission selected');
		}

		return result;
	}

	onEditSave() {
		this.editAlert.reset();

		// must validate manually, since button is hosted on dialog modal, and not edit view.
		let result = this.uiEditViewComponent.validateData(); // this raises the error$ event
		if (!result.isValid) {
			return;
		}
		this.editSaving = true;
		let httpObservable: Observable<SystemRoleModel> | null = null;
		if (this.editDataId == 0) {
			httpObservable = this._data.post(this.editViewModel.data);
		} else {
			httpObservable = this._data.put(this.editDataId!, this.editViewModel.data);
		}
		httpObservable.subscribe({
			next: () => {
				this.editSaving = false;
				this.reloadList();
				this.closeEditModal();
			},
			error: (err) => {
				this.editSaving = false;
				let verb = this.editDataId == 0 ? "insert" : "update";
				this.editAlert.errorMessage = `Failed to ${verb} item. Reason: ${err.statusText}`;
			}
		});
	}

	// might not be needed in simple modals
	override scrollWindowTop() {
		window.scroll(0, 0);
	}
}
