
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


// add imports

@Injectable()
export class FilesService {

	constructor(public _http: HttpClient, public _auth: AuthService) { }

	public downloadFile(fileKey: string): Observable<Blob> {

		return this._http.get<Blob>(`api/Files/downloadFile?fileKey=${encodeURIComponent(fileKey)}`,
      { headers: this._auth.initAuthHeaders(), responseType: 'blob' as 'json' })
      .pipe(
        map(response =>  {
          return response;
        }),
        catchError(response => this._auth.handleError(response))
      );
	};
}

