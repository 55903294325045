import {Component, OnDestroy, inject, ViewChild, ElementRef} from '@angular/core';
import { AuthService } from '@services/auth.service';
import {UIAlertComponent, UIAlertItem, UIComponentBase} from '@buildable/foundation';
import { RouterModule } from '@angular/router';
import {MlsDataService} from "@services/mls-data.service";

@Component({
	selector: 'csv-import',
	standalone: true,
    imports: [
        RouterModule,
        UIAlertComponent,
    ],
	templateUrl: './csv-import.component.html',
	providers: [MlsDataService],
})
export class CsvImportComponent extends UIComponentBase implements OnDestroy {

  // Import CSV logic
  public listAlert: UIAlertItem = new UIAlertItem();
  public selectedFile: File | null = null;
  @ViewChild('csvFileInput') csvFileInput!: ElementRef;


	constructor(
    private _mlsDataService: MlsDataService
  ) {
		super();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}


  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;

    if (this.selectedFile) {
      const fileName = this.selectedFile.name;
      const label = this.csvFileInput.nativeElement.nextElementSibling;
      label.textContent = fileName;
    }
  }
  importCsvData() {
    if (!this.selectedFile) {
      this.listAlert.reset();
      this.listAlert.error("Please select a CSV file.");
      return;
    }

    this.listAlert.reset();
    this.listAlert.loading = true;
    this.selectedFile.type

    this._mlsDataService.importMlsCsvData(this.selectedFile).subscribe({
      next: (res) => {
        this.listAlert.loading = false;
        this.listAlert.success(`Successfully added ${res} records to database.`);
      },
      error: (err) => {
        this.listAlert.loading = false;
        this.listAlert.error("Unable to import data from CSV.", err.statusText);
      }
    });
  }
}
