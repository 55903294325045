<div class="container mb-5">
  <ui-alert [alert]="listAlert"></ui-alert>
  <ui-header [header]="'Offers'"></ui-header>
  <hr />
  <ui-listview  [viewModel]="listViewModel">
    <ng-container ngProjectAs="[altFilters]">
      <ng-select
        [items]="filterOptions"
        placeholder="Filter by status"
        [(ngModel)]="selectedStatus"
        (ngModelChange)="reloadList()"
        bindValue="value"
      />
    </ng-container>
  </ui-listview>
</div>


<ng-template #viewBtnColumn let-item>
  <a class="btn btn-secondary btn-sm" [routerLink]="'/agent/offers/' + item.data?.buyerOfferId">
    View
  </a>
</ng-template>

<ng-template #statusColumn let-item>
  <p [ngClass]="getStatusTextClass(item.data.status)">{{ getStatusText(item.data.status) }}</p>
</ng-template>
