import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy, OnInit,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";
import {CommonModule} from "@angular/common";
import {UIAlertComponent, UIAlertItem, UIHeaderComponent,} from "@buildable/foundation";
import {PaymentMethods} from "@models/payment-methods";
import {Subscription} from "rxjs";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {BuyerOffersService} from "@services/buyer-offers.service";
import {Router} from "@angular/router";

@Component({
  selector: 'offer-status',
  standalone: true,
  imports: [
    CommonModule, FontAwesomeModule,
    UIAlertComponent, UIHeaderComponent],
  templateUrl: './offer-status.component.html',
  styleUrls: ['offer-status.component.scss'],
  providers: [BuyerOffersService]
})
export class OfferStatusComponent implements OnInit {
  public isLoading: boolean = false;

  private _buyerOffersService = inject(BuyerOffersService);
  private _router = inject(Router);

  public wizardAlert: UIAlertItem = new UIAlertItem();

  constructor() {

  }

  ngOnInit() {

  }


  home() {
    this._router.navigate(['/home'])
  }

}
