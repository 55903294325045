import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {UIAlertComponent, UIAlertItem, UIComponentBase} from '@buildable/foundation';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import {BuyerOffersService} from "@services/buyer-offers.service";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {OfferStatus} from "@models/offer-status";
import {WizardOfferService} from "@services/wizard-offer.service";
import {filter, Subscription} from "rxjs";

@Component({
	selector: 'buyer-home',
	standalone: true,
    imports: [
        RouterModule,
        UIAlertComponent,
    ],
  templateUrl: './buyer-home.component.html',
  styleUrls: ['./buyer-home.component.scss'],
  providers: [BuyerOffersService]
})
export class BuyerHomeComponent extends UIComponentBase implements OnInit ,OnDestroy {
	public auth = inject(AuthService);
	private _buyerOfferService = inject(BuyerOffersService);
  private _router = inject(Router);
  private _wizardOfferService = inject(WizardOfferService);
  private _subscription: Subscription | null = null;

  public offer: BuyerOfferModel | null = null;

  public alertComponent: UIAlertItem = new UIAlertItem();
  private _activatedRoute = inject(ActivatedRoute);

	constructor() {
		super();
	}

  ngOnInit() {

    // abstract init
    this.initializeComponent();

    // watch changes in route
    this._subscription = this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        filter(() => this.isBuyerRouteActive())
      )
      .subscribe(() => {
        this.initializeComponent();
      });
  }

  isBuyerRouteActive(): boolean {
    let currentRoute: ActivatedRoute | null = this._activatedRoute.root;

    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    return currentRoute?.snapshot?.routeConfig?.path === 'buyer';
  }

  initializeComponent() {

    // Get offer status to redirect to correct view
    this._buyerOfferService.getItemByUserId(this.auth.getLocalUserId()).subscribe({
      next: offer => {
        this.offer = offer;

        this.setBuyerOffer(this.offer);
        this.redirectToOffer(this.offer);
      },
      error: err => {
        this.alertComponent.error('An error occurred trying to start an offer.', err.statusText);
      }
    })
  }

  setBuyerOffer(offer: BuyerOfferModel) {
    const localOffer = this._wizardOfferService.getBuyerOffer();

    if (localOffer?.buyerOfferId != null && localOffer?.buyerOfferId <= 0) {
      this._wizardOfferService.setBuyerOffer(offer);
      this._wizardOfferService.setBuyerOfferTemp(offer);
    }
  }

  redirectToOffer(offer: BuyerOfferModel) {
    switch (offer.status) {
      case OfferStatus.PreApprovalRequired:
        this._router.navigate(['/buyer/pre-approval-upload/']);
        break;
      case OfferStatus.PreApprovalApproved:
        this._router.navigate(['/buyer/offer-wizard']);
        break;
      default:
        this._router.navigate(['/buyer/offer']);
        break;
    }
  }


  override ngOnDestroy() {
		super.ngOnDestroy();
	}
}
