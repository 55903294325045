import { Injectable, NgModule } from '@angular/core';
import { Router, CanDeactivate } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Observable } from 'rxjs';
import { SystemPermission } from './models/system-permissions'


/**
 * Use this guard to prevent navigation away.
 */
@Injectable()
export class CheckNavigateAway implements CanDeactivate<any> {

  canDeactivate(target: any): Observable<boolean> | boolean {
    if (target && target.canDeactivate) {
      return target.canDeactivate(); // returns an observable<boolean>, or boolean
    }
    return true;
  }

}

// define all the auth guards right here, these classes have been ultra condensed on purpose, to help audits.
// ##START CanActivate; do not modify generated content below this line.
@Injectable() export class CheckAdminAdd implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminAdd) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminDelete implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminDelete) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminPortalAccess implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminPortalAccess) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminUpdate implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminUpdate) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminUserAdd implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminUserAdd) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminUserDelete implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminUserDelete) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminUserUpdate implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminUserUpdate) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminUserView implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminUserView) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckAdminView implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AdminView) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckPortalAccess implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.PortalAccess) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckUserAdd implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.UserAdd) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckUserDelete implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.UserDelete) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckUserUpdate implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.UserUpdate) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
@Injectable() export class CheckUserView implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.UserView) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
// ##END CanActivate; do not modify generated content above this line.
@Injectable() export class CheckAgentView implements CanActivate { canActivate() { return this._auth.checkRole(SystemPermission.AgentView) ? true : this._router.parseUrl('/not-authorized'); } constructor(private _auth: AuthService, private _router: Router) { } }
// ##START CanActivate-Azure; do not modify generated content below this line.
// ##END CanActivate-Azure; do not modify generated content above this line.

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// UI module

@NgModule({
  imports: [
  ],
  providers: [
    CheckNavigateAway,
    // ##START Providers; do not modify generated content below this line.
    CheckAdminAdd,
    CheckAdminDelete,
    CheckAdminPortalAccess,
    CheckAdminUpdate,
    CheckAdminUserAdd,
    CheckAdminUserDelete,
    CheckAdminUserUpdate,
    CheckAdminUserView,
    CheckAdminView,
    CheckPortalAccess,
    CheckUserAdd,
    CheckUserDelete,
    CheckUserUpdate,
    CheckUserView,
    // ##END Providers; do not modify generated content above this line.
    // ##START Providers-Azure; do not modify generated content below this line.
    // ##END Providers-Azure; do not modify generated content above this line.
    CheckAgentView
  ],
  declarations: [
  ],
  exports: [
  ]

})
/** this contains all our auth guard providers */
export class CanActivateModule {
}

