
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

export enum SystemLogType {
	// Enumeration values
	LowLevel = 1,
	Debug = 2,
	Info = 3,
	Warning = 4,
	Error = 5,
	Fatal = 6,

}







