import { Injectable, Inject } from "@angular/core";
import { Observable, BehaviorSubject } from 'rxjs';

import { CacheService } from "./cache.service";
import { ListResultItem } from "../models/list-result-item";
import { SearchOptions } from "@buildable/foundation";
import { SearchFields } from "@models/search-fields";
import { LogNinja } from "./log-ninja.service";

export interface IServiceGetItems {
  getItems(options: SearchOptions): Observable<any[]>;
}
export interface IServiceGetResultItems {
  getResultItems(options: SearchOptions): Observable<ListResultItem[]>;
}

@Injectable()
export class CacheDataService {
  private _isCacheLoading = false;
  private _isCacheLoaded = false;

  private _isCacheAdminLoading = false;
  private _isCacheAdminLoaded = false;

  // ##START Subject; do not modify generated content below this line.
  public cachedRefCountry = new BehaviorSubject<ListResultItem[]>([]);
  public cachedRefCountryState = new BehaviorSubject<ListResultItem[]>([]);
  public cachedRefEmailTemplate = new BehaviorSubject<ListResultItem[]>([]);
  public cachedRefEmailVariable = new BehaviorSubject<ListResultItem[]>([]);
  public cachedRefLanguage = new BehaviorSubject<ListResultItem[]>([]);
  public cachedShelfShareType = new BehaviorSubject<ListResultItem[]>([]);
  public cachedShelfType = new BehaviorSubject<ListResultItem[]>([]);
  public cachedSocialAccountType = new BehaviorSubject<ListResultItem[]>([]);
  public cachedSystemDocumentType = new BehaviorSubject<ListResultItem[]>([]);
  public cachedSystemFeedbackType = new BehaviorSubject<ListResultItem[]>([]);
  public cachedSystemPermission = new BehaviorSubject<ListResultItem[]>([]);
  public cachedSystemPermissionBySortNo = new BehaviorSubject<ListResultItem[]>([]);
  public cachedSystemRoleGroup = new BehaviorSubject<ListResultItem[]>([]);
  // ##END Subject; do not modify generated content above this line.
  // ##START Subject-Azure; do not modify generated content below this line.
  // ##END Subject-Azure; do not modify generated content above this line.

  constructor(
    private _cache: CacheService,
    private _logger: LogNinja
  ) {
  }

  /** fetch a behavior subject from a getResultItems() signature.
   * Do not not call this method from a constructor, because it accesses the network.
   * @param service service with the getResultItems() method.
   * @param options optional search options, should be null to make sure it does not page. If you pass options make sure to triple check paging
   * @param field optional search field, needed when there's a related entity, or some filtering to apply.
   */
  fetchGetResultItemsBs(service: IServiceGetResultItems, options?: SearchOptions, fields?: SearchFields)
    : BehaviorSubject<ListResultItem[]> {
    let list = new BehaviorSubject<ListResultItem[]>([]);
    let opt = options ? options : new SearchOptions();
    opt.pagingDisabled = options ? options.pagingDisabled : true; // disable paging by default, otherwise, let caller dictate the paging.
    opt.fields = fields ? fields : opt.fields;
    service.getResultItems(opt).subscribe(
      (items) => {
        if (items.length > 0 && items[items.length - 1].pager != null) {
          items.pop(); // remove the pager (last item)
        }
        list.next(items);
      }
    );
    return list;
  }

  /**
   * fetch a behavior subject from a getItems() signature, and removes the last pager item properly.
   * Do not not call this method from a constructor, because it accesses the network.
   * @param service service with the getItems() method.
   * @param fieldLabel name of field in the returned models that should be used for the label (usually a name of sorts)
   * @param fieldValue name of field in the returned models that should be used for the value (usually an id)
   * @param options optional search options, needed when there's a related entity, or some filtering to apply.
   */
  fetchGetItemsBs(service: IServiceGetItems, fieldLabel?: string, fieldValue?: string, options?: SearchOptions, fields?: SearchFields)
    : BehaviorSubject<ListResultItem[]> {
    let list = new BehaviorSubject<ListResultItem[]>([]);
    let opt = options ? options : new SearchOptions();
    opt.pagingDisabled = options ? options.pagingDisabled : true; // disable paging by default, otherwise, let caller dictate the paging.
    opt.fields = fields ? fields : opt.fields;
    service.getItems(opt).subscribe(
      (items) => {
        if (items.length > 0 && items[items.length - 1].pager != null) {
          items.pop(); // remove the pager (last item)
        }
        if (fieldLabel && fieldValue) {
          let values = items.map(x => <ListResultItem>{ label: x[fieldLabel], value: x[fieldValue] });
          list.next(values);
        } else {
          list.next(items);
        }
      }
    );
    return list;
  }

  resetCacheAll() {
    this.resetCache();
    this.resetCacheAdmin();
  }

  resetCache() {
    this._isCacheLoaded = false;
    this.loadCache();
  }

  resetCacheAdmin() {
    this._isCacheAdminLoaded = false;
    this.loadCacheAdmin();
  }

  loadCacheAll() {
    this.loadCache();
    this.loadCacheAdmin();
  }

  loadCache(): void {
    if (!this._isCacheLoaded && !this._isCacheLoading) {
      this._isCacheLoading = true;
      this._cache.getEnums().subscribe({
        next: (items) => {
          this._logger.log('Received cache items');
          this._isCacheLoaded = true;
          this._isCacheLoading = false;
          items.forEach(x => {
            switch (x.listName) {
              // ##START loadCache; do not modify generated content below this line.
              case "RefCountry": this.cachedRefCountry.next(x.items!); break;
              case "RefCountryState": this.cachedRefCountryState.next(x.items!); break;
              case "RefEmailTemplate": this.cachedRefEmailTemplate.next(x.items!); break;
              case "RefEmailVariable": this.cachedRefEmailVariable.next(x.items!); break;
              case "RefLanguage": this.cachedRefLanguage.next(x.items!); break;
              case "ShelfShareType": this.cachedShelfShareType.next(x.items!); break;
              case "ShelfType": this.cachedShelfType.next(x.items!); break;
              case "SocialAccountType": this.cachedSocialAccountType.next(x.items!); break;
              case "SystemDocumentType": this.cachedSystemDocumentType.next(x.items!); break;
              case "SystemFeedbackType": this.cachedSystemFeedbackType.next(x.items!); break;
              // ##END loadCache; do not modify generated content above this line.
              // ##START loadCache-Azure; do not modify generated content below this line.
              // ##END loadCache-Azure; do not modify generated content above this line.

              default: this._logger.error('unknown cache list: ' + x.listName); break;
            }
          });
        },
        error: (err) => {
          this._isCacheLoaded = false;
          this._isCacheLoading = false;
          this._logger.error('Failed to load cache items. Reason: ' + err.statusText);
        }
      });
    }
  }


  loadCacheAdmin(): void {
    if (!this._isCacheAdminLoaded && !this._isCacheAdminLoading) {
      this._isCacheAdminLoading = true;
      this._cache.getEnumsAdmin().subscribe({
        next: (items) => {
          this._logger.log('Received admin cache items');
          this._isCacheAdminLoaded = true;
          this._isCacheAdminLoading = false;
          items.forEach(x => {
            switch (x.listName) {
              // ##START loadCacheAdmin; do not modify generated content below this line.
              case "SystemPermission": this.cachedSystemPermission.next(x.items!); break;
              case "SystemPermissionBySortNo": this.cachedSystemPermissionBySortNo.next(x.items!); break;
              case "SystemRoleGroup": this.cachedSystemRoleGroup.next(x.items!); break;
              // ##END loadCacheAdmin; do not modify generated content above this line.
              // ##START loadCacheAdmin-Azure; do not modify generated content below this line.
              // ##END loadCacheAdmin-Azure; do not modify generated content above this line.
              default: this._logger.error('unknown cache list: ' + x.listName); break;
            }
          });
        },
        error: (err) => {
          this._isCacheAdminLoaded = false;
          this._isCacheAdminLoading = false;
          this._logger.error('Failed to load cache items. Reason: ' + err.statusText);
        }
      });
    }
  }
}
