import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {BuyerOfferModel} from "@models/buyer-offer-model";
import {WizardOfferService} from "@services/wizard-offer.service";

@Component({
  selector: 'step-3-buyer',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TypeaheadModule],
  templateUrl: './step-3-buyer.component.html',
  styleUrls: ['step-3-buyer.component.scss'],
})
export class Step3BuyerComponent implements OnInit{
  public buyerName: string = "";
  public buyerPhone: string = "";
  public buyerEmail: string = "";


  buyerOffer: BuyerOfferModel;
  private buyerOfferService = inject(WizardOfferService);

  constructor() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
  }

  updateBuyerOffer(): void {
    this.buyerOffer.buyerName = this.buyerName;
    this.buyerOffer.buyerEmail = this.buyerEmail;
    this.buyerOffer.buyerPhone = this.buyerPhone;
    this.buyerOfferService.setBuyerOfferTemp(this.buyerOffer);
    this.setIsValid();
  }

  ngOnInit() {
    this.buyerOffer = this.buyerOfferService.getBuyerOffer();
    this.buyerName = this.buyerOffer.buyerName || "";
    this.buyerEmail = this.buyerOffer.buyerEmail || "";
    this.buyerPhone = this.buyerOffer.buyerPhone || "";
    this.setIsValid();
  }

  private setIsValid() {
    this.buyerOfferService.setIsValid(this.isValid());
  }

  private isValid() {
    return this.buyerName.trim().length > 0 && this.buyerEmail.trim().length > 0 && this.buyerPhone.trim().length > 0;
  }
}
