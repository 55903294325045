
// this file has been generated. Do not modify manually. Please see _typewriter.tst in the current folder

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOptions } from '@buildable/foundation';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


// add imports
import { ListResultItem, IListResultItem } from '../models/list-result-item';
import { BuyerOfferModel, IBuyerOfferModel } from '../models/buyer-offer-model';
import { UpdateOptions, IUpdateOptions } from '../models/update-options';

@Injectable()
export class BuyerOffersService {

	constructor(public _http: HttpClient, public _auth: AuthService) { }

	public getResultItems(i_oSearchOptions: SearchOptions): Observable<ListResultItem[]> {

		return this._http.post<ListResultItem[]>(`api/BuyerOffers/getResultItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IListResultItem[]).map(x => new ListResultItem(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItems(i_oSearchOptions: SearchOptions): Observable<BuyerOfferModel[]> {

		return this._http.post<BuyerOfferModel[]>(`api/BuyerOffers/getItems`, i_oSearchOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return (response as IBuyerOfferModel[]).map(x => new BuyerOfferModel(x)); // convert array of json structs to array of objects (with methods)
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemById(id: number): Observable<BuyerOfferModel> {

		return this._http.get<BuyerOfferModel>(`api/BuyerOffers/getItemById/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new BuyerOfferModel(<IBuyerOfferModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemByUserId(id: number): Observable<BuyerOfferModel> {
		
		return this._http.get<BuyerOfferModel>(`api/BuyerOffers/getItemByUserId/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new BuyerOfferModel(<IBuyerOfferModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getItemNew(): Observable<BuyerOfferModel> {
		
		return this._http.get<BuyerOfferModel>(`api/BuyerOffers/getItemNew`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new BuyerOfferModel(<IBuyerOfferModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public post(i_oValue: BuyerOfferModel): Observable<BuyerOfferModel> {

		return this._http.post<BuyerOfferModel>(`api/BuyerOffers`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new BuyerOfferModel(<IBuyerOfferModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public putColumn(i_oUpdateOptions: UpdateOptions): Observable<UpdateOptions> {

		return this._http.put<UpdateOptions>(`api/BuyerOffers/putColumn`, i_oUpdateOptions, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new UpdateOptions(<IUpdateOptions>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public put(id: number, i_oValue: BuyerOfferModel): Observable<BuyerOfferModel> {

		return this._http.put<BuyerOfferModel>(`api/BuyerOffers/${id}`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new BuyerOfferModel(<IBuyerOfferModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public delete(id: number): Observable<any> {

		return this._http.delete<any>(`api/BuyerOffers/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public deleteBulk(i_iIdArray: number[]): Observable<any> {

		return this._http.post<any>(`api/BuyerOffers/deleteBulk`, i_iIdArray, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return response;
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public getFormPdf(i_oSearchOptions: SearchOptions): Observable<Blob> {

		return this._http.post<Blob>(`api/BuyerOffers/getFormPdf`, i_oSearchOptions,
      { headers: this._auth.initAuthHeaders(), responseType: 'blob' as 'json' })
			.pipe(
				map(response =>  {
          return response;
        }),
				catchError(response => this._auth.handleError(response))
			);
	};

  public uploadPreApproval(file: File): Observable<BuyerOfferModel> {
    let __methodFileWrapper = new FormData();
    __methodFileWrapper.append('file', file);

    return this._http.post<BuyerOfferModel>(`api/BuyerOffers/uploadPreApproval`, __methodFileWrapper, { headers: { }})
      .pipe(
        map(response =>  {
          return new BuyerOfferModel(<IBuyerOfferModel>response);
        }),
        catchError(response => this._auth.handleError(response))
      );
  };

	public submitOffer(i_oValue: BuyerOfferModel): Observable<BuyerOfferModel> {

		return this._http.post<BuyerOfferModel>(`api/BuyerOffers/submitOffer`, i_oValue, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new BuyerOfferModel(<IBuyerOfferModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
	public approveOfferById(id: number): Observable<BuyerOfferModel> {
		
		return this._http.get<BuyerOfferModel>(`api/BuyerOffers/approveOfferById/${id}`, { headers: this._auth.initAuthHeaders() })
			.pipe(
				map(response =>  {
					return new BuyerOfferModel(<IBuyerOfferModel>response);
				}),
				catchError(response => this._auth.handleError(response))
			);
	};
}

