<div class="offer-wizard container py-0 pb-4">
  <div class="card p-0 h-100 d-flex flex-column">
    <ui-alert [alert]="wizardAlert"></ui-alert>

    <div class="card__body flex-grow-1">
      <app-transition
        #wizardComponent
        class="full-height offer-wizard__body-container"
        [currentIndex]="currentStep"
        [templateConditions]="wizardConditions()"
        (indexChange)="onIndexChange($event)"
        (lastItem)="onLastItem()"
      >
        <ng-template>
          <step-1-welcome class="full-height"></step-1-welcome>
        </ng-template>

        <ng-template>
          <step-2-address class="full-height"></step-2-address>
        </ng-template>

        <ng-template>
          <step-3-buyer class="full-height"></step-3-buyer>
        </ng-template>

        <!--      For phase 2  -->
        <!--      <ng-template>-->
        <!--        <step-4-buyer-2 class="full-height"></step-4-buyer-2>-->
        <!--      </ng-template>-->

        <ng-template>
          <step-5-price class="full-height"></step-5-price>
        </ng-template>

        <ng-template>
          <step-6-financing class="full-height"></step-6-financing>
        </ng-template>

        <ng-template>
          <step-7-down-payment class="full-height"></step-7-down-payment>
        </ng-template>

        <ng-template>
          <step-8-earnest-money class="full-height"></step-8-earnest-money>
        </ng-template>

        <ng-template>
          <step-9-home-to-sell class="full-height"></step-9-home-to-sell>
        </ng-template>

        <ng-template>
          <step-10-home-to-sell-address class="full-height"></step-10-home-to-sell-address>
        </ng-template>

        <ng-template>
          <step-11-home-to-sell-days class="full-height"></step-11-home-to-sell-days>
        </ng-template>

        <ng-template>
          <step-12-closing-date class="full-height"></step-12-closing-date>
        </ng-template>

        <ng-template>
          <step-13-review-offer class="full-height"></step-13-review-offer>
        </ng-template>
      </app-transition>
    </div>

    <div class="px-3 pb-3 pt-3 flex-grow-0" style="border-top: 1px solid rgba(0, 0, 0, 0.11)">
      <div>
        <div class="progress mb-3">
          <div
            class="progress-bar"
            role="progressbar"
            [style.width.%]="wizardComponent.progressPercentage || 0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
          </div>
        </div>
      </div>
      <!-- back and forward buttons -->
      <div class="d-flex justify-content-between align-items-center">
        <button
          class="btn btn-light btn-wizard"
          (click)="previous()"
          [disabled]="isFirstStep"
        >
          <fa-icon [icon]="faChevronLeft" class="mr-1"></fa-icon> Back
        </button>
        <button
          *ngIf="!isLastStep"
          class="btn btn-primary btn-wizard"
          (click)="next()"
          [disabled]="!isValidStep"
        >
          Next
          <fa-icon [icon]="faChevronRight" class="ml-1"></fa-icon>
        </button>
        <button
          *ngIf="isLastStep"
          class="btn btn-primary btn-wizard"
          [loading]="isLoading"
          (click)="submit()"
        >
          <span>Submit</span>
        </button>
      </div>
    </div>
  </div>


</div>
