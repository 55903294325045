import { Component, OnInit, OnDestroy, ViewChild, inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { UIAlertItem, UIComponentBase, UIModalComponent, UIListViewColumn, UIListItem, UIListItemStyle, UIListViewModel, UIListViewComponent, UIListChange, SearchOptions, UIHeaderComponent, UIAlertComponent, UILoadingBarComponent } from '@buildable/foundation';
import { SystemUsersService } from '@services/system-users.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconsModule } from '@app/icons/icons.module';

@Component({
	selector: 'system-user-master',
	standalone: true,
	imports: [
		CommonModule, FontAwesomeModule, IconsModule,
		UIHeaderComponent, UIAlertComponent, UIListViewComponent, UIModalComponent, UILoadingBarComponent,
	],
	templateUrl: './system-user-master.component.html',
	providers: [SystemUsersService], // does not need to be SINGLETON, or global (saves on space)
})
export class SystemUserMasterComponent extends UIComponentBase implements OnDestroy {
	public listAlert: UIAlertItem = new UIAlertItem();
	public listViewModel: UIListViewModel;
	private _searchOptions: SearchOptions = new SearchOptions({ isSortAscending: false, sortBy: 'systemUserId' });

	public resettingAlert = new UIAlertItem();
	public resettingPassword = false;

	@ViewChild(UIListViewComponent)
	public uiListViewComponent!: UIListViewComponent;
	@ViewChild(UIModalComponent)
	public uiModal!: UIModalComponent;

	public auth = inject(AuthService);
	private _router = inject(Router);
	private _data = inject(SystemUsersService);

	constructor() {
		super();
		this.listViewModel = new UIListViewModel({
			showPager: true,
			showButtons: true,
			showFilter: false,
			addText: 'Add a system user',
			addEnabled: this.auth.hasRoleAdminAdd(),
			deleteText: '',
			deleteEnabled: this.auth.hasRoleAdminDelete(),
			viewEnabled: this.auth.hasRoleAdminView(),
			reloadText: '',
			reloadEnabled: true,
			inlineEditEnabled: this.auth.hasRoleAdminUpdate(),
			items: [],
			columns: [
				//new UIListViewColumn({ label: 'ID', field: 'systemUserId' }),
        new UIListViewColumn({ label: 'Email', field: 'emailAddress', link: true, style: <UIListItemStyle>{ bold: true }, canEdit: false }),
				new UIListViewColumn({ label: 'Active', field: 'isActive', type: 'boolean' }),
        new UIListViewColumn({ label: 'First Name', field: 'firstName', canEdit: true }),
				new UIListViewColumn({ label: 'Last Name', field: 'lastName', canEdit: true }),
				// can't edit systemRoles anymore. max 3/3/20
				//new UIListViewColumn({ label: 'Access', field: 'systemRolesCSV', type: 'text' }),
				new UIListViewColumn({ label: 'Added', field: 'dateAdded', type: 'date' }),
				new UIListViewColumn({ label: 'Modified', field: 'dateModified', type: 'date' }),
			]
		});
		// subscribe to events when the model will be ready
		this.addSubscriptions(this.listViewModel.initialize$.subscribe(() => {
			this.addSubscriptions(
				this.listViewModel.reload$.subscribe(searchOptions => this.reload(searchOptions)),
				this.listViewModel.back$.subscribe(() => this._router.navigate(['/home', 0])),
				this.listViewModel.add$.subscribe(() => this._router.navigate(['/system/users/item', 0])),
				this.listViewModel.view$.subscribe((item: UIListItem) => this._router.navigate(['/system/users/item', item.id])),
				this.listViewModel.delete$.subscribe((items: UIListItem[]) => this.deleteSelection(items)),
				this.listViewModel.updated$.subscribe((item: UIListChange) => this.updateField(item)),
			);
		}));
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}

	reload(searchOptions?: SearchOptions | null) {
		this.listAlert.reset();

		if (searchOptions != null) {
			this._searchOptions = searchOptions;
		}

		this.listViewModel.pager.loading = true;
		this._data.getItems(this._searchOptions).subscribe({
			next: (items) => {
				if (items === undefined || items.length == 0) return; // no items

				let pager = items.pop()!.pager; // pop the pager
				this.listViewModel.items = items.map(x => {
					let item: UIListItem = new UIListItem();
					item.data = x;
					item.selected = false;
					item.id = x.systemUserId!;
					item.showButton = x.systemUserId != this.auth.getLocalUserId()
					return item;
				});
				this.listViewModel.pager.update(pager!, this.listViewModel.items);
				this.listViewModel.pager.loading = false;
			},
			error: (err) => {
				this.listViewModel.pager.loading = false;
				this.listAlert.errorMessage = "Failed to get records. Reason: " + err.statusText;
			}
		});
	}

	deleteSelection(selectedItems: UIListItem[]) {
		let selectedIds = selectedItems.map(x => x.id!);
		this.listAlert.reset();
		this._data.deleteBulk(selectedIds).subscribe({
			next: (result) => {
				this.reload();
			},
			error: (err) => {
				this.listAlert.loading = false;
				this.listAlert.errorMessage = 'Failed to delete one or more items. Reason: ' + err.statusText;
			}
		});
	}

	updateField(change: UIListChange) {
		this._data.putColumn(change.updateOptions).subscribe({
			next: (result) => change.commit(result),
			error: (err) => this.listAlert.errorMessage = 'Failed to update field. Reason: ' + err.statusText,
		});
	}

	showResetPasswordModal() {
		// reset the edit view
		this.resettingPassword = false;
		this.resettingAlert.reset();
		this.uiModal.show();
	}

	closeResetPasswordModal() {
		this.uiModal.hide();
	}

	onResetPassword() {
		let selectedItems = this.listViewModel.items.filter(x => x.selected);
        let selectedIds = selectedItems.map(x => x.id!);

		this.resettingPassword = true;
		this.resettingAlert.loading = true;
		this.resettingAlert.reset();

		this._data.resetPassword(selectedIds).subscribe({
			next: () => {
				this.listAlert.successMessage = "Password reset email" + (selectedIds.length > 1 ? "s have" : " has") + " been sent to " + (selectedIds.length > 1 ? "these users" : "this user");
				this.closeResetPasswordModal();
				this.resettingPassword = false;
				this.resettingAlert.loading = false;
			},
			error: (err) => {
				this.resettingPassword = false;
				this.resettingAlert.loading = false;
				this.resettingAlert.errorMessage = 'Failed to send the reset email passwords. Reason: ' + err.statusText;
			}
		});
	}
}
