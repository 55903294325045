<div id="nav-loading-bar-container">
<!--  <ui-loading-bar-->
<!--    [show]="(loadingService.showLoading$ | async) ?? false"-->
<!--    [backgroundClass]="'load-bar-bg'"-->
<!--    [foregroundClass]="'load-bar-fg'"-->
<!--  ></ui-loading-bar>-->
</div>

<nav class="navbar navbar-dark bg-gradient-primary navbar-expand-md fixed-top">
  <a class="navbar-brand  ml-lg-2" [routerLink]="['/home']">
    <img
      class="navbar-brand-img"
      src="/images/homewrite_logo_icon_white.png"
      style="height: 40px; width: auto"
      title="Homewrite Platform"
    />
  </a>

  <button
    id="navbar-toggler"
    class="navbar-toggler custom-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <fa-icon [size]="'lg'" [icon]="faBars"></fa-icon>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-4">
      <!--  public    -->
      <li class="nav-item" [routerLinkActive]="['active']">
        <a [routerLink]="['/home']" class="nav-link" (click)="closeMobileMenu($event)">Home</a>
      </li>

      <!-- admin menu -->
      <ng-container
        *ngIf="
          loggedIn &&
          userIdentityCurrent &&
          userIdentityCurrent.identityType == 'admin'
        "
      >
        <li class="nav-item" [routerLinkActive]="['active']">
          <a [routerLink]="['/admin/mls-data']" class="nav-link" (click)="closeMobileMenu($event)">MLS Data</a>
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownSystem"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Admin
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownSystem">
            <a [routerLink]="['/system/users']" class="dropdown-item" (click)="closeMobileMenu($event)"
              >User Management</a
            >
            <a [routerLink]="['/system/admins']" class="dropdown-item" (click)="closeMobileMenu($event)"
              >Team Management</a
            >
            <a [routerLink]="['/system/agents']" class="dropdown-item" (click)="closeMobileMenu($event)"
              >Agents Management</a
            >
            <a [routerLink]="['/system/roles']" class="dropdown-item" (click)="closeMobileMenu($event)"
              >Roles &amp; Permissions</a
            >
            <!-- TODO -->
            <a
              [routerLink]="['/system/activity-log']"
              (click)="closeMobileMenu($event)"
              class="dropdown-item disabled"
              disabled
              >Activity Log</a
            >
            <!-- TODO -->
            <a
              [routerLink]="['/system/email-log']"
              (click)="closeMobileMenu($event)"
              class="dropdown-item disabled"
              disabled
            >Email Log</a>
            <!-- TODO: dev-only -->
            <!-- <div class="dropdown-divider"></div>
            <a
              [routerLink]="['/system/design']"
              class="dropdown-item"
              (click)="closeMobileMenu($event)"
              >Design System</a> -->
          </div>
        </li>
      </ng-container>

      <ng-container
        *ngIf="
          loggedIn &&
          userIdentityCurrent &&
          (userIdentityCurrent.identityType == 'buyer'
          || userIdentityCurrent.identityType == 'admin')
        "
      >
        <li class="nav-item" [routerLinkActive]="['active']">
          <a [routerLink]="['/buyer']" class="nav-link" (click)="closeMobileMenu($event)">Start Offer</a>
        </li>
      </ng-container>

      <!--  public    -->
<!--      <li class="nav-item" [routerLinkActive]="['active']">-->
<!--        <a [routerLink]="['/learn-more']" class="nav-link" (click)="closeMobileMenu($event)">Learn More</a>-->
<!--      </li>-->

      <ng-container
        *ngIf="
          loggedIn &&
          userIdentityCurrent &&
          (userIdentityCurrent.identityType == 'agent'
          || userIdentityCurrent.identityType == 'admin')
        "
      >
        <li class="nav-item" [routerLinkActive]="['active']">
          <a [routerLink]="['/agent/offers']" class="nav-link" (click)="closeMobileMenu($event)">Offers</a>
        </li>

      </ng-container>

    <ng-container
      *ngIf="loggedIn && userIdentityCurrent && auth.hasRolePortalAccess()"
    >
      <div style="flex-grow: 1;"></div>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a (click)="closeMobileMenu($event); auth.logout()" class="nav-link d-flex align-items-center">
          <span class="mr-2">Logout</span>
          <fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>
        </a>
      </li>
    </ng-container>
    <ng-container
      *ngIf="!loggedIn"
    >
      <div style="flex-grow: 1;"></div>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a [routerLink]="['/login']" class="nav-link d-flex align-items-center" (click)="closeMobileMenu($event)">
          <span class="mr-2">Login</span>
          <fa-icon [icon]="['fas', 'user']"></fa-icon>
        </a>
      </li>
    </ng-container>
    </ul>

  </div>
</nav>
