<div
  class="card pb-3 pt-4 mt-3 px-0 mb-5"
  style="width: 90%; max-width: 390px; margin: 0 auto"
>
  <div class="card-body px-4 pt-0">
    <h2 class="sr-only">Register Form</h2>
    <div class="register-logo mb-2"></div>

    <div *ngIf="!showSuccessMessage">
      <div class="form-group text-left">
        <label for="firstName"><span class="sr-only">First Name</span>First Name</label>
        <input
          id="firstName"
          class="form-control"
          type="text"
          [(ngModel)]="authUser.firstName"
        />
      </div>
      <div class="form-group text-left">
        <label for="lastName"><span class="sr-only">Last Name</span>Last Name</label>
        <input
          id="lastName"
          class="form-control"
          type="text"
          [(ngModel)]="authUser.lastName"
        />
      </div>
      <div class="form-group text-left">
        <label for="loginEmail"><span class="sr-only">Login </span>Email</label>
        <input
          id="loginEmail"
          class="form-control"
          type="email"
          [(ngModel)]="authUser.login"
          (keyup.enter)="buyerRegister()"
        />
      </div>
      <div class="form-group text-left">
        <label for="loginPw">Password</label>
        <input
          id="loginPw"
          class="form-control"
          type="password"
          [(ngModel)]="authUser.password"
          (keyup.enter)="buyerRegister()"
        />
      </div>
      <div class="form-group text-left">
        <label for="confirmPassword">Confirm Password</label>
        <input
          id="confirmPassword"
          class="form-control"
          type="password"
          [(ngModel)]="userConfirmPassword"
          (keyup.enter)="buyerRegister()"
        />
      </div>

      <div class="form-group">
        <button
          class="btn btn-block btn-primary"
          (click)="buyerRegister()"
          [loading]="signingUp"
        >
          <span>Sign Up</span>
        </button>
      </div>

      <div *ngIf="!signingUp && errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>

    <div *ngIf="showSuccessMessage" class="alert alert-success">
      An email has been sent to you. Please activate your account to log in.
      <hr>
      <div class="mt-3 text-center">
        <a class=" small" [routerLink]="['/login']">
          Go to Log In
        </a>
      </div>
    </div>
  </div>

  <hr class="my-0" />
  <div class="mt-3 text-center">
    <small class="mb-1">Already have an account?</small>
    <a
      class=" small"
      [routerLink]="['/login']">
      Log In
    </a>
  </div>
</div>
