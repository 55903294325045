<div class="container-fluid mb-5">
    <ui-alert [alert]="listAlert"></ui-alert>
    <ui-header [header]="'Logs'"></ui-header>

	<ui-listview [viewModel]="listViewModel">
		<ng-container ngProjectAs="[altButtons]">
			<div class="d-inline-flex align-items-center">

				<span class="d-inline-flex align-items-center">
					<input id="tailLog" class="mr-2" type="checkbox" [(ngModel)]="tailLog" />
					<label for="tailLog" class="mb-0 mr-2 font-weight-normal">Auto&nbsp;tail</label>
				</span>

				<select class="form-control form-control-sm mr-2 mb-0" [(ngModel)]="filterLogTypeSelected" (change)="filterLogType()">
					<option [ngValue]="null" [selected]="!filterLogTypeSelected">All Types</option>
					<option [ngValue]="1">Low Level</option>
					<option [ngValue]="2">Debug</option>
					<option [ngValue]="3">Info</option>
					<option [ngValue]="4">Warning</option>
					<option [ngValue]="5">Error</option>
					<option [ngValue]="6">Fatal</option>
				</select>

				<select class="form-control form-control-sm mr-2 mb-0" [(ngModel)]="filterDateSelected" (change)="filterDate()">
					<option [ngValue]="null" [selected]="!filterDateSelected">All Dates</option>
					<option *ngFor="let opt of dateListBs | async" [ngValue]="opt.label">{{opt.label}}</option>
				</select>
			</div>

			<button class="btn btn-primary" (click)="downloadLogs()">Download Log</button>
		</ng-container>
	</ui-listview>
</div>
