<div class="container h-100 w-100 p-0">
  <div class="offer-wizard-bg__container">
    <div class="offer-wizard-bg"></div>
  </div>

  <ui-alert [alert]="alertComponent"></ui-alert>

  <router-outlet></router-outlet>

  <!-- Add space at the bottom on mobile -->
  <div class="d-lg-none" style="height: 1.25rem"></div>
</div>
