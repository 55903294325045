import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

import { isPlatformBrowser } from '@angular/common'; // because localStorage fails when the code is compiled on the server (node doesn't have it)
import { PLATFORM_ID } from '@angular/core';
import { AuthService } from "./auth.service";


// declare the Index.chsml authentication check
declare var _MyInitialAuthentication: boolean | null;

@Injectable({
  providedIn: 'root'
})
export class SessionService {
	private _isBrowser: boolean;

	/** use this property for property binding */
	public isUserLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(PLATFORM_ID) private platform_id: any) {
    // angular 4 way for isBrowser()
    this._isBrowser = isPlatformBrowser(platform_id);

    if (this._isBrowser) {
      // if hosted via Razor template, this is always true/false
      if (_MyInitialAuthentication != null) {
        this.setUserIsLoggedIn(_MyInitialAuthentication);
      }

      // if hosted from the Angular index.html the MyInitialAuthentication variable will not be set
      else {
        // fallback to localstorage, just a tad slower (completely negligible though)

        // check if localstorage value exists and is parsable as JSON
        const authProfile = localStorage.getItem(AuthService.AUTH_PROFILE_KEY);
        try {
          if (authProfile) {
            const authProfileObj = JSON.parse(authProfile);
            if (authProfileObj) {
              this.setUserIsLoggedIn(true);
            }
          }
        } catch (e) {
          this.setUserIsLoggedIn(false);
        }
      }
    }
	}

  setUserIsLoggedIn(value: boolean) {
		this.isUserLoggedIn$.next(value);
	}

	// Timezone offset
	private _timeZoneOffset: number = 0;
	get timeZoneOffset(): number {
		return this._timeZoneOffset;
	}
	set timeZoneOffset(value: number) {
		if (value != null) {
			this._timeZoneOffset = value;
		}
	}
}
