<div class="full-height row pt-3">
  <div class="d-flex flex-column justify-content-center">
    <div>
      <h1 class="col-md-9 h1-responsive fw-bold">How long do you think it will take you to sell your home?</h1>
      <p class="col-md-9 mt-3 subheader">
        We will discuss this in detail during our consultation call!
      </p>
      <div class="mt-2 col-md-6">
        <input type="number"
          step="1"
          min="1"
          class="wizard-input"
          [(ngModel)]="daysToSell"
          id="number_days"
          placeholder="Number of Days"
          (ngModelChange)="updateBuyerOffer()"
        />
      </div>
      <p class="col-md-9 mt-3 subheader">
        45-60 days is standard and competitive.
      </p>

<!--      <p class="col-md-9 mt-3 text-secondary">-->
<!--        Standard practice is to ask the seller to give you 5 days to put your home on the market, and 45 days-->
<!--        to have it sold. “Sold” means you have accepted an offer on it- not that it has closed :)-->
<!--      </p>-->
<!--      <p class="col-md-9 mt-2 text-secondary">-->
<!--        <strong>Pro tip:</strong> If you don’t have an agent in place to sell your home, don’t worry. We will discuss this with-->
<!--        you prior to submitting your offer, so there’s a plan in place. There’s no rush to any of this, we’ll go at-->
<!--        your pace!-->
<!--      </p>-->
    </div>
  </div>

</div>
