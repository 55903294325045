<div class="full-height row pt-3">
  <div class="d-flex flex-column justify-content-center">
    <div class="subheader">
      <h1 class="col-md-9 h1-responsive fw-bold">Review: Did we miss anything?</h1>
      <p class="col-md-9 mt-3 ">
        Please review your answers. You can click the back button if anything needs to be changed.
      </p>

      <div class="mt-3 col-md-6">
        <p><strong>Purchase price: </strong>{{ buyerOffer.askingPrice | currency }}</p>
        <p><strong>Closing Date: </strong>{{ buyerOffer.closingDate | date }}</p>
        <p><strong>Buyer: </strong>{{ buyerOffer.buyerName }}</p>
        <p><strong>Dependent on home sale: </strong>{{ buyerOffer.buyerHasHomeToSell ? "Yes" : "No" }}</p>
        <p><strong>Dependent on loan approval: </strong>{{ buyerOffer.paymentMethod == PaymentMethods.Financing ? "Yes" : "No" }}</p>
        <p><strong>Earnest Money deposit: </strong>{{ buyerOffer.earnestMoney | currency }}</p>
        <p *ngIf="buyerOffer.paymentMethod == PaymentMethods.Financing">
          <strong>Down Payment: </strong>{{ buyerOffer.downPayment | currency }}
        </p>
      </div>
    </div>
  </div>

</div>
