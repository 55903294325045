<div class="container">
	<div class="row">
		<div class="mx-sm-auto pt-md-5 mb-3">
			<div class="card" style="width: 300px; max-width: 400px;">
				<div class="card-header">
					404 - Page Not Found
				</div>
				<div class="card-body">
					<p>Either this page does not exist, or this feature is coming soon.</p>
				</div>
			</div>
		</div>
	</div>
</div>
